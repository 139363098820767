import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { MatBadgeModule } from '@angular/material/badge';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

import { AuthService } from 'src/app/auth-guards/auth.service';
import { userdto } from './models/userdto';
import { MyService, MyServiceEvent } from './services/eventservice';
import { firebaseStoreService } from './services/firebasestoreservice';
import { AppUtilApiService } from './services/app-util-api.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthGuardService } from './auth-guards/auth-guard';
import { LoginService } from './services/login.service';

export interface ReviewsCount {
  rowsCnt: number;
}

export interface Links {
  Name: string;
  Link: string;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  navLinks: Links[] = [
    {
      Link: 'plans',
      Name: 'Plans',
    },
    {
      Link: 'trading-past-performance',
      Name: 'Past Performance',
    },
  ];
  @ViewChild('notification') elementView!: ElementRef;
  @ViewChild('toolbar') toolbar!: any;
  matsidetop!: number;
  stickyheader = false;
  shownotification = false;
  showtrialnotification = false;
  @HostListener('scroll', ['$event'])
  onDocumentMousewheelEvent(event: any) {
    debugger;
    console.log('scrolled');
  }
  @HostListener('window:scroll', ['$event'])
  public onWindowScroll(event: Event): void {
    debugger;
    console.log('scrolled');
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event: any) {
    if (!this.shownotification && !this.showtrialnotification) {
      this.stickyheader = true;
      if (this.stickyheader) {
        this.matsidetop = this.toolbar._elementRef.nativeElement.offsetHeight;
        console.log(this.matsidetop);
      }
      return;
    }
    console.log(event);
    console.log(window.pageYOffset);

    var viewHeight = this.elementView.nativeElement.offsetHeight;

    console.log(viewHeight);

    console.log(this.toolbar._elementRef.nativeElement.offsetHeight);

    this.matsidetop =
      viewHeight + this.toolbar._elementRef.nativeElement.offsetHeight;

    this.stickyheader = window.pageYOffset > viewHeight;
    if (this.stickyheader) {
      this.matsidetop = this.toolbar._elementRef.nativeElement.offsetHeight;
      console.log(this.matsidetop);
    }
  }

  userLinks: Links[] = [];
  adminLinks: Links[] = [];
  user: userdto | undefined;
  isadmin = false;
  avatar: string = '';
  showback: boolean = false;
  title = 'push-notification';
  message: string = '';

  public reviewsCount!: Observable<ReviewsCount>;
  public reviewsBadgeHideFlag = true;

  constructor(
    private device: DeviceDetectorService,
    private _location: Location,
    private service: MyService,
    private spinner: NgxSpinnerService,
    public appUtil: AppUtilApiService,
    private afAuth: AngularFireAuth,
    private router: Router,
    public authF: AngularFireAuth,
    private fs: firebaseStoreService,
    public auth: AuthService,
    public LoginService: LoginService,
    public dialog: MatDialog,

    public afs: AngularFirestore
  ) {
   this.auth.isAuthenticated().then(async (res) => {

     debugger;
      this.shownotification = await this.auth.isPhoneNumberVerified();
      this.calculateSideBarHeight();
    });
    // console.log(this.messagingService.deviceInfo);
    this.service.onChange.subscribe({
      next: (event: MyServiceEvent) => {
        this.showback = true;
      },
    });

    this.authF.authState.subscribe((es) => {
      this.adminLinks = [];
      this.userLinks = [];

      if (es != null) {
        this.auth.isUserAuthenticated = true;
        this.spinner.hide();
        this.fs.getAdminDetail(es.uid).subscribe((e) => {
          if (e == null || e.data() == null) {
            this.userLinks.push({
              Link: 'trading-alerts',
              Name: 'Active Alerts',
            });

            this.userLinks.push({
              Link: 'subscriptions',
              Name: 'Subscriptions',
            });
            this.userLinks.push({
              Link: 'transactions',
              Name: 'Transactions',
            });
            this.userLinks.push({
              Link: 'myinfo',
              Name: 'My Profile',
            });

            this.fs.getUserDetail(es.uid).subscribe((lp) => {
              this.user = lp.data();
              this.avatar = this.user?.pictureUrl ?? '';
            });
          } else {
            this.isadmin = true;
            this.adminLinks.push({
              Link: '/alert-manager',
              Name: 'Alert Manager',
            });
            this.adminLinks.push({
              Link: '/user-manager',
              Name: 'User Manager',
            });
            this.adminLinks.push({
              Link: '/plan-manager',
              Name: 'Plan Manager',
            });
            this.adminLinks.push({
              Link: '/refund-manager',
              Name: 'Refunds Manager',
            });
            this.adminLinks.push({
              Link: '/contact-manager',
              Name: 'Contacts',
            });

            this.adminLinks.push({
              Link: '/reviews-manager',
              Name: 'Reviews Manager',
            });

            this.user = e.data();
            this.avatar = this.user?.pictureUrl ?? '';
          }
        });
      } else {
        this.auth.isUserAuthenticated = false;
        // this.navLinks.push({
        //   Link: '/login',
        //   Name: 'Login',
        // },);

        this.navLinks.forEach((element, index) => {
          if (element.Link == 'trading-alerts') this.navLinks.splice(index, 1);
        });
      }
      this.navLinks.forEach((element, index) => {
        if (element.Link == 'about-us' || element.Link == 'faq') {
          this.navLinks.splice(index, 1);
        }
      });

      this.navLinks.push({
        Link: 'about-us',
        Name: 'About Us',
      });
    });
  }

  async ngOnInit(): Promise<void> {
    this.afs
      .collection('user-reviews', (ref) =>
        ref.where('showflag', '==', 'N').orderBy('createAt', 'desc')
      )
      .valueChanges()
      .subscribe((result) => {
        this.reviewsCount = <any>result.length;

        if (result.length > 0) {
          this.reviewsBadgeHideFlag = false;
        }
      });

    if (await this.LoginService.isLoggedIn()) {
      var uid = (await this.LoginService.getCurrentUserId())?.uid ?? '';
      debugger;
      this.fs
        .getTrialExpireSubscriptions(uid)
        .valueChanges()
        .subscribe(async (e) => {
          if (e.length == 0) {
            var subscriptions = await this.fs
              .getUserSubscriptionWhichAreNotTrial(uid)
              .get()
              .toPromise();
            if (subscriptions.docs.length == 0) {
              console.log(e);
              this.showtrialnotification = true;
              this.calculateSideBarHeight();
            }
          }
        });
    }
  }

  ngAfterViewInit(): void {
    // console.log(" parent called");
    // this.service.doSomething("hii ");

    this.calculateSideBarHeight();
  }

  calculateSideBarHeight() {
    if (this.stickyheader) {
      this.matsidetop = this.toolbar._elementRef.nativeElement.offsetHeight;
    }
    var viewHeight = 0;
    if (this.elementView != undefined) {
      viewHeight = this.elementView.nativeElement.offsetHeight;
    }
    this.matsidetop =
      viewHeight + this.toolbar._elementRef.nativeElement.offsetHeight;
  }

  async logout() {
    this.auth.logout();
  }
}
