import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { userdto } from 'src/app/models/userdto';
import { AppUtilApiService } from 'src/app/services/app-util-api.service';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { NotificationService } from 'src/app/services/notification.service';
import { EdituserComponent } from '../edituser/edituser.component';

import { UserdevicedetailsComponent } from '../userdevicedetails/userdevicedetails.component';
import { ChangephonenumberdialogComponent } from '../../../user-dashboard/changephonenumberdialog/changephonenumberdialog.component';
import { ProgresdialogComponent } from 'src/app/dialogs/progresdialog/progresdialog.component';
import { SmsReponseModule } from 'src/app/models/smsresponsedto';
import { VerifyphonenumberdialogComponent } from 'src/app/user-dashboard/verifyphonenumberdialog/verifyphonenumberdialog.component';
import { SmsMessagingService } from '../../../services/sms-messaging.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { flatten } from '@angular/compiler';


@Component({
  selector: 'app-viewallusers',
  templateUrl: './viewallusers.component.html',
  styleUrls: ['./viewallusers.component.css']
})
export class ViewallusersComponent implements OnInit {

  users: userdto[] = [];
  displayedColumns: string[] = ['name', 'email', 'phone', 'lastlogin', 'action'];
  dataSource !: MatTableDataSource<userdto>;

  @ViewChild(MatPaginator) paginator !: MatPaginator;
  @ViewChild(MatSort) sort !: MatSort;

  constructor(
    public nts: NotificationService,
    public ngx: NgxSpinnerService,
    public appUtil: AppUtilApiService,
    public fc: AngularFireFunctions,
    public dialog: MatDialog,
    public firestore: firebaseStoreService,
    public afs: AngularFirestore) {
  }

  ngOnInit(): void {
    this.firestore.getUsers().valueChanges().subscribe(e => {
      this.users = [];
      e.forEach(res => {

        this.users.push(res);
      });
      this.dataSource = new MatTableDataSource(this.users);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  ngAfterViewInit() {
    this.ngx.show();
  }
  async ShowDeviceInfo(element: any) {
    this.dialog.open(UserdevicedetailsComponent, { data: element });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  async editUser(row: userdto) {
    this.dialog.open(EdituserComponent, { data: row, disableClose: true })
  }

  async changephonenumber(row: userdto) {
    this.dialog.open(ChangephonenumberdialogComponent, { data: row, disableClose: true });
  }

  async verifyphonenumber(row: userdto) {
    console.log(row.phone);


    var func = this.fc.httpsCallable('sendPhoneVerificationToken');
    var dialogRef = this.dialog.open(ProgresdialogComponent, { disableClose: true });
    func({
      'phone':
        row.phone
    }).subscribe(e => {
      console.log(e);

      var smsDto = SmsReponseModule.Convert.toSMSResponseDto(e);
      if (smsDto.status == 'pending') {
        dialogRef.close();
        this.dialog.open(VerifyphonenumberdialogComponent, { data: row, disableClose: true });
      }
      else {
        this.nts.showError("Could not verify at that moment", "Verirication Failed");
      }
    });
  }

  async suspendUserAlerts(row: userdto) {
    let alrtFlag: boolean = false;

    if (row.alertsflag) {
      alrtFlag = false;
    }
    else {
      alrtFlag = true;
    }

    return this.afs.collection('users').doc(row.uid).update({ 'alertsflag': alrtFlag })
      .then(r => {
        this.nts.showSuccess('Alert service status chnage; successfully', row.name + ' ' + row.lastname ? row.lastname : '');
      }).catch(e => {
        this.nts.showError('Alert service status chnage; Error', row.name + ' ' + row.lastname ? row.lastname : '');
      });

  }


  async suspendUserAccess(row: userdto) {
    let accFlag;
    let alertflag;

    if (row.statusflag =='A') {
      accFlag = 'I';
      alertflag = false;
    }
    else {
      accFlag = 'A';
      alertflag = true;
    }

    return this.afs.collection('users').doc(row.uid).update({ 'statusflag': accFlag,'alertsflag': alertflag })
      .then(r => {
        this.nts.showSuccess('User Status chnage; successfully', row.name + ' ' + row.lastname ? row.lastname : '');
      }).catch(e => {
        this.nts.showError('User Status chnage; Error', row.name + ' ' + row.lastname ? row.lastname : '');
      });;

  }
}