import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentSnapshot } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AlertPlans } from '../models/alertplandto';
import { subscriptiondto } from '../models/subscriptiondto';
import { userdto } from '../models/userdto';
import { Alerts, AlertDetail, AlertPlanDetails } from '../models/alertdto';
import { commentdto } from '../models/commentdto';
import * as firebase from 'firebase';
import { fcmTokensdto } from '../models/fcmTokensdto';
import { dateservice } from './date-service';
import { RefundDto, RefundCompactDto } from '../models/refunddto';
import { contactUsdto } from '../models/contactusdto';
import {
  StripeStatusConstants,
  AppUtilApiService,
} from './app-util-api.service';

@Injectable({
  providedIn: 'root',
})
export class firebaseStoreService {
  updateSubscriptionRefund(id: string) {
    return this.firestore
      .collection(firebaseStoreService.subscriptionCollection)
      .doc(id)
      .update({
        isEnabled: false,
        isCancelled: true,
      });
  }
  static alertPlanCollection: string = 'alertplans';
  static subscriptionCollection: string = 'subscriptions';
  static userCollection: string = 'users';
  static adminCollection: string = 'admin';
  static alertCollection = 'alerts';
  static commentCollection = 'comments';
  static fcmTokens = 'fcmTokens';
  static refundCollection = 'refunds';
  static contactus = "contactus";
  constructor(
    public firestore: AngularFirestore,
    public ds: dateservice,
    public appUtil: AppUtilApiService
  ) {}

  async contactus(contactUsdto:contactUsdto)
  {
    debugger;
    var contact =  this.firestore.collection(firebaseStoreService.contactus).doc();
    contactUsdto.id = contact.ref.id;
    return contact.set(contactUsdto);
  }
  getContactQueries()
  {
    return this.firestore.collection<contactUsdto>(firebaseStoreService.contactus);
  }
  async getRefundForSubscriptionId(id: string) {
    // await this.firestore.collection(firebaseStoreService.refundCollection, res => res.where("subscriptionid", "==", "123").where("stripesubscriptionid", "==", "123").orderBy("applieddate", "desc")).get().toPromise();
    return this.firestore.collection<RefundDto>(
      firebaseStoreService.refundCollection,
      (res) => res.where('subscriptionid', '==', id)
    );
  }
  rejectRefund(reason: string, id: string) {
    return this.firestore
      .collection(firebaseStoreService.refundCollection)
      .doc(id)
      .update({
        isreviewed: true,
        isaccepted: false,
        rejectionreason: reason,
      });
  }
  refundAmountUser(id: string) {
    return this.firestore
      .collection(firebaseStoreService.refundCollection)
      .doc(id)
      .update({
        isreviewed: true,
        isaccepted: true,
      });
  }
  refundAmountToUser(id: string, amount: number) {
    return this.firestore
      .collection(firebaseStoreService.refundCollection)
      .doc(id)
      .update({
        isreviewed: true,
        isaccepted: true,
        refundamount: amount,
      });
  }
  createRefund(refund: RefundDto) {
    var doc = this.firestore
      .collection(firebaseStoreService.refundCollection)
      .doc();
    refund.id = doc.ref.id;
    return doc.set(refund);
  }
  getAllRefunds(isreview: boolean = false) {
    return this.firestore
      .collection<RefundDto>(firebaseStoreService.refundCollection)
      .valueChanges();
  }

  async isAdmin(uid: string) {
    var ans = await this.firestore
      .collection(firebaseStoreService.adminCollection)
      .doc(uid)
      .get()
      .toPromise();
    if (ans != null && ans.exists) {
      return true;
    } else {
      return false;
    }
  }

  updateUserPhoneNumberStatus(uid: string, status: boolean) {
    return this.firestore
      .collection(firebaseStoreService.userCollection)
      .doc(uid)
      .update({
        isPhoneNumberVerified: status,
      });
  }
  updatePhonerNumber(uid: string, phone: string, wcphone: string) {
    return this.firestore
      .collection(firebaseStoreService.userCollection)
      .doc(uid)
      .update({
        phone: phone,
        isPhoneNumberVerified: false,
      });
  }
  unArchiveAlert(id: string) {
    return this.firestore
      .collection(firebaseStoreService.alertCollection)
      .doc(id)
      .update({
        closePrice: null,
        updatedAt: this.getCurrentTime(),
        closeAt: null,
        alertstatus: 'O',
      });
  }
  async updatecommentscount(alertId: string) {
    var comments = await this.firestore
      .collection(firebaseStoreService.alertCollection)
      .doc(alertId)
      .collection(firebaseStoreService.commentCollection)
      .get()
      .toPromise();
    var length = comments.size;
    return this.firestore
      .collection(firebaseStoreService.alertCollection)
      .doc(alertId)
      .update({
        totalcomments: length,
      });
  }
  getFcmToken(uid: string) {
    return this.firestore
      .collection(firebaseStoreService.userCollection)
      .doc(uid)
      .collection<fcmTokensdto>(firebaseStoreService.fcmTokens, (res) =>
        res.orderBy('createDate', 'desc')
      )
      .valueChanges();
  }
  getCurrentTime() {
    return firebase.default.firestore.FieldValue.serverTimestamp();
  }
  addComents(comment: commentdto) {
    var doc = this.firestore
      .collection(firebaseStoreService.alertCollection)
      .doc(comment.alertPlan)
      .collection(firebaseStoreService.commentCollection)
      .doc();
    comment.commentId = doc.ref.id;
    return doc.set(comment);
  }

  getAllAlerts() {
    return this.firestore
      .collection<Alerts>('alerts', (res) => res.orderBy('createdAt', 'desc'))
      .valueChanges();
  }
  async getUserSubscriptionsPlanIds(uid: string) {
    var subscriptions = await this.getUserActiveSubscription(uid).toPromise();

    var subId: string[] = [];
    subscriptions.forEach((e) => {
      subId.push(e.data().alertPlanId);
    });
    return subId;
  }
  async getActiveAlertsForUser(uid: string) {
    return this.firestore
      .collection<Alerts>(firebaseStoreService.alertCollection, (res) =>
        res.orderBy('createdAt', 'desc')
      )
      .valueChanges();
  }

  async removeTrialSubscription(planId: string, userId: string) {
    var subscriptions = await this.firestore
      .collection(firebaseStoreService.subscriptionCollection, (res) =>
        res
          .where('userId', '==', userId)
          .where('alertPlanId', '==', planId)
          .where('isTrial', '==', true)
      )
      .get()
      .toPromise();
    subscriptions.forEach((e) => {
      this.firestore
        .collection(firebaseStoreService.subscriptionCollection)
        .doc(e.id)
        .delete();
    });
  }
  getActiveAlerts() {
    return this.firestore
      .collection<Alerts>(firebaseStoreService.alertCollection, (res) =>
        res.where('alertstatus', '==', 'O').orderBy('createdAt', 'desc')
      )
      .valueChanges();
  }
  getClosedAlerts(dt: any) {
    return this.firestore
      .collection<Alerts>('alerts', (res) =>
        res
          .where('alertstatus', '==', 'C')
          .where('closeAt', '<', dt)
          .orderBy('closeAt', 'desc')
      )
      .valueChanges();
  }

  getAlerts() {
    return this.firestore
      .collection<Alerts>(firebaseStoreService.alertCollection, (res) =>
        res.orderBy('createdAt', 'desc')
      )
      .valueChanges();
  }
  updateComents(comment: commentdto) {
    return this.firestore
      .collection(firebaseStoreService.alertCollection)
      .doc(comment.alertPlan)
      .collection(firebaseStoreService.commentCollection)
      .doc(comment.commentId)
      .update(comment);
  }
  deleteComment(comment: commentdto) {
    return this.firestore
      .collection(firebaseStoreService.alertCollection)
      .doc(comment.alertPlan)
      .collection(firebaseStoreService.commentCollection)
      .doc(comment.commentId)
      .delete();
  }
  closeAlert(Alerts: Alerts) {
    var doc = this.firestore
      .collection(firebaseStoreService.alertCollection)
      .doc(Alerts.alertId)
      .update({
        closePrice: Alerts.closePrice,
        desciption: Alerts.desciption?.toUpperCase(),
        note: Alerts.notes,
        updatedAt: Alerts.updatedAt,
        updatedBy: Alerts.updatedBy,
        closeBy: Alerts.closeBy,
        closeAt: Alerts.closeAt,
        alertstatus: Alerts.alertstatus,
      });

    return doc;
  }

  updateAlertPlans(plans: AlertPlans) {
    return this.firestore
      .collection(firebaseStoreService.alertPlanCollection)
      .doc(plans.plandocid)
      .update(plans);
  }

  addAlertPlans(plans: AlertPlans) {
    plans.planname = plans.planname.toUpperCase();
    plans.activeflag = true;
    var doc = this.firestore
      .collection(firebaseStoreService.alertPlanCollection)
      .doc();

    plans.plandocid = doc.ref.id.trim();

    return doc.set(plans);
  }

  addAlerts(Alerts: Alerts) {
    Alerts.symbol = Alerts.symbol?.toUpperCase();
    Alerts.desciption = Alerts.desciption?.toUpperCase();
    var doc = this.firestore
      .collection(firebaseStoreService.alertCollection)
      .doc();
    Alerts.alertId = doc.ref.id;
    return doc.set(Alerts);
  }

  updateAlerts(Alerts: Alerts) {
    Alerts.symbol = Alerts.symbol?.toUpperCase();
    Alerts.desciption = Alerts.desciption?.toUpperCase();
    var doc = this.firestore
      .collection(firebaseStoreService.alertCollection)
      .doc(Alerts.alertId);

    return doc.update({
      openprice: Alerts.openprice,
      desciption: Alerts.desciption,
      note: Alerts.notes,
      updatedAt: Alerts.updatedAt,
      symbol: Alerts.symbol,
      updatedBy: Alerts.updatedBy,
    });
  }
  deleteAlert(alertId: string) {
    return this.firestore
      .collection(firebaseStoreService.alertCollection)
      .doc(alertId)
      .delete();
  }
  updateAlert(alert: Alerts) {
    alert.symbol = alert.symbol?.toUpperCase();
    alert.desciption = alert.desciption?.toUpperCase();
    return this.firestore
      .collection(firebaseStoreService.alertCollection)
      .doc(alert.alertId)
      .update(alert);
  }
  updateSubscriptionTrialTime(time: any, subscriptionId: string) {
    return this.firestore
      .collection(firebaseStoreService.subscriptionCollection)
      .doc(subscriptionId)
      .update({
        trialEndTime: time,
      });
  }

  async refundToggle(id: string, toggle: boolean) {
    return await this.firestore
      .collection(firebaseStoreService.subscriptionCollection)
      .doc(id)
      .update({ refundapplied: toggle });
  }
  getSubscriptionByStripeId(id: string) {
    return this.firestore.collection<subscriptiondto>(
      firebaseStoreService.subscriptionCollection,
      (res) => res.where('stripeSubscriptionId', '==', id)
    );
  }
  tooglealertplan(Id: string, action: boolean) {
    return this.firestore
      .collection(firebaseStoreService.alertPlanCollection)
      .doc(Id)
      .update({
        activeflag: action,
      });
  }
  canceltrialSubscription(id: string) {
    return this.firestore
      .collection(firebaseStoreService.subscriptionCollection)
      .doc(id)
      .update({
        isCancelled: true,
        isEnabled: false,
        trialEndTime: this.getCurrentTime(),
      });
  }
  enableTrialSubscription(id: string) {
    return this.firestore
      .collection(firebaseStoreService.subscriptionCollection)
      .doc(id)
      .update({
        isCancelled: false,
        isEnabled: true,
        trialEndTime: this.ds.getDateAfterDays(7),
      });
  }
  getAllAlertPlans(active: boolean = true) {
    return this.firestore.collection<AlertPlans>(
      firebaseStoreService.alertPlanCollection,
      (res) => res.where('activeflag', '==', active).orderBy('planname')
    );
  }

  getAllActivePlansWithDetail(active: boolean = true) {
    return this.firestore
      .collection<AlertPlanDetails>(
        firebaseStoreService.alertPlanCollection,
        (res) => res.where('activeflag', '==', active).orderBy('planname')
      )
      .snapshotChanges();
  }
  getAlertPlan(planId: string) {
    return this.firestore
      .collection<AlertPlans>(firebaseStoreService.alertPlanCollection)
      .doc(planId)
      .get();
  }

  getUserDetail(userId: string) {
    return this.firestore
      .collection<userdto>(firebaseStoreService.userCollection)
      .doc(userId)
      .get();
  }
  getAdminDetail(userId: string) {
    return this.firestore
      .collection<userdto>(firebaseStoreService.adminCollection)
      .doc(userId)
      .get();
  }
  async getUserActiveSubscriptionWhichAreNotTrial(userId: string) {
    var subscriptions = await this.firestore
      .collection<subscriptiondto>(
        firebaseStoreService.subscriptionCollection,
        (ref) =>
          ref
            .where('userId', '==', userId)
            .where('isCanceled', '==', false)
            .where('isTrial', '==', false)
      )
      .get()
      .toPromise();

    var planIds: string[] = [];
    subscriptions.docs.forEach((e) => {
      planIds.push(e.data().alertPlanId);
    });
    return planIds;
  }

  getUserSubscriptionWhichAreNotTrial(userId: string) {
    return this.firestore
      .collection<subscriptiondto>(
        firebaseStoreService.subscriptionCollection,
        (ref) =>
          ref
            .where('userId', '==', userId)

            .where('isTrial', '==', false)
      );
  }
  getUserActiveSubscription(userId: string) {
    return this.firestore
      .collection<subscriptiondto>(
        firebaseStoreService.subscriptionCollection,
        (ref) =>
          ref.where('userId', '==', userId).where('isEnabled', '==', true)
      )
      .get();
  }

  getUserSubscription(userId: string) {
    return this.firestore.collection<subscriptiondto>(
      firebaseStoreService.subscriptionCollection,
      (ref) => ref.where('userId', '==', userId)
    );
  }
  getUserSubscriptionbyUserIdAndStripeSubscription(
    userid: string,
    subscriptionId: string
  ) {
    return this.firestore.collection<subscriptiondto>(
      firebaseStoreService.subscriptionCollection,
      (ref) =>
        ref
          .where('userId', '==', userid)
          .where('stripeSubscriptionId', '==', subscriptionId)
    );
  }
  getSubscriptionByAlertIdAndUserId(
    alertId: string,
    userId: String,
    isTrial = true
  ) {
    debugger;
    if (isTrial) {
      return this.firestore
        .collection<subscriptiondto>(
          firebaseStoreService.subscriptionCollection,
          (ref) =>
            ref.where('alertId', '==', alertId).where('userId', '==', userId)
        )
        .get();
    } else {
      return this.firestore
        .collection<subscriptiondto>(
          firebaseStoreService.subscriptionCollection,
          (ref) =>
            ref
              .where('alertPlanId', '==', alertId)
              .where('userId', '==', userId)
              .where('isTrial', '==', false)
              .where('isCancelled', '==', false)
        )
        .get();
    }
  }

  getUsers() {
    return this.firestore.collection<userdto>(
      firebaseStoreService.userCollection,
      (res) => res.orderBy('name')
    );
  }
  getAlertPlansByList(planIds: string[]) {
    return this.firestore
      .collection<AlertPlans>(firebaseStoreService.alertPlanCollection, (ref) =>
        ref.where('plandocid', 'in', planIds)
      )
      .snapshotChanges();
  }

  getInCompletePaymentSubscriptions(uid: string) {
    return this.firestore.collection<subscriptiondto>(
      firebaseStoreService.subscriptionCollection,
      (res) =>
        res
          .where('userId', '==', uid)
          .where('stripeStatus', '==', StripeStatusConstants.INCOMPLETE)
    );
  }

  getTrialExpireSubscriptions(uid: string) {
    var currentTime = this.appUtil.getServerTimestamp();
    return this.firestore.collection<subscriptiondto>(
      firebaseStoreService.subscriptionCollection,
      (res) =>
        res
          .where('userId', '==', uid)
          .where('isTrial', '==', true)
          .where('trialEndTime', '>', new Date())
    );
  }
}
