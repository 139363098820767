import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { fcmTokensdto } from 'src/app/models/fcmTokensdto';
import { userdto } from 'src/app/models/userdto';
import { AppUtilApiService } from 'src/app/services/app-util-api.service';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { NotificationService } from 'src/app/services/notification.service';


@Component({
  selector: 'app-userdevicedetails',
  templateUrl: './userdevicedetails.component.html',
  styleUrls: ['./userdevicedetails.component.css']
})
export class UserdevicedetailsComponent implements OnInit {

  uid:string
  tokens: fcmTokensdto[] = [];
  displayedColumns: string[] = ['deviceType', 'createDate', 'os', 'os_version','token'];
  dataSource !: MatTableDataSource<fcmTokensdto>;
  constructor(@Inject(MAT_DIALOG_DATA) public data: userdto, public nts: NotificationService, public appUtil: AppUtilApiService, public fs: firebaseStoreService) {
    
    this.uid = data.uid;

   }

  OnCopy()
  {
    console.log('Show');
    this.nts.showSuccess("Token Copied", "Copy Successfull");
  }
  ngOnInit(): void {
    this.fs.getFcmToken(this.uid).subscribe(res => {
      
      this.tokens = res;
      this.dataSource = new MatTableDataSource(this.tokens);
    });
  
  }

}
