import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/auth-guards/auth.service';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { NotificationService } from 'src/app/services/notification.service';
import { PaymentService } from 'src/app/services/paymentservice';
import { usersubscriptiondto } from '../../../models/subscriptiondto';

@Component({
  selector: 'app-changetrialenddatedialog',
  templateUrl: './changetrialenddatedialog.component.html',
  styleUrls: ['./changetrialenddatedialog.component.scss']
})
export class ChangetrialenddatedialogComponent implements OnInit {
  date = new Date();
  constructor(public nts:NotificationService,
    public router: Router,
    public activeroute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public auth: AuthService,
    public fc: PaymentService,
    @Inject(MAT_DIALOG_DATA) public data: usersubscriptiondto,
    public fs:firebaseStoreService,
    private SpinnerService: NgxSpinnerService,
    private dialogRef: MatDialogRef<ChangetrialenddatedialogComponent>,) { }

  ngOnInit(): void {
  }
  async trialEndDateChanged(subsc:usersubscriptiondto,event: MatDatepickerInputEvent<Date>)
  {
    console.log(subsc);
    console.log(event.value);
  this.data.trialEndTime = event.value;
    
  }
  async ChangeDate()
  {
    console.log("Data Changed");
    this.fs.updateSubscriptionTrialTime(this.data.trialEndTime, this.data.subscriptionId).then(res => {
      this.nts.showSuccess("Trial Date Updated", "Trial");
      this.dialogRef.close();
    })
      .catch(es => {
        this.nts.showError(es, "Error");
        console.log(es);
    })
  }
}
