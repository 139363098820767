import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AngularFirestore } from '@angular/fire/firestore';
import { AlertDetail, Alerts } from 'src/app/models/alertdto';


import { commentdto } from 'src/app/models/commentdto';
import { AddcommentsComponent } from '../../comments-manager/addcomments/addcomments.component';
import { FormBuilder } from '@angular/forms';

import { FullEditDialogComponent } from '../full-edit-dialog/full-edit-dialog.component';
import { ViewbasiccommentsComponent } from '../../comments-manager/viewbasiccomments/viewbasiccomments.component';
import { MatDialog } from '@angular/material/dialog';
import { AletDeleteDialogComponent } from '../alet-delete-dialog/alet-delete-dialog.component';
import { CloseAlertDialogComponent } from '../close-alert-dialog/close-alert-dialog.component';
import { EditAlertsDialogComponent } from '../edit-alerts-dialog/edit-alerts-dialog.component';
import { DeleteconfirmdialogComponent } from 'src/app/dialogs/deleteconfirmdialog/deleteconfirmdialog.component';
import { AppUtilApiService } from 'src/app/services/app-util-api.service';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { ViewCommentsDialogComponent } from '../../comments-manager/view-comments-dialog/view-comments-dialog.component';
import { AddAlertsComponent } from '../add-alerts/add-alerts.component';

export interface UserData {
  id: string;
  name: string;
  progress: string;
  fruit: string;
}

/** Constants used to fill up our data base. */
const FRUITS: string[] = [
  'blueberry', 'lychee', 'kiwi', 'mango', 'peach', 'lime', 'pomegranate', 'pineapple'
];
const NAMES: string[] = [
  'Maia', 'Asher', 'Olivia', 'Atticus', 'Amelia', 'Jack', 'Charlotte', 'Theodore', 'Isla', 'Oliver',
  'Isabella', 'Jasper', 'Cora', 'Levi', 'Violet', 'Arthur', 'Mia', 'Thomas', 'Elizabeth'
];
@Component({
  selector: 'app-myalerts',
  templateUrl: './myalerts.component.html',
  styleUrls: ['./myalerts.component.scss', './alert.scss']
})

export class MyalertsComponent implements OnInit {

  @HostListener('scroll', ['$event'])
  scrollHandler(event: any) {
    console.debug("Scroll Event");
  }

  Alert: Alerts[] = [];
  ClosedAlert: Alerts[] = [];
  displayedColumnsFooter: string[] = ['create'];
  displayedColumns: string[] = ['alertplan', 'createdAt', 'symbol', 'desciption', 'openprice', 'closePrice', 'closeAt', 'gain', 'comments', 'action'];
  dataSource !: MatTableDataSource<Alerts>;
  dataSourceClosed !: MatTableDataSource<Alerts>;

  @ViewChild(MatPaginator) paginator !: MatPaginator;
  @ViewChild(MatSort) sort !: MatSort;


  @ViewChild('paginator2', { read: MatPaginator }) paginator2 !: MatPaginator;
  @ViewChild(MatSort) sort2 !: MatSort;

  constructor(
    public appUtil: AppUtilApiService,
    public afs: firebaseStoreService,
    public dialog: MatDialog,
    public firestore: AngularFirestore,
    private formBuilder: FormBuilder,) {
    // Create 100 users
    const users = Array.from({ length: 100 }, (_, k) => createNewUser(k + 1));
  }
  ngOnInit(): void {
    this.afs.getAllAlerts().subscribe(res => {

      this.Alert = [];
      res.forEach(r => {

        let a = {} as Alerts;

        if (r.closeAt != "" && r.closeAt != null && r.closeAt != undefined) {

          if (
            r.closeAt != null &&
            r.closeAt != undefined &&
            new Date(r.closeAt.seconds * 1000) >=
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
          ) {
            this.Alert.push(r);
          }
        }
        else {
          this.Alert.push(r);
        }
      });

      // Assign the data to the data source for the table to render

      this.dataSource = new MatTableDataSource(this.Alert);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

    var dt = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    )
    this.afs.getClosedAlerts(dt).subscribe(res => {

      this.ClosedAlert = [];
      res.forEach(r => {

        let a = {} as Alerts;
        this.ClosedAlert.push(r);
      });

      this.dataSourceClosed = new MatTableDataSource(this.ClosedAlert);
      this.dataSourceClosed.paginator = this.paginator2;
      this.dataSourceClosed.sort = this.sort2;
    });

  }

  ngAfterViewInit() {

  }

  applyFilter2(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceClosed.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceClosed.paginator) {
      this.dataSourceClosed.paginator.firstPage();
    }
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  comments: commentdto[] = [];

  async ViewComments(row: Alerts) {
    this.firestore.collection("alerts").doc(row.alertId).collection<commentdto>("comments").get().subscribe(e => {
      this.comments = [];
      e.forEach(res => {
        this.comments.push(res.data());
      });
      let alerDetail: AlertDetail = { commentdto: this.comments, Alert: row };
      this.dialog.open(ViewCommentsDialogComponent, { data: alerDetail, disableClose: true });
    });
  }

  async ViewBASICComments(row: Alerts) {
    this.dialog.open(ViewbasiccommentsComponent, { data: row });
  }


  unArchive(row: Alerts) {
    this.dialog.open(DeleteconfirmdialogComponent, { data: 'UnArchive ' + row.alertplan, disableClose: true }).beforeClosed().subscribe(e => {

      if (e.data) {
        this.afs.unArchiveAlert(row?.alertId ?? '').then(e => {
          console.log("updated")

        });


      }
    });
  }
  reOpen(row: Alerts) {
    this.dialog.open(DeleteconfirmdialogComponent, { data: 'ReOpen ' + row.alertplan, disableClose: true }).beforeClosed().subscribe(e => {

      if (e.data) {
        this.afs.unArchiveAlert(row?.alertId ?? '').then(e => {
          console.log("updated")

        });


      }
    });
  }
  async addComent(row: Alerts) {
    console.log(row);
    this.dialog.open(AddcommentsComponent, { data: row, disableClose: true });
  }
  async createNew() {
    this.dialog.open(AddAlertsComponent, { disableClose: true });
  }
  async closealert(row: any) {
    console.log(row);
    this.dialog.open(CloseAlertDialogComponent, { data: row, disableClose: true });
  }

  async deleteAlert(row: Alerts) {
    this.dialog.open(AletDeleteDialogComponent, { data: row, disableClose: true });
  }

  async editAlert(row: Alerts) {
    this.dialog.open(EditAlertsDialogComponent, { data: row, disableClose: true });
  }

  async fullEditAlert(row: Alerts) {
    this.dialog.open(FullEditDialogComponent, { data: row, disableClose: true });
  }

}

/** Builds and returns a new User. */
function createNewUser(id: number): UserData {
  const name = NAMES[Math.round(Math.random() * (NAMES.length - 1))] + ' ' +
    NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) + '.';

  return {
    id: id.toString(),
    name: name,
    progress: Math.round(Math.random() * 100).toString(),
    fruit: FRUITS[Math.round(Math.random() * (FRUITS.length - 1))]
  };


}



