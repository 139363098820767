import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Alerts } from 'src/app/models/alertdto';
import { contactUsdto } from '../../models/contactusdto';
import { firebaseStoreService } from '../../services/firebasestoreservice';

@Component({
  selector: 'app-contactus-manager',
  templateUrl: './contactus-manager.component.html',
  styleUrls: ['./contactus-manager.component.css']
})
export class ContactusManagerComponent implements OnInit {
  displayedColumns: string[] = ['name', 'email', 'message'];
  dataSource !: MatTableDataSource<contactUsdto>;

  contacts : contactUsdto[]=[];
  @ViewChild(MatPaginator) paginator !: MatPaginator;
  @ViewChild(MatSort) sort !: MatSort;
  constructor(public fs:firebaseStoreService) { }

  ngOnInit() {

    this.fs.getContactQueries().valueChanges().subscribe(e=>{
      this.contacts = [];
      e.forEach(c=>{
        this.contacts.push(c);
      });


        this.dataSource = new MatTableDataSource(this.contacts);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
