import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
 
import { AppUtilApiService } from 'src/app/services/app-util-api.service';

import { AuthService } from 'src/app/auth-guards/auth.service';
 
import { first, tap } from 'rxjs/operators';
import { Router } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],

})
export class HeaderComponent implements OnInit {
  public currentUser: any
 

  constructor(
    public appUtil: AppUtilApiService,
    public auth: AuthService,
    private afAuth: AngularFireAuth,
    private router:Router,) {
      
    this.currentUser = '';
      this.isAuthenticated();
  }

  async ngOnInit(): Promise<void> {
    this.currentUser = await this.afAuth.currentUser;
    await this.isAuthenticated();
  }

  public async signOut() {
    await this.auth.logout();
    await this.isAuthenticated();
      this.auth.isUserAuthenticated = false;
  }

  public async logIn() {
   await this.router.navigate(['login']);
    await this.isAuthenticated();
  }
 
  public async isAuthenticated() {

  await  this.afAuth.authState.pipe(first()).pipe(
      tap(user => {
        if (user) {
          this.auth.isUserAuthenticated = true;
        } else {
          this.auth.isUserAuthenticated  = false;
        }
       
      })
    )
    .subscribe()
  }
}
