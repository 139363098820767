import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class SmsMessagingService {

  private sendPhoneAuthToken = "sendPhoneVerificationToken";
  private sendPhoneAuthCodeVerification = "verifyPhoneAuthCode";

  constructor(public cloud: AngularFireFunctions) { }
  
  sendPhoneVerification(phone:string)
  {
    var func = this.cloud.httpsCallable(this.sendPhoneAuthToken);
    return func({
      'phone':phone
    });
  }

  verifyAuthToken(phone: string, code: string)
  {
   var func = this.cloud.httpsCallable(this.sendPhoneAuthCodeVerification);
    return func({
      'phone': phone,
      'code':code
    });
  }

  sendSms()
  {

  }

}
