import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { AppUtilApiService } from 'src/app/services/app-util-api.service';
import { AuthService } from 'src/app/auth-guards/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { ForgotpasswordComponent } from '../forgotpassword/forgotpassword.component';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  email = new FormControl('', [Validators.required, Validators.email]);
  form !: FormGroup;
  logininvalid = false;
  loginerror: string = "";
  returnUrl: string = "/dashboard";
  hide = true;
  loginForm!: FormGroup;


  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }
    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
  constructor(
    private _location: Location,
    public router: Router,
    private activeroute: ActivatedRoute,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,

    public auth: AuthService,
    public appUtil: AppUtilApiService,
    public nts: NotificationService) {

  }

  ngOnInit(): void {


    this.loginForm = this.formBuilder.group({
      loginPass: [null, [Validators.required, Validators.min(4)]],
      loginUser: [null, [Validators.required, Validators.email]]
    });

    this.activeroute.queryParams.subscribe((params) => {
      this.returnUrl = params['returnUrl'];
      this.returnUrl = this.returnUrl ? this.returnUrl.toString().trim() : this.returnUrl;
    });

  }

  async OnSubmit() {

    this.logininvalid = false;
    if (this.loginForm?.valid) {


      var email = this.loginForm.controls['loginUser'];
      var password = this.loginForm.controls['loginPass'];


      this.auth.login(email.value, password.value).then(async e => {

        if (e.user != null) {

          debugger;
          if (await this.auth.isLocked(e.user.uid))
          {
            await this.auth.logout();
            this.logininvalid = true;
            this.loginerror = "You have been blocked";
            return;
          }
          if (this.returnUrl === '' || this.returnUrl === undefined) {
            this.router.navigateByUrl('/home');
            this.auth.isUserAuthenticated = true;
          }
          else {
            if (this.returnUrl == 'back') {
              this._location.back();

            }
            else {
              console.log(this.returnUrl);
              this.router.navigateByUrl(this.returnUrl);
            }
          }
        }

      }).catch(exc => {
        this.logininvalid = true;
        this.loginerror = exc.message;
      });

    }
  }

  async forgotpassword() {
    this.dialog.open(ForgotpasswordComponent, { disableClose: true }).beforeClosed().subscribe(e => {

      if (e) {
        this.nts.showSuccess("Please Check Your Email", "Password Reset Sent On Email");
      }

    });


  }
}



