import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as firebase from 'firebase';
import { ProgresdialogComponent } from 'src/app/dialogs/progresdialog/progresdialog.component';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {

  form!: FormGroup;
  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public auth: AngularFireAuth,
    public dialogRef:MatDialogRef<ForgotpasswordComponent>,
    public nts:NotificationService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        email: [null, [Validators.required, Validators.email]],
       
      }
      
    );
  }

  

  async changePassword() {
    debugger;
    if (this.form.valid) {
      var email = this.form.controls['email'].value;
      var dialogRef = this.dialog.open(ProgresdialogComponent, { disableClose: true });
      this.auth.sendPasswordResetEmail(email).then(e => {
        dialogRef.close();

        this.dialogRef.close({ data: true });

      }).catch(e => {
        dialogRef.close();
      });
      console.log('valid form');
    } else {
      console.log('error');
      console.log(this.form.errors);
    }
  }

}
