import { Injectable } from '@angular/core';
import firebase from 'firebase';
import { Timestamp } from 'rxjs/internal/operators/timestamp';

@Injectable({
  providedIn: 'root'
})
export class AppUtilApiService {
  AppVersion = "1.17";

  DateFormat = "MM/dd/yyyy";
  dateTimeFormat = "MM/dd/yyyy hh:mm a";



  dt = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );
  Company = {
    "Name": "Trading Academy",
    "WebSiteName": "Trading Academy",
    "WebSiteAddress": "www.tradingacademy.us",
    "Email": "info@tradingacademy.us",
    "PolicyEmail": "policy@tradingacademy.us",
    "Phone": "(804) 256-8100",
    "SMS": "8042568100",
    "FullAddress": "145 Fleet Street, STE 142, National Harbor, MD 20745",
    "Address1": "145 Fleet Street, STE 142",
    "Address2": "National Harbor, MD 20745",
  }


  CompanyLogo() {
    return "https://firebasestorage.googleapis.com/v0/b/trading-academy.appspot.com/o/images%2Ftrading_academy_logo.jpg?alt=media&token=6934b4bf-2717-4ef9-841f-cf065457f19b"
  }
  TradingAcademyLogo() {
    return "assets/img/trading_academy_logo.svg";
  }

  CompanyWebSiteName() { return this.Company.WebSiteName; }
  WebsiteVersion() { return this.AppVersion; }
  getServerTimestamp() { return firebase.firestore.FieldValue.serverTimestamp(); }



}
export class StripeStatusConstants{

    static  ACTIVE = "active";
    static  INCOMPLETE = "incomplete";
    static CANCELED = "canceled";
}

