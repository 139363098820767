import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { PaymentService } from '../../services/paymentservice';
import * as StripeSubscriptionModule from "../../models/stripesubscription";
import { subscriptiondto } from '../../models/subscriptiondto';
import { MyService, MyServiceEvent } from 'src/app/services/eventservice';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteconfirmdialogComponent } from 'src/app/dialogs/deleteconfirmdialog/deleteconfirmdialog.component';
import { GeneralConfirmationDialogDto } from '../../models/generalconfirmationdialogdto';
import { GeneraMessageDialogComponent } from '../../dialogs/genera-message-dialog/genera-message-dialog.component';
import { ProgresdialogComponent } from 'src/app/dialogs/progresdialog/progresdialog.component';
import { AddPaymentMethodComponent } from 'src/app/user-dashboard/add-payment-method/add-payment-method.component';
import { firebaseStoreService } from '../../services/firebasestoreservice';
import { NotificationService } from '../../services/notification.service';
import { StripePaymentMethodInfoModule } from '../../models/stripepaymentmethodinfo';
import { ViewpaymentmethoddialogComponent } from '../../user-dashboard/viewpaymentmethoddialog/viewpaymentmethoddialog.component';
import { AppUtilApiService } from '../../services/app-util-api.service';
import { SubscriptionInvoiceInfoModule } from 'src/app/models/subscriptioninvoicedto';
import { MatTableDataSource } from '@angular/material/table';
import { RefundpaymentComponent } from '../../user-dashboard/refundpayment/refundpayment.component';
import { throwIfEmpty } from 'rxjs/operators';
import { CardDeclineDetailsComponent } from 'src/app/user-dashboard/card-decline-details/card-decline-details.component';
import { RefundDto } from '../../models/refunddto';



@Component({
  selector: 'app-subscriptiondetail',
  templateUrl: './subscriptiondetail.component.html',
  styleUrls: ['./subscriptiondetail.component.css']
})
export class SubscriptiondetailComponent implements OnInit {


  displayedColumns: string[] = ['created', 'amountdue', 'amountpaid', 'status', 'download'];
  dataSource !: MatTableDataSource<SubscriptionInvoiceInfoModule.StripeSubscriptionInvoiceInfodto>;
  showallinvoices: boolean = false;
  invoicerecord: SubscriptionInvoiceInfoModule.StripeSubscriptionInvoiceInfodto[] = [];


  refund!: RefundDto;
  progress!: MatDialogRef<ProgresdialogComponent>;
  subscriptiondto!: subscriptiondto;
  subscription !: StripeSubscriptionModule.StripeSubscriptionModule.StripeSubscription | undefined;
  @Output() notifyParent: EventEmitter<any> = new EventEmitter();
  name!: string;
  id!: string;
  constructor(public appUtil: AppUtilApiService, public nts: NotificationService, public dialog: MatDialog, public fs: firebaseStoreService, public fc: PaymentService, private ngs: NgxSpinnerService, private service: MyService, public activeroute: ActivatedRoute) {

    this.service.doSomething("");
    this.activeroute.queryParams.subscribe(pr => {
      console.log(pr["name"]);
      console.log(pr["id"]);

      this.name = pr["name"];
      this.id = pr["id"];
      this.start(pr["name"], pr["id"]);

    });


  }
  sendNotification() {
    this.notifyParent.emit('Some value to send to the parent');
  }
  ngOnInit(): void {

    this.service.doSomething('hello');

  }

  async PauseConfirmation() {
    var data: GeneralConfirmationDialogDto = {
      title: 'Cancel Subscription',
      message: 'Are you Sure ? You will not be able to revise it',
      confirmbuttontext: 'Yes, Cancel',
      cancelbuttontext: 'No'
    }
    this.dialog.open(GeneraMessageDialogComponent, { data: data, disableClose: true }).beforeClosed().subscribe(e => {

      if (e.data) {
        this.progress = this.dialog.open(ProgresdialogComponent, { disableClose: true });

        this.fc.cancelSubscription(this.subscription?.id ?? '').subscribe(res => {
          //     console.log(res);
          this.progress.close();
          this.start(this.name, this.id);

        });
      }

    });
  }

  async ChangePaymentMethod() {
    this.dialog.open(AddPaymentMethodComponent, { data: this.subscription, disableClose: true }).beforeClosed().subscribe(e => {

      if (e) {
        this.nts.showSuccess("Paymnet Method Updated Successfully", "Payment Method");
        this.start(this.name, this.id);
      }

    });
  }
  async viewPaymentMethod() {
    var dialog = this.dialog.open(ProgresdialogComponent, { disableClose: true });

    var pm = await this.fc.getPaymentMethod(this.subscription?.paymentMethoId ?? '').toPromise();
    console.log(pm);
    var method = StripePaymentMethodInfoModule.Convert.toStripePaymentMethodInfo(pm);
    dialog.close();
    this.dialog.open(ViewpaymentmethoddialogComponent, { data: method, disableClose: true });
    console.log(JSON.parse(pm));

  }
  async ResumeConfirmation() {
    let confirmationDialog: GeneralConfirmationDialogDto = {
      title: 'Resume Subscription',
      message: 'You will be charged when subscription renews.',
      confirmbuttontext: 'Resume',
      cancelbuttontext: 'No'
    };
    this.dialog.open(GeneraMessageDialogComponent, { data: confirmationDialog, disableClose: true }).beforeClosed().subscribe(e => {

      if (e.data) {


        this.progress = this.dialog.open(ProgresdialogComponent, { disableClose: true });
        this.fc.resumeSubscription(this.subscription?.id ?? '').subscribe(res => {

          this.progress.close();
          this.start(this.name, this.id);
        });
      }

    });
  }
  async start(name: string, id: string) {
    this.progress = this.dialog.open(ProgresdialogComponent, { disableClose: true });

    this.fc.getSubscription(id).subscribe(e => {
      var subscription = e;
      this.subscription = subscription;
      this.subscription.created = new Date(subscription?.start != null ? subscription.start * 1000 : 10000);
      this.subscription.end = new Date(subscription?.end != null ? subscription.end * 1000 : 10000);
      this.subscription.status = subscription.status;

      var amount = ((subscription?.amount ?? 0) / 100);

      subscription.amount = amount;

      this.progress.close();
    });

    this.fs.getSubscriptionByStripeId(id).valueChanges().subscribe(e => {

      this.subscriptiondto = e[0];
      if (this.refund == null) {
        this.getRefundDetails();
      }
    });


  }

  async getPaymentIntentError() {


    this.fc.getPaymentIntentErrorFromInvoice(this.subscription?.invoice ?? '').subscribe(e => {

      this.dialog.open(CardDeclineDetailsComponent, { data: e, disableClose: true });
    });
  }
  async Refund() {
    var subscriptiondto = (await this.fs.getSubscriptionByStripeId(this.subscription?.id ?? '').get().toPromise());
    var subc = subscriptiondto.docs[0].data();
    this.dialog.open(RefundpaymentComponent, { data: subc, disableClose: true }).beforeClosed().subscribe(async e => {
      if (e) {
        await this.fs.refundToggle(this.subscriptiondto.subscriptionId, true);
        this.nts.showSuccess("Refund Request Received", "We Will Notify you about refund");
      }

    });
  }
  async getAllInvoices() {

    var dialog = this.dialog.open(ProgresdialogComponent, { disableClose: true });
    this.fc.getAllInvoicesOfSubscriptions(this.id).subscribe(e => {

      this.showallinvoices = true;

      this.invoicerecord = SubscriptionInvoiceInfoModule.Convert.toStripeSubscriptionInvoiceInfodto(e);
      this.invoicerecord.forEach(e => {

        e.created = new Date(e.created);
        e.amountDue = ((e.amountDue ?? 0) / 100);
        e.amountPaid = ((e.amountPaid ?? 0) / 100);
      });


      dialog.close();
      this.dataSource = new MatTableDataSource(this.invoicerecord);
    });
  }

  async getRefundDetails() {
    (await this.fs.getRefundForSubscriptionId(this.subscriptiondto?.subscriptionId ?? '')).valueChanges().subscribe(e => {
      if (e != null) {
        if (e.length > 0) {

          this.refund = e[0];
        }

      }
    });
  }
}
