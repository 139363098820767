import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { first } from 'rxjs/operators';
import { userdto } from '../models/userdto';

import { NotificationService } from './notification.service';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  user$!: firebase.default.User;

  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    public router: Router,
    private noti: NotificationService,
    private userServ: UsersService) {
    var ts = firebase.default.firestore.FieldValue.serverTimestamp();

    this.afAuth.authState.subscribe(async user => {
      if (user) {
        this.user$ = user;

        this.updateUserLocalInfo(user.uid)

        if (!this.user$.displayName) {

          this.updateGoogleUserDb(this.user$.uid);
        }

      } else {

      }
    })

  }

  async updateUserLocalInfo(user: any) {
    await this.userServ.storeLoginUserInfo(this.user$.uid);
  }

  async updateGoogleUserDb(uid: string) {
    const user = firebase.default.auth().currentUser;

    await this.afs.collection('users').doc(uid).get().pipe(first()).subscribe((res: any) => {

      let fullname = res.data().name + ' ' + res.data().lastname;
      user?.updateProfile({
        displayName: fullname,
        //  photoURL: "https://example.com/jane-q-user/profile.jpg"
      });
    });
  }



  async signup(email: string, password: string) {

    var result = await this.afAuth.createUserWithEmailAndPassword(email, password).then((res) => {
      this.router.navigate(['home']);
      this.noti.showSuccess('Welcome ' + res.user?.email, 'Log-in');
    });

  }

  async login(email: string, password: string) {
    var result = await this.afAuth.signInWithEmailAndPassword(email, password)
      .then((res) => {
        this.router.navigate(['admin']);
        this.noti.showSuccess('Welcome ' + res.user?.email, 'Log-in');
      })

  }

  async getCurrentUserId() {
    await this.afAuth.authState.pipe(first()).toPromise();
    return await this.afAuth.currentUser;
  }
  async geUserId()
  {
    return (await this.afAuth.currentUser)?.uid ;
  }


  async authState() {
    return this.afAuth.onAuthStateChanged;
  }
  async logout() {
    await this.afAuth.signOut().then(() => {
      this.router.navigate(['']);
      this.noti.showInfo('Logout successfully.', 'Logging out');
    });
    //  localStorage.removeItem('user');

  }

  async signInAnonymously() {
    await this.afAuth.signInAnonymously()
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode + ': ' + errorMessage);
      });
  }

  async inAdmin() {
    var currentUser = await this.afAuth.currentUser;

    if (currentUser == null) {
      return false;
    }

    if ((await this.afs.collection('admin').doc(currentUser.uid).get().toPromise()).exists) {
      return true;
    } else {
      return false
    }
  }

  public async isAuthenticated(): Promise<boolean> {
    var currentUser = await this.afAuth.currentUser;
    if (currentUser != null) {
      return true;
    } else {
      return false;
    }
  }

  async signOut() {
    var currentUser = await this.afAuth.currentUser;
    await this.afAuth.signOut();
    this.router.navigate(['']);
  }

  async isLoggedIn() {

    await this.afAuth.authState.pipe(first()).toPromise();
    return (await this.getCurrentUserId())?.uid != null;
    await this.afAuth.authState.subscribe(res => {
      if (res && res.uid) {
        return true;
      }
      return false;
    });

    return false;
  }

  async changePassword(password: string) {
    var usr = await this.afAuth.currentUser;
    await usr?.updatePassword(password);

  }

}
