export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBoRjqejI6SoTVum8csgd56WjxAPqfAMPs",
    authDomain: "trading-academy.firebaseapp.com",
    projectId: "trading-academy",
    storageBucket: "trading-academy.appspot.com",
    messagingSenderId: "389700196587",
    appId: "1:389700196587:web:a0cb445c26198ddc4646ae",
    measurementId: "G-S1RRBB13WD"
  },
  stripe:{
  publicKey:"pk_live_51ISHAjFL0m4LXzCriyTVKqnMXk4NxE8BzchmsSUf1gbItKeOIOhVoTLQVHnDqf3ntTayTPjJG4Ov0yH4UwO98UF700ta61vZx1"
  }
};
