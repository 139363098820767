import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';

import { ActivatedRoute } from '@angular/router';
import { param } from 'jquery';
import { MatDialog } from '@angular/material/dialog';
import { EdituserComponent } from '../edituser/edituser.component';
import { AlertPlans } from 'src/app/models/alertplandto';
import { usersubscriptiondto } from 'src/app/models/subscriptiondto';
import { userdto } from 'src/app/models/userdto';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { NotificationService } from 'src/app/services/notification.service';
import { DeleteconfirmdialogComponent } from '../../../dialogs/deleteconfirmdialog/deleteconfirmdialog.component';
import { ThrowStmt } from '@angular/compiler';
import { ChangetrialenddatedialogComponent } from '../changetrialenddatedialog/changetrialenddatedialog.component';


@Component({
  selector: 'app-viewusersubscriptions',
  templateUrl: './viewusersubscriptions.component.html',
  styleUrls: ['./viewusersubscriptions.component.css']
})
export class ViewusersubscriptionsComponent implements OnInit {
  plans: AlertPlans[] = [];
  usersubscriptions: usersubscriptiondto[] = [];
  showsubscription = false;

  displayedColumns: string[] = ['name', 'status', 'trialendtime', 'action'];
  dataSource !: MatTableDataSource<usersubscriptiondto>;
  breakpoint: number | undefined;
  userId: string = "";
  events: string[] = [];
  user ?: userdto ;
  date1 = new FormControl(new Date())
  date = new Date();
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
  }
  
  constructor(
    public nts:NotificationService,
    public fs: firebaseStoreService,
    public auth: AngularFireAuth,
    public dialog: MatDialog,
    public router:ActivatedRoute,
    private SpinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.SpinnerService.show();
    this.breakpoint = window.innerWidth <= 400 ? 1 : 6;

    this.router.queryParams.subscribe(params => {
      debugger;
      console.log(params);
      this.userId = params["userId"];
      this.auth.authState.subscribe((ath) => {
        if (ath != null) {
          let subscriptions: string[] = [];
          this.usersubscriptions = [];
          
          this.fs.getUserDetail(this.userId).subscribe(e => {
            

            this.user = e.data();


          });



          this.fs.getUserSubscription(this.userId).snapshotChanges().subscribe((e) => {
            let subscriptions: string[] = [];
          this.usersubscriptions = [];
            e.forEach((rs) => {
              var date = rs.payload.doc.data()['trialEndTime'];
              var dt = new Date();
              if (date != null && date !+ undefined)
              {
                 dt = new Date(date.seconds * 1000);
                console.log(dt);
                }
           
              let subscrs: usersubscriptiondto = {
                alertPlan: '',
                currentSubscribedTime:
                  rs.payload.doc.data()['currentSubscribedTime'],
                isCancelled: rs.payload.doc.data()['isCancelled'],
                isEnabled: rs.payload.doc.data()['isEnabled'],
                isTrial: rs.payload.doc.data()['isTrial'],
                stripeStatus: rs.payload.doc.data()['stripeStatus'],
                stripeSubscriptionId:
                  rs.payload.doc.data()['stripeSubscriptionId'],
                subscriptionId: rs.payload.doc.data()['subscriptionId'],
                alertPlanId: rs.payload.doc.data()['alertPlanId'],
                trialEndTime: rs.payload.doc.data()['trialEndTime'],
                trialEndDate:dt 
              };
              this.usersubscriptions.push(subscrs);
              subscriptions.push(rs.payload.doc.data()['alertPlanId']);
            });
            console.log("User Subscription");
           
            if (subscriptions.length > 0) {
              
            var alertPlan =  this.fs.getAlertPlansByList(subscriptions).subscribe((s) => {
              s.forEach((res) => {
                console.log(res);
                  var element = this.usersubscriptions.filter(
                    (rs) => rs.alertPlanId == res.payload.doc.data()['plandocid']
                  );
                
                    element.forEach(el => {
                      
                      el.alertPlan =
                      res.payload.doc.data()['planname'];
                    });
                 
                });
                this.SpinnerService.hide();
                this.showsubscription = true;
              console.log(this.usersubscriptions);
                this.dataSource = new MatTableDataSource(this.usersubscriptions);
                alertPlan.unsubscribe();
  
            });
              
            } else {
              this.usersubscriptions = [];
              this.SpinnerService.hide();
              this.showsubscription = true;
            }
          });
        }
      });
  });
    
   
    
  }

  
  
  async trialEndDateChanged(subsc:usersubscriptiondto)
  {
    this.dialog.open(ChangetrialenddatedialogComponent, { data: subsc, disableClose: true });
  }
  onResize(event: any) {
    this.breakpoint = event.target.innerWidth <= 400 ? 1 : 6;
  }
  async enableSubscription(subs: usersubscriptiondto)
  {
    this.dialog.open(DeleteconfirmdialogComponent, { data: 'Enable ' + subs.alertPlan, disableClose: true }).beforeClosed().subscribe(e => {
      
      if (e)
      {
        this.fs.enableTrialSubscription((subs.subscriptionId)).then(e => {
          this.nts.showSuccess("Enabled", "Subscription Enabled");
        });
        }
    });
  }
  async disableSubscription(subs: usersubscriptiondto)
  {
    this.dialog.open(DeleteconfirmdialogComponent, { data: 'Disable ' + subs.alertPlan, disableClose: true }).beforeClosed().subscribe(e => {
      
      if (e)
      {
        this.fs.canceltrialSubscription((subs.subscriptionId)).then(e => {
          this.nts.showSuccess("disabled", "Subscription Disabled");
        });
        }
    });
  }

}
