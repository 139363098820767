import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ViewbasiccommentsComponent } from 'src/app/managers/comments-manager/viewbasiccomments/viewbasiccomments.component';
import { Alerts } from 'src/app/models/alertdto';
import { commentdto } from 'src/app/models/commentdto';
import { AppUtilApiService } from 'src/app/services/app-util-api.service';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { NotificationService } from 'src/app/services/notification.service';
import { LoginService } from '../../services/login.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NgModule } from '@angular/core';

@Component({
  selector: 'app-trading-alerts',
  templateUrl: './trading-alerts.component.html',
  styleUrls: ['./trading-alerts.component.scss'],
})

export class TradingAlertsComponent implements OnInit {
  Alert: Alerts[] = [];
  filteredAlert: Alerts[] = [];
  filteredCloedAlert: Alerts[] = [];
  public searchStr = '';
  searchClosedStr = '';
  closedAlert: Alerts[] = [];

  @ViewChild('paginator2', { read: MatPaginator }) paginator2 !: MatPaginator;
  @ViewChild(MatSort) sort2 !: MatSort;

  isShow = false;
  comments: commentdto[] = [];
  displayedColumns: string[] = [
    'alertplan',
    'symbol',
    'desciption',
    'createdAt',
    'openprice',
    'closePrice',
    'closeAt',
    'gain',
    'comments',
  ];

  public dataSource!: MatTableDataSource<Alerts>;
  dataSourceClosed!: MatTableDataSource<Alerts>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    public router: Router,
    public afs: AngularFirestore,
    public auth: AngularFireAuth,
    public dialog: MatDialog,
    public fs: firebaseStoreService,
    public appUtil: AppUtilApiService,
    public loginService: LoginService,
    public nts: NotificationService,
    public fns: AngularFireFunctions
  ) {
    this.filteredAlert = this.Alert;
    this.searchStr = '';
    this.searchClosedStr = '';
  }

  filterAlert(searchString: string) {

    if (searchString.trim().length > 0) {
      return this.filteredAlert = this.Alert.filter(str =>
        (str.desciption?.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 ||
          str.symbol?.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1) ||
        str.openprice?.toString().indexOf(searchString) !== -1);
    } else {
      return this.filteredAlert = this.Alert;
    }
  }

  resetFiterAlert() {
    this.searchStr = '';
    this.filterAlert(this.searchStr);
    this.applyFilter(this.searchStr);
  }

  filterClosedAlert(searchString: string) {
    if (searchString.trim().length > 0) {
      return this.filteredCloedAlert = this.closedAlert.filter(str =>
        (str.desciption?.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 ||
          str.symbol?.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1) ||
        str.openprice?.toString().indexOf(searchString) !== -1);
    } else {
      return this.filteredCloedAlert = this.closedAlert;
    }
  }

  resetClosedFiterAlert() {
    this.searchClosedStr = '';
    this.filterClosedAlert(this.searchClosedStr);
    this.applyFilter2(this.searchClosedStr);
  }

  applyFilter(searchString: string) {
    this.dataSource.filter = searchString.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async applyFilter2(searchString: string) {

    if (searchString.trim().length > 0) {

      this.dataSourceClosed.filter = searchString.trim().toLowerCase();
      if (this.dataSourceClosed.paginator) {
        this.dataSourceClosed.paginator.firstPage();
      }

      this.filteredCloedAlert = this.closedAlert.filter(str =>
        (str.desciption?.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 ||
          str.symbol?.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1) ||
        str.openprice?.toString().indexOf(searchString) !== -1);
    }
  }

  async ViewComments(row: Alerts) {


    var count = 0;

    this.afs.collection("alerts").doc(row.alertId).collection<commentdto>("comments").get().subscribe(e => {
      this.comments = [];
      count = 0;
      e.forEach(res => {
        count++;
      });
      if (count > 0) {
        this.dialog.open(ViewbasiccommentsComponent, { data: row, disableClose: true });
      }
      else {
        this.nts.showInfo("No Comments", "Comments");
      }
    });
  }


  async ngOnInit(): Promise<void> {

    var dt = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    )
    this.fs.getClosedAlerts(dt).subscribe((e) => {
      this.closedAlert = [];

      e.forEach(res => {
        this.closedAlert.push(res);
      });

      this.dataSourceClosed = new MatTableDataSource(this.closedAlert);
      this.dataSourceClosed.paginator = this.paginator2;
      this.dataSourceClosed.sort = this.sort2;
      this.filteredCloedAlert = this.closedAlert;

    });

    var UID = await this.loginService.getCurrentUserId();
    var subscriptions = await this.fs.getUserSubscriptionsPlanIds(UID?.uid ?? '');

    (await this.fs.getActiveAlertsForUser(UID?.uid ?? '')).subscribe((e) => {
      this.Alert = [];

      e.forEach((r) => {

        if (subscriptions.find(f => r.alertplanId == f) != undefined) {

          if (r.closeAt != "" && r.closeAt != null && r.closeAt != undefined) {

            if (
              r.closeAt != null &&
              r.closeAt != undefined &&
              new Date(r.closeAt.seconds * 1000) >=
              new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate()
              )
            ) {
              this.Alert.push(r);
            }
          }
          else {
            this.Alert.push(r);
          }
        }
      });

      this.dataSource = new MatTableDataSource(this.Alert);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isShow = true;
      this.filteredAlert = this.Alert;


    });
  }
}
