import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertPlans } from 'src/app/models/alertplandto';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { NotificationService } from 'src/app/services/notification.service';
import { AletDeleteDialogComponent } from '../../alert-manager/alet-delete-dialog/alet-delete-dialog.component';


@Component({
  selector: 'app-deleteplans',
  templateUrl: './deleteplans.component.html',
  styleUrls: ['./deleteplans.component.css']
})
export class DeleteplansComponent implements OnInit {

  constructor(public Nts:NotificationService, public spinner:NgxSpinnerService, private dialogRef: MatDialogRef<AletDeleteDialogComponent>,  @Inject(MAT_DIALOG_DATA) public data: AlertPlans, public fns:firebaseStoreService) {


  }

  ngOnInit(): void {
  }

  async deleteAlert()
  {
    this.spinner.show();
    this.fns.tooglealertplan(this.data.plandocid,(!this.data.activeflag) ).then(res => {
        
      this.Nts.showInfo("Action Successfully Perfomed", this.data.planname);
      this.spinner.hide();
      this.dialogRef.close();
    }).catch(err => {
      this.spinner.hide();
      this.Nts.showError("Error","Error in deletion");
    });


  }
}
