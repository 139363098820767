import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CardDeclineDto } from '../../models/carddeclineddetails';

@Component({
  selector: 'app-card-decline-details',
  templateUrl: './card-decline-details.component.html',
  styleUrls: ['./card-decline-details.component.scss']
})
export class CardDeclineDetailsComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: CardDeclineDto) {

    debugger;
    console.log(data);
  }
  

  ngOnInit(): void {
  }

}
