import { Component, OnInit } from '@angular/core';
import { userdto } from 'src/app/models/userdto';
import { AuthService } from '../../auth-guards/auth.service';
import { firebaseStoreService } from '../../services/firebasestoreservice';
import { LoginService } from '../../services/login.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { EdituserComponent } from '../../managers/user-manager/edituser/edituser.component';
import { ProgresdialogComponent } from '../../dialogs/progresdialog/progresdialog.component';
import { SmsMessagingService } from 'src/app/services/sms-messaging.service';
import { VerifyphonenumberdialogComponent } from '../verifyphonenumberdialog/verifyphonenumberdialog.component';
import { NotificationService } from '../../services/notification.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { SmsReponseModule } from 'src/app/models/smsresponsedto';
import { ChangephonenumberdialogComponent } from '../changephonenumberdialog/changephonenumberdialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-myinfo',
  templateUrl: './myinfo.component.html',
  styleUrls: ['./myinfo.component.scss']
})
export class MyinfoComponent implements OnInit {

  userinfo!: userdto;
  constructor(public fc:AngularFireFunctions, public dialog:MatDialog, public AuthService:LoginService, 
    public firestore:AngularFirestore, public smsService:SmsMessagingService, 
    public firebaseStoreService:firebaseStoreService,public nts:NotificationService,
    public router: Router,) { }

  async ngOnInit(): Promise<void> {
    var uid = await this.AuthService.getCurrentUserId();
  
    this.firestore.collection<userdto>('users').doc(uid?.uid).valueChanges().subscribe(e => {
      
        this.userinfo = e ?? this.userinfo;
      console.log(this.userinfo);
    });
    
    
  }
  
  async verifyPhoneNumber()
  {
    console.log(this.userinfo.phone);
    

  var func =  this.fc.httpsCallable('sendPhoneVerificationToken');
  var dialogRef = this.dialog.open(ProgresdialogComponent, { disableClose: true });
    func({
      'phone':
      this.userinfo.phone
    }).subscribe(e => {
      console.log(e);

     var smsDto = SmsReponseModule.Convert.toSMSResponseDto(e);
      if (smsDto.status == 'pending')
      {
        
        dialogRef.close();
        this.dialog.open(VerifyphonenumberdialogComponent, { data: this.userinfo, disableClose: true });
      }
      else {
        this.nts.showError("Could not verify at that moment", "Verirication Failed");
      }
    });

    
    
  }
  async EditInfo()
  {
    this.dialog.open(EdituserComponent, { data: this.userinfo, disableClose: true });
  }

  async changePhoneNumber()
  {
 
    this.dialog.open(ChangephonenumberdialogComponent, { data: this.userinfo, disableClose: true });
  }

  close(){
    this.router.navigateByUrl('/home');
  }
}
