import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Alerts } from 'src/app/models/alertdto';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { NotificationService } from 'src/app/services/notification.service';
import { DeleteconfirmdialogComponent } from '../deleteconfirmdialog/deleteconfirmdialog.component';
import { GeneralConfirmationDialogDto } from '../../models/generalconfirmationdialogdto';

@Component({
  selector: 'app-genera-message-dialog',
  templateUrl: './genera-message-dialog.component.html',
  styleUrls: ['./genera-message-dialog.component.scss']
})
export class GeneraMessageDialogComponent implements OnInit {

  constructor(public Nts:NotificationService, public spinner:NgxSpinnerService, private dialogRef: MatDialogRef<GeneraMessageDialogComponent>,  @Inject(MAT_DIALOG_DATA) public data: GeneralConfirmationDialogDto, public fns:firebaseStoreService) { }

  ngOnInit(): void {
  }

  onConfirmation()
  {
    return this.dialogRef.close({ data: true });
  }
}
