import {
  Component,
  OnInit,
  Input,
  HostListener,
  ElementRef,
  ViewChild,
  AfterViewInit,
  isDevMode,
} from '@angular/core';


import { AlertPlans } from 'src/app/models/alertplandto';
import { subscriptiondto } from 'src/app/models/subscriptiondto';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { userdto } from 'src/app/models/userdto';
import { AuthService } from 'src/app/auth-guards/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModelDialogComponent } from 'src/app/dialogs/model-dialog/model-dialog.component';
import { StripeSubscriptionModule } from 'src/app/models/stripesubscription';
import { PaymentService } from 'src/app/services/paymentservice';
import { ProgresdialogComponent } from '../../../dialogs/progresdialog/progresdialog.component';
import { customerPaymentMethodsDto } from 'src/app/models/customerpaymentmethodsdto';
import { environment } from '../../../../environments/environment';

declare var require: any
var PhoneNumber = require( 'awesome-phonenumber' );
declare var Stripe: stripe.StripeStatic;
@Component({
  selector: 'app-paymentplanspage',
  templateUrl: './paymentplanspage.component.html',
  styleUrls: ['./paymentplanspage.component.css'],
})
export class PaymentplanspageComponent implements OnInit, AfterViewInit {
  @ViewChild('cardInfo') cardInfo: ElementRef | any;
  pm: PaymentService;
  mask!: string;
  plan!: string;
  methods: customerPaymentMethodsDto[] = [];

  constructor(
    public dialog: MatDialog,
    public router: Router,
    public firestore: AngularFirestore,
    public fns: AngularFireFunctions,
    public auth: AngularFireAuth,
    public authService:AuthService,

    private SpinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public ps:PaymentService,
    public firebaseStoreService:firebaseStoreService
  ) {
    this.pm = new PaymentService(fns);
    this.amount = 500;
    this.userId = '';
    this.alertId = '';
    this.setMaskAndExample('US');

  }
  openDialog(): void {}
  ngAfterViewInit(): void {
    const cardStyle = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    };
    if(isDevMode())
    {
      this.stripe = Stripe(
        'pk_test_51ISHAjFL0m4LXzCrWZ1UMRetktzNoa0rzteiy2l3Tf5GaKPe5y2vlianjyrYGT4AKvB85sTj4Gxu1BRgCm8SeZMV00qbVJDNya'
      );
    }
    else{
      this.stripe = Stripe(
        'pk_live_51ISHAjFL0m4LXzCriyTVKqnMXk4NxE8BzchmsSUf1gbItKeOIOhVoTLQVHnDqf3ntTayTPjJG4Ov0yH4UwO98UF700ta61vZx1'
      );
    }

    const elements = this.stripe.elements();

    //this.card = elements.create('card', { cardStyle });
    this.cardNumberInput = elements.create('cardNumber');

    this.cardNumberInput.mount(this.cardInput.nativeElement);

    elements.create('cardExpiry').mount(this.cardM.nativeElement);

    elements.create('cardCvc').mount(this.cardCvc.nativeElement);

    // this.card.mount(this.cardElement.nativeElement);

    this.cardNumberInput.addEventListener('change', (error: any) => {
      console.log(error);
      this.cardErrors = error.error != undefined ? error.error.message : '';
    });
  }

  @Input() amount: any;
  @Input() description: any;
  @ViewChild('cardElement') cardElement: ElementRef | any;
  @ViewChild('cardInput') cardInput: ElementRef | any;
  @ViewChild('cardCvc') cardCvc: ElementRef | any;
  @ViewChild('cardMM') cardMM: ElementRef | any;
  @ViewChild('cardM') cardM: ElementRef | any;
  handler!: StripeCheckoutHandler;

  stripe: any; // : stripe.Stripe;
  card: any;
  cardErrors: any;
  userId: string;

  cardNumberInput: any;
  cardCvcInput: any;
  cardExpiryInput: any;

  loading = false;
  confirmation: any;
  alertId: string;
  alert: AlertPlans | undefined;
  selectedPriceId?: string;
  selectedPlanId?: string;
  Signed = false;
  returnUrl: string = "";
  form!: FormGroup;
  logininvalid = false;
  loginerror: string = '';
  selectDefaultValue = "";


  errors :string[] = [];

  ngOnInit(): void {


    this.form = this.formBuilder.group({
      password: [null, [Validators.required, Validators.min(4)]],
      email: [null, [Validators.required, Validators.email]],
      name: [null, [Validators.required]],
      address: [null, [Validators.required]],
      city: [null, [Validators.required]],
      state: [null, [Validators.required]],
      zipcode: [null, [Validators.required]],
      number: [null, [Validators.required, Validators.minLength(10)]],
      disclaimer:[null,[Validators.required]]
    });





   this.returnUrl = this.router.url;
    this.SpinnerService.show();
    this.route.queryParams.subscribe((params) => {
      this.alertId = params['plan'];
      if (this.alertId == undefined) {
        this.router.navigateByUrl('plans');
      }
      this.firebaseStoreService.getAlertPlan(this.alertId).subscribe((al) => {
        this.SpinnerService.hide();
        if (al.data() == null) {
          this.router.navigateByUrl('/plans');
        }

        this.selectDefaultValue = "yearly";
        this.plan = "yearly";
        this.alert = al.data();
        console.log(this.alert?.planname);
        this.selectedPriceId = this.alert?.yearlyprice ?? '';
        this.selectedPlanId = this.alert?.yearlypriceid ?? '';
      });
    });

    this.auth.authState.subscribe((e) => {
      if (e != null) {
        console.log(e);
        this.userId = e.uid;
        this.Signed = false;
        this.ps.getCustomerPaymentMethods(this.userId).subscribe(e=>{

          this.methods = e;
        });
      } else {
        this.Signed = true;
        this.userId = '';
      }
    });
  }
  async handleForm(e: any) {
    e.preventDefault();



    const { token, error } = await this.stripe.createToken(
      this.cardNumberInput
    );
    if (token) {
      var dialogprogress=   this.dialog.open(ProgresdialogComponent, { disableClose: true });



      console.log(token);
      console.log(this.selectedPriceId);

     var pMethod = await this.pm.createPaymentMethod(token.id, (parseInt(this.selectedPriceId ?? '')*100), 'usd').toPromise();
     if (pMethod == null)
      {dialogprogress.close();
        return;
      }
     var customerMethod = await this.pm.attachPaymetnMethodToCustomer(this.userId, pMethod.paymentMethodId).toPromise();
      debugger;
      if (customerMethod.customerId == undefined)
      {
        this.cardErrors = customerMethod.decline_code != null ? customerMethod.decline_code : customerMethod.code;
        dialogprogress.close();
        return;
      }
     var res =  await  this.pm.subscribeplan(customerMethod.customerId, this.selectedPlanId ?? '', pMethod.paymentMethodId)?.toPromise();
      console.log(res);
      if (res.statusCode != undefined)
      {
        this.cardErrors = res.code + "- Unknown Reasons";
        dialogprogress.close();
        return;
      }
      const subscriptionStripe =
        StripeSubscriptionModule.Convert.toStripeSubscription(res);
        let subscription: subscriptiondto = {
          alertPlanId: this.alert?.plandocid ?? '',
          isCancelled: false,
          isTrial: false,
          isEnabled: true,
          priceId: this.selectedPlanId,
          productId:this.alert?.stripeproductid,
          stripeStatus: 'active',
          subscriptionId: '',
          currentSubscribedTime: '',
          plan: this.plan ?? '',
          amount: Number( this.selectedPriceId ?? '0'),
          paymentMethoId: pMethod.paymentMethodId,
          currentPeriodEndDate: subscriptionStripe.end,
          currentPeriodStartDate:subscriptionStripe.start,
          stripeSubscriptionId: subscriptionStripe.id ?? '',
          userId: this.userId,
          refundapplied:false
        };
        this.pm.createSubscription(subscription).subscribe(async (r) => {
          console.log(r);
          await  this.firebaseStoreService.removeTrialSubscription(this.alertId, this.userId);
          dialogprogress.close();
          const dialogRef = this.dialog.open(ModelDialogComponent);

          dialogRef.afterClosed().subscribe((result) => {
            this.router.navigateByUrl('trading-alerts');
          });
          // this.router.navigateByUrl('/alerts');
        });



    } else {
      console.log(error);
    }

    console.log(error);

    if (error) {
      // Inform the customer that there was an error.
      this.cardErrors = error.message;
    } else {
      // Send the token to your server.
      this.loading = true;


      // this.pm.createPaymentMethod(source.id, 500, "$").subscribe(e => {
      //   console.log(e);

      // });

    }
  }

  // Open the checkout handler
  async checkout(e: any) {
    this.handler.open({
      name: 'Fireship Store',
      description: this.description,
      amount: this.amount,
      email: '',
    });
    e.preventDefault();
  }
  onOptionsSelected(value: string) {
    console.log('the selected value is ' + value);
    this.plan = value;
    if (value == 'yearly') {
      this.selectedPlanId = this.alert?.yearlypriceid ?? '';
      this.selectedPriceId = this.alert?.yearlyprice ?? '';
    } else {
      this.selectedPlanId = this.alert?.monthlypriceid ?? '';
      this.selectedPriceId = this.alert?.price ?? '';
    }
  }

  async OnSubmit()
  {
    console.log('clicked');
    if (this.form.valid) {
      var dialogRef = this.dialog.open(ProgresdialogComponent, { disableClose: true });
      var email = this.form.controls['email'];
      var password = this.form.controls['password'];
      this.SpinnerService.show();
      this.authService
        .register(email.value, password.value)
        .then((e) => {
          this.SpinnerService.hide();
          console.log(e);
          if (e.user != null) {
            var controls = this.form.controls;
            debugger;
            var pn = new PhoneNumber(controls['number'].value, 'US');
            let user: userdto = {
              name: this.form.controls['name'].value,
              email: email.value,
              city: controls['city'].value,
              phone: pn.a.number.e164,
              address: controls['address'].value,
              state: controls['state'].value,
              zipcode: controls['zipcode'].value,
              uid: e.user.uid,
              isupdate: false,
              isPhoneNumberVerified: false,

              pictureUrl: '',
            };

            this.pm.registerUser(user, false).subscribe((e) => {
              console.log(e);
              dialogRef.close();
            });
          }
        })
        .catch((exc) => {
          this.logininvalid = true;
          dialogRef.close();
          this.SpinnerService.hide();
          this.loginerror = exc.message;
          console.log(exc.meesage);
        });

      console.log(email.value);
    } else {
      this.getFormValidationErrors();
    }
  }
  getFormValidationErrors() {
    Object.keys(this.form.controls).forEach((key) => {
      let controlErrors = this.form?.get(key)?.errors;
      debugger;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {

          this.errors.push('Key control: ' + key + ', keyError: ' + keyError);
          console.log('Key control: ' + key + ', keyError: ' + keyError);
        });
      }
    });
  }

  getCountryCodeForRegionCode(regionCode: string): string {
    return PhoneNumber.getCountryCodeForRegionCode(regionCode);
  }

  getExample(regionCode: string, format = 'national'): string {
    const example = PhoneNumber.getExample(regionCode);
    const countryCode = PhoneNumber.getCountryCodeForRegionCode(regionCode);

    return example.getNumber(format);
  }

  getMask(regionCode: string, format = 'national'): string {
    return this.getExample(regionCode, format).replace(/\d/g, '0');
  }

  getRegionCodeForCountryCode(regionCode: string): string {
    return PhoneNumber.getRegionCodeForCountryCode(regionCode);
  }

  setMaskAndExample(regionCode: string) {

    this.mask = this.getMask(regionCode);
  }

}
