
export module StripePaymentMethodInfoModule
{
  // To parse this data:
//
//   import { Convert, StripePaymentMethodInfo } from "./file";
//
//   const stripePaymentMethodInfo = Convert.toStripePaymentMethodInfo(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface StripePaymentMethodInfo {
  type?: Type;
}

export interface Type {
  brand?:             string;
  checks?:            Checks;
  country?:           string;
  expMonth?:          number;
  expYear?:           number;
  fingerprint?:       string;
  funding?:           string;
  generatedFrom?:     null;
  last4?:             string;
  networks?:          Networks;
  threeDSecureUsage?: ThreeDSecureUsage;
  wallet?:            null;
}

export interface Checks {
  addressLine1Check?:      null;
  addressPostalCodeCheck?: null;
  cvcCheck?:               string;
}

export interface Networks {
  available?: string[];
  preferred?: null;
}

export interface ThreeDSecureUsage {
  supported?: boolean;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
  public static toStripePaymentMethodInfo(json: string): StripePaymentMethodInfo {
      return cast(JSON.parse(json), r("StripePaymentMethodInfo"));
  }

  public static stripePaymentMethodInfoToJson(value: StripePaymentMethodInfo): string {
      return JSON.stringify(uncast(value, r("StripePaymentMethodInfo")), null, 2);
  }
}

function invalidValue(typ: any, val: any, key: any = ''): never {
  if (key) {
      throw Error(`Invalid value for key "${key}". Expected type ${JSON.stringify(typ)} but got ${JSON.stringify(val)}`);
  }
  throw Error(`Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`, );
}

function jsonToJSProps(typ: any): any {
  if (typ.jsonToJS === undefined) {
      const map: any = {};
      typ.props.forEach((p: any) => map[p.json] = { key: p.js, typ: p.typ });
      typ.jsonToJS = map;
  }
  return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
  if (typ.jsToJSON === undefined) {
      const map: any = {};
      typ.props.forEach((p: any) => map[p.js] = { key: p.json, typ: p.typ });
      typ.jsToJSON = map;
  }
  return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = ''): any {
  function transformPrimitive(typ: string, val: any): any {
      if (typeof typ === typeof val) return val;
      return invalidValue(typ, val, key);
  }

  function transformUnion(typs: any[], val: any): any {
      // val must validate against one typ in typs
      const l = typs.length;
      for (let i = 0; i < l; i++) {
          const typ = typs[i];
          try {
              return transform(val, typ, getProps);
          } catch (_) {}
      }
      return invalidValue(typs, val);
  }

  function transformEnum(cases: string[], val: any): any {
      if (cases.indexOf(val) !== -1) return val;
      return invalidValue(cases, val);
  }

  function transformArray(typ: any, val: any): any {
      // val must be an array with no invalid elements
      if (!Array.isArray(val)) return invalidValue("array", val);
      return val.map(el => transform(el, typ, getProps));
  }

  function transformDate(val: any): any {
      if (val === null) {
          return null;
      }
      const d = new Date(val);
      if (isNaN(d.valueOf())) {
          return invalidValue("Date", val);
      }
      return d;
  }

  function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
      if (val === null || typeof val !== "object" || Array.isArray(val)) {
          return invalidValue("object", val);
      }
      const result: any = {};
      Object.getOwnPropertyNames(props).forEach(key => {
          const prop = props[key];
          const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
          result[prop.key] = transform(v, prop.typ, getProps, prop.key);
      });
      Object.getOwnPropertyNames(val).forEach(key => {
          if (!Object.prototype.hasOwnProperty.call(props, key)) {
              result[key] = transform(val[key], additional, getProps, key);
          }
      });
      return result;
  }

  if (typ === "any") return val;
  if (typ === null) {
      if (val === null) return val;
      return invalidValue(typ, val);
  }
  if (typ === false) return invalidValue(typ, val);
  while (typeof typ === "object" && typ.ref !== undefined) {
      typ = typeMap[typ.ref];
  }
  if (Array.isArray(typ)) return transformEnum(typ, val);
  if (typeof typ === "object") {
      return typ.hasOwnProperty("unionMembers") ? transformUnion(typ.unionMembers, val)
          : typ.hasOwnProperty("arrayItems")    ? transformArray(typ.arrayItems, val)
          : typ.hasOwnProperty("props")         ? transformObject(getProps(typ), typ.additional, val)
          : invalidValue(typ, val);
  }
  // Numbers can be parsed by Date but shouldn't be.
  if (typ === Date && typeof val !== "number") return transformDate(val);
  return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
  return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
  return transform(val, typ, jsToJSONProps);
}

function a(typ: any) {
  return { arrayItems: typ };
}

function u(...typs: any[]) {
  return { unionMembers: typs };
}

function o(props: any[], additional: any) {
  return { props, additional };
}

function m(additional: any) {
  return { props: [], additional };
}

function r(name: string) {
  return { ref: name };
}

const typeMap: any = {
  "StripePaymentMethodInfo": o([
      { json: "type", js: "type", typ: u(undefined, r("Type")) },
  ], false),
  "Type": o([
      { json: "brand", js: "brand", typ: u(undefined, "") },
      { json: "checks", js: "checks", typ: u(undefined, r("Checks")) },
      { json: "country", js: "country", typ: u(undefined, "") },
      { json: "exp_month", js: "expMonth", typ: u(undefined, 0) },
      { json: "exp_year", js: "expYear", typ: u(undefined, 0) },
      { json: "fingerprint", js: "fingerprint", typ: u(undefined, "") },
      { json: "funding", js: "funding", typ: u(undefined, "") },
      { json: "generated_from", js: "generatedFrom", typ: u(undefined, null) },
      { json: "last4", js: "last4", typ: u(undefined, "") },
      { json: "networks", js: "networks", typ: u(undefined, r("Networks")) },
      { json: "three_d_secure_usage", js: "threeDSecureUsage", typ: u(undefined, r("ThreeDSecureUsage")) },
      { json: "wallet", js: "wallet", typ: u(undefined, null) },
  ], false),
  "Checks": o([
      { json: "address_line1_check", js: "addressLine1Check", typ: u(undefined, null) },
      { json: "address_postal_code_check", js: "addressPostalCodeCheck", typ: u(undefined, null) },
      { json: "cvc_check", js: "cvcCheck", typ: u(undefined, "") },
  ], false),
  "Networks": o([
      { json: "available", js: "available", typ: u(undefined, a("")) },
      { json: "preferred", js: "preferred", typ: u(undefined, null) },
  ], false),
  "ThreeDSecureUsage": o([
      { json: "supported", js: "supported", typ: u(undefined, true) },
  ], false),
};

}