import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AddcommentsComponent } from '../../comments-manager/addcomments/addcomments.component';
import { UserData } from '../../alert-manager/myalerts/myalerts.component';
import { AletDeleteDialogComponent } from '../../alert-manager/alet-delete-dialog/alet-delete-dialog.component';

import { DeleteplansComponent } from '../deleteplans/deleteplans.component';
import { EditplansComponent } from '../editplans/editplans.component';
import { CreateplansComponent } from '../createplans/createplans.component';
import { Alerts } from 'src/app/models/alertdto';
import { AlertPlans } from 'src/app/models/alertplandto';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { LoginService } from '../../../services/login.service';

@Component({
  selector: 'app-plansmanager',
  templateUrl: './plansmanager.component.html',
  styleUrls: ['./plansmanager.component.scss']
})
export class PlansmanagerComponent implements OnInit {


  Alert: AlertPlans[] = [];
  ClosedAlert: AlertPlans[] = [];
  displayedColumns: string[] = ['planname', 'description','price', 'yearlyprice',  'action']
  dataSource !: MatTableDataSource<AlertPlans>;
  dataSourceClosed !: MatTableDataSource<AlertPlans>;

  @ViewChild(MatPaginator) paginator !: MatPaginator;
  @ViewChild(MatSort) sort !: MatSort;

  @ViewChild('paginator2', { read: MatPaginator }) paginator2 !: MatPaginator;
  @ViewChild(MatSort) sort2 !: MatSort;
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  displayProgressSpinner = false;
  spinnerWithoutBackdrop = false;

  constructor(public auth:LoginService ,public dialog: MatDialog, public firestore: firebaseStoreService, private formBuilder: FormBuilder,) { }

  async ngOnInit(): Promise<void> {

    this.displayProgressSpinner = true;
  
    
    this.firestore.getAllAlertPlans().valueChanges().subscribe(res => {

      this.Alert = [];
      res.forEach(r => {

        let a = {} as Alerts;
        this.Alert.push(r);
      });

      // Assign the data to the data source for the table to render
      this.dataSource = new MatTableDataSource(this.Alert);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

    this.firestore.getAllAlertPlans(false).valueChanges().subscribe(res => {

      this.ClosedAlert = [];
      res.forEach(r => {
        let a = {} as Alerts;
        this.ClosedAlert.push(r);
      });

      // Assign the data to the data source for the table to render

      this.dataSourceClosed = new MatTableDataSource(this.ClosedAlert);
      this.dataSourceClosed.paginator = this.paginator2;
      this.dataSourceClosed.sort = this.sort2;
    });
    // this.firestore.collection<Alerts>('alerts', res=> res.where('alertstatus','==','C').orderBy('createdAt')).valueChanges().subscribe(res => {

    //   console.log('alerts data received');
    //   this.ClosedAlert = [];
    //   res.forEach(r => {

    //     let a = {} as Alerts;

    //     console.log(r.alertplan);
    //     this.ClosedAlert.push(r);
    //   });

    //   this.dataSourceClosed = new MatTableDataSource(this.ClosedAlert);
    // });
  }

  ngAfterViewInit() {

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  applyFilter2(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceClosed.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceClosed.paginator) {
      this.dataSourceClosed.paginator.firstPage();
    }
  }




  async createnewplan() {
    this.dialog.open(CreateplansComponent, { disableClose: true });
  }
  async deleteAlert(row: Alerts) {
    this.dialog.open(DeleteplansComponent, { data: row, disableClose: true });
  }

  async editAlert(row: Alerts) {
    this.dialog.open(EditplansComponent, { data: row, disableClose: true });
  }


}