import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Alerts } from 'src/app/models/alertdto';
import { AlertPlans } from 'src/app/models/alertplandto';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { LoginService } from 'src/app/services/login.service';
import { NotificationService } from 'src/app/services/notification.service';
import { EditAlertsDialogComponent } from '../edit-alerts-dialog/edit-alerts-dialog.component';

@Component({
  selector: 'app-full-edit-dialog',
  templateUrl: './full-edit-dialog.component.html',
  styleUrls: ['./full-edit-dialog.component.css']
})
export class FullEditDialogComponent implements OnInit {

  selectedValue: string = '';
  Alertpl !: AlertPlans;
  AlertPlans: AlertPlans[] = [];

  email = new FormControl('', [Validators.required, Validators.email]);
  form!: FormGroup;
  logininvalid = false;
  loginerror: string = '';
  returnUrl: string = '/dashboard';
  opendate  = new Date();
  dt  = new Date();
  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
  constructor(
    public Nts:NotificationService, public spinner:NgxSpinnerService, private dialogRef: MatDialogRef<EditAlertsDialogComponent>,  @Inject(MAT_DIALOG_DATA) public data: Alerts, public fns:firebaseStoreService,
    public firestoreservice: firebaseStoreService,

    public router: Router,
    private formBuilder: FormBuilder,
    public auth: LoginService,
    private SpinnerService: NgxSpinnerService,
    private _snackBar: MatSnackBar
  ) {
    this.firestoreservice.getAllAlertPlans().valueChanges().subscribe((alrt) => {
      alrt.forEach((plans) => {
        this.AlertPlans.push(plans);

      
       
      });

      this.Alertpl = this.AlertPlans.filter(es => es.planname == this.data.alertplan)[0];
      console.log(this.Alertpl);
      this.dt = this.data.closeAt != null ? new Date(this.data.closeAt.seconds * 1000) : this.dt;
    this.opendate = new Date(this.data.createdAt.seconds * 1000)
    this.form = this.formBuilder.group({
      openprice: [this.data.openprice, [Validators.required,  Validators.min(0.0000001)]],
      description: [this.data.desciption, [Validators.required]],
      notes: [this.data.notes],
      alertplan: [this.Alertpl, [Validators.required]],
      symbol: [this.data.symbol, [Validators.required]],
      opendate: [this.opendate, Validators.required],
      closedate:[this.dt,[Validators.required]],
      closeprice:[this.data.closePrice,[Validators.required, Validators.min(0.0000001)]]
    });
    });
  }

  ngOnInit(): void {
    this.dt = this.data.closeAt != null ? new Date(this.data.closeAt.seconds * 1000) : this.dt;
    this.opendate = new Date(this.data.createdAt.seconds * 1000)
    this.form = this.formBuilder.group({
      openprice: [this.data.openprice, [Validators.required, Validators.min(0.0000001)]],
      description: [this.data.desciption],
      notes: [this.data.notes],
      alertplan: [this.Alertpl, [Validators.required]],
      symbol: [this.data.symbol, [Validators.required]],
      opendate: [this.opendate, Validators.required],
      closedate:[this.dt,[Validators.required]],
      closeprice:[this.data.closePrice,[Validators.required, Validators.min(0.0000001)]]
    });
    
  }

  async OnSubmit() {

    var user = await this.auth.isAuthenticated();
    if (user) {
      console.log(this.form.controls['alertplan'].value);
      debugger;
      this.logininvalid = false;
        
      if (this.form.valid) {
        this.SpinnerService.show();
        var alertPlanName = this.form.controls['alertplan'].value;
        var symbol = this.form.controls['symbol'].value;
        var openprice = this.form.controls['openprice'].value;
      
      
        var openDate = this.form.controls['opendate'].value;
        var closeprice = this.form.controls['closeprice'].value;
        var description = this.form.controls['description'].value;
        var notes = this.form.controls['notes'].value;
        var closedate = this.form.controls['closedate'].value;
  
        var uid = await this.auth.getCurrentUserId();
        let alertPlan: Alerts = {
          
          alertId: this.data.alertId,
          alertplanId: alertPlanName.plandocid, symbol: symbol.toUpperCase(), openprice: openprice, desciption: description, notes: notes,
          alertplan: alertPlanName.planname,
          updatedBy: uid?.uid,
          createdAt: openDate,
          alertstatus: 'C',         
          closeBy: uid?.uid,
          updatedAt: this.firestoreservice.getCurrentTime(),
          closePrice: closeprice,
          closeAt: closedate
       
        };
  
        this.firestoreservice.updateAlert(alertPlan).then(res => {
          this.SpinnerService.hide();
          console.log(res);
          this.Nts.showSuccess("Updated Successfully", this.data.alertplan);
          

          this.dialogRef.close();
        }).catch(error => {
  
          this.Nts.showError(error, this.data.alertplan);
          console.log(error);
         
        });
      }
    }
  }
}
