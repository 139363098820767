import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Alerts } from 'src/app/models/alertdto';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { NotificationService } from 'src/app/services/notification.service';


@Component({
  selector: 'app-alet-delete-dialog',
  templateUrl: './alet-delete-dialog.component.html',
  styleUrls: ['./alet-delete-dialog.component.scss']
})
export class AletDeleteDialogComponent implements OnInit {

  constructor(public Nts:NotificationService, public spinner:NgxSpinnerService, 
    private dialogRef: MatDialogRef<AletDeleteDialogComponent>,  @Inject(MAT_DIALOG_DATA) public data: Alerts, public fns:firebaseStoreService) {


  }

  ngOnInit(): void {
  }

  async deleteAlert()
  {
    this.spinner.show();
    this.fns.deleteAlert(this.data.alertId ?? '').then(res => {
        
      this.Nts.showInfo("Succesfully deleted", "Alert Deleted");
      this.spinner.hide();
      this.dialogRef.close();
    }).catch(err => {
      this.spinner.hide();
      this.Nts.showError("Error","Error in deletion");
    });


  }

}
