
import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
@Component({
  selector: 'app-model-dialog',
  templateUrl: './model-dialog.component.html'
})
export class ModelDialogComponent  {

  constructor() {
    


  }
  onNoClick(): void {
  
  }

  ngOnInit(): void {
  }

}
