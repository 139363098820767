export class Reviews {
    createAt: any;
    pictureurl: any;
    rating: any;
    review: any;
    showflag: string = 'A';
    username: string | undefined;
    uid: any;

}


export interface ReviewDto {
    createAt: any;
    pictureurl: any;
    rating: number;
    review: any;
    showflag: 'A';
    username: string | undefined;
    uid: any;
}

const ratingDesc = [
    { value: 1, desc: 'Not Satisfied' },
    { value: 2, desc: 'OK' },
    { value: 3, desc: 'Satisfied' },
    { value: 4, desc: 'Very Satisfied' },
    { value: 5, desc: 'Extremely Satisfied' }];


