import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Alerts } from 'src/app/models/alertdto';
import { AlertPlans } from 'src/app/models/alertplandto';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { LoginService } from 'src/app/services/login.service';
import { NotificationService } from 'src/app/services/notification.service';


import { CloseAlertDialogComponent } from '../close-alert-dialog/close-alert-dialog.component';

@Component({
  selector: 'app-edit-alerts-dialog',
  templateUrl: './edit-alerts-dialog.component.html',
  styleUrls: ['./edit-alerts-dialog.component.css']
})
export class EditAlertsDialogComponent implements OnInit {
  selectedValue: string = '';
  Alertpl !: AlertPlans;
  AlertPlans: AlertPlans[] = [];

  email = new FormControl('', [Validators.required, Validators.email]);
  form!: FormGroup;
  logininvalid = false;
  loginerror: string = '';
  returnUrl: string = '/dashboard';
  opendate  = new Date();

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
  constructor(
    public Nts:NotificationService, public spinner:NgxSpinnerService, private dialogRef: MatDialogRef<EditAlertsDialogComponent>,  @Inject(MAT_DIALOG_DATA) public data: Alerts, public fns:firebaseStoreService,
    public firestoreservice: firebaseStoreService,

    public router: Router,
    private formBuilder: FormBuilder,
    public auth: LoginService,
    private SpinnerService: NgxSpinnerService,
    private _snackBar: MatSnackBar
  ) {
    this.firestoreservice.getAllAlertPlans().valueChanges().subscribe((alrt) => {
      alrt.forEach((plans) => {
        this.AlertPlans.push(plans);

      
       
      });

      this.Alertpl = this.AlertPlans.filter(es => es.planname == this.data.alertplan)[0];
      console.log(this.Alertpl);
      this.opendate = new Date(this.data.createdAt.seconds * 1000)
      this.form = this.formBuilder.group({
        openprice: [this.data.openprice, [Validators.required, Validators.min(0.00000001)]],
        description: [this.data.desciption],
        notes: [this.data.notes],
        alertplan: [this.Alertpl, [Validators.required]],
        symbol: [this.data.symbol, [Validators.required]],
        opendate:[this.opendate,Validators.required]
      });
    });
  }

  ngOnInit(): void {
   
    this.form = this.formBuilder.group({
      openprice: [this.data.openprice, [Validators.required,Validators.min(0.00000001)]],
      description: [this.data.desciption],
      notes: [this.data.notes],
      alertplan: [this.Alertpl, [Validators.required]],
      symbol: [this.data.symbol, [Validators.required]],
      opendate:[this.opendate,Validators.required]
    });
  }

  async OnSubmit() {

    var user = await this.auth.isAuthenticated();
    if (user) {
      console.log(this.form.controls['alertplan'].value);
      debugger;
      this.logininvalid = false;
        
      if (this.form.valid) {
        this.SpinnerService.show();
        var alertPlanName = this.form.controls['alertplan'].value;
        var symbol = this.form.controls['symbol'].value;
        var openprice = this.form.controls['openprice'].value;
        var description = this.form.controls['description'].value;
        var notes = this.form.controls['notes'].value;
        var openDate = this.form.controls['opendate'].value;
  
        var uid = await this.auth.getCurrentUserId();
        let alertPlan: Alerts = {
          
          alertId: this.data.alertId,
          alertplanId: alertPlanName.plandocid, symbol: symbol.toUpperCase(), openprice: openprice, desciption: description, notes: notes,
          alertplan: alertPlanName.planname,
          updatedAt: Date.now(),
          updatedBy: uid?.uid,
          createdAt:openDate
       
        };
  
        this.firestoreservice.updateAlert(alertPlan).then(res => {
          this.SpinnerService.hide();
          console.log(res);
          this.Nts.showSuccess("Updated Successfully", this.data.alertplan);
          

          this.dialogRef.close();
        }).catch(error => {
  
          this.Nts.showError(error, this.data.alertplan);
          console.log(error);
         
        });
      }
      else {
        console.log(this.form.errors);
      }
    }
  }
}
