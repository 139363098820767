import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Timestamp } from 'rxjs';
import { AlertPlans } from 'src/app/models/alertplandto';
import { Router } from '@angular/router';
import { firebaseStoreService } from '../../../services/firebasestoreservice';
import { LoginService } from 'src/app/services/login.service';
import { AlertPlanDetails } from '../../../models/alertdto';


@Component({
  selector: 'app-planspage',
  templateUrl: './planspage.component.html',
  styleUrls: ['./planspage.component.scss']
})
export class PlanspageComponent implements OnInit {
  isShow = false;
  AlertPlan: AlertPlanDetails[] = [];
  showtrial = false;

  returnUrl: string = "/";
  constructor(public ls: LoginService, public router: Router, public fs: firebaseStoreService, public firestore: AngularFirestore, public auth: AngularFireAuth, public fns: AngularFireFunctions) {

  }

  async ngOnInit(): Promise<void> {

    this.returnUrl = this.router.url;

    this.auth.authState.subscribe(res => {

      this.afterAuthenticating();
      if (res == null) {

        this.showtrial = true;

      }
      else {
        this.fs.getUserActiveSubscription(res.uid).subscribe(s => {



        });
        this.showtrial = false;
      }


    });


  }
  async afterAuthenticating() {

    var uid = await this.ls.getCurrentUserId();

    var subscriptions = uid != null ? await this.fs.getUserActiveSubscriptionWhichAreNotTrial(uid?.uid ?? '') : null;

    this.firestore.collection<AlertPlanDetails>('alertplans', res => res.where("activeflag", "==", true)).valueChanges().subscribe(e => {

      this.AlertPlan = [];
      e.forEach(res => {


        if (subscriptions?.find(f => f == res.plandocid)) {
          res.isAlreadyPurchased = true;
          this.AlertPlan.push(res);
        }
        else {
          res.isAlreadyPurchased = false;
          this.AlertPlan.push(res);
        }

      });
      this.isShow = true;

    });

  }

}


