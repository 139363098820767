import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertPlans } from 'src/app/models/alertplandto';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { LoginService } from 'src/app/services/login.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PaymentService, CloundFunctionsConstants } from 'src/app/services/paymentservice';
import { AletDeleteDialogComponent } from '../../alert-manager/alet-delete-dialog/alet-delete-dialog.component';

@Component({
  selector: 'app-editplans',
  templateUrl: './editplans.component.html',
  styleUrls: ['./editplans.component.scss'],
})
export class EditplansComponent implements OnInit {
  email = new FormControl('', [Validators.required, Validators.email]);
  form!: FormGroup;
  logininvalid = false;
  loginerror: string = '';
  returnUrl: string = '/dashboard';
  loading = false;

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
  constructor(
    public Nts: NotificationService,
    public spinner: NgxSpinnerService,
    private dialogRef: MatDialogRef<AletDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertPlans,
    public fns: firebaseStoreService,
    public firestoreservice: firebaseStoreService,
    public firecloud: PaymentService,
    public router: Router,
    private formBuilder: FormBuilder,
    public auth: LoginService,
    private SpinnerService: NgxSpinnerService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: [this.data.planname, [Validators.required, Validators.min(1)]],
      description: [this.data.desciption, Validators.required],
      monthlyprice: [this.data.price, Validators.required],
      yearlyprice: [this.data.yearlyprice, [Validators.required]],
    });
  }

  async OnSubmit() {
    this.SpinnerService.show();
    var user = await this.auth.isAuthenticated();
    if (user) {
      this.logininvalid = false;
      console.log(this.form.valid);
      if (this.form.valid) {
        this.loading = true;
        var uid = await this.auth.getCurrentUserId();
        var name = this.form.controls['name'].value;
        var monthlyprice = this.form.controls['monthlyprice'].value;
        var yearlyprice = this.form.controls['yearlyprice'].value;
        var description = this.form.controls['description'].value;

        this.firecloud.createProduct(name,description).subscribe((res) => {
          var productId = res;

          this.firecloud
            .createStripePLan(
              monthlyprice,
              CloundFunctionsConstants.stripemonthInterval,
              productId
            )
            .subscribe((res) => {
              var monthlyPlanId = res;

              this.firecloud
                .createStripePLan(
                  yearlyprice,
                  CloundFunctionsConstants.stripeyearInterval,
                  productId
                )
                .subscribe((res) => {
                  var yearlyPlanId = res;

                  let plan: AlertPlans = {
                    plandocid: this.data.plandocid,
                    stripeproductid: productId,
                    desciption: description,
                    monthlypriceid: monthlyPlanId,
                    price: monthlyprice,
                    planname: name.toUpperCase(),
                    yearlyprice: yearlyprice,
                    yearlypriceid: yearlyPlanId,
                    topicname: name,
                    createdAt: this.fns.getCurrentTime(),
                    updatedAt: this.fns.getCurrentTime(),

                  };

                  this.firestoreservice
                    .updateAlertPlans(plan)
                    .then((res) => {
                      this.Nts.showSuccess("Updated Plan", plan.planname);
                      this.SpinnerService.hide();
                      this.dialogRef.close();

                    })
                    .catch((err) => {
                      this.SpinnerService.hide();
                    });
                });
            });
        });
      }
    }
  }
}
