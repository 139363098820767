import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Alerts } from 'src/app/models/alertdto';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { NotificationService } from 'src/app/services/notification.service';


@Component({
  selector: 'app-deleteconfirmdialog',
  templateUrl: './deleteconfirmdialog.component.html',
  styleUrls: ['./deleteconfirmdialog.component.css']
})
export class DeleteconfirmdialogComponent implements OnInit {

  constructor(public Nts:NotificationService, public spinner:NgxSpinnerService, private dialogRef: MatDialogRef<DeleteconfirmdialogComponent>,  @Inject(MAT_DIALOG_DATA) public data: Alerts, public fns:firebaseStoreService) { }

  ngOnInit(): void {
  }

  onConfirmation()
  {
    return this.dialogRef.close({ data: true });
  }

}
