import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { userdto } from '../../models/userdto';
import { firebaseStoreService } from '../../services/firebasestoreservice';
import { NotificationService } from '../../services/notification.service';
declare var require: any
var PhoneNumber = require( 'awesome-phonenumber' );
@Component({
  selector: 'app-changephonenumberdialog',
  templateUrl: './changephonenumberdialog.component.html',
  styleUrls: ['./changephonenumberdialog.component.scss']
})
export class ChangephonenumberdialogComponent implements OnInit {
  mask!: string;
  example!: string;
  
  form!: FormGroup;
  constructor(public nts:NotificationService,  public fs:firebaseStoreService, private formBuilder: FormBuilder,
     private dialogRef: MatDialogRef<ChangephonenumberdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: userdto) {
    
   
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
     
  
      number: [this.data.wcphone, [Validators.required, Validators.minLength(10)]],
    });
    this.setMaskAndExample('US');
  }
  getCountryCodeForRegionCode(regionCode: string): string {
    return PhoneNumber.getCountryCodeForRegionCode(regionCode);
  }

  getExample(regionCode: string, format = 'national'): string {
    const example = PhoneNumber.getExample(regionCode);
    const countryCode = PhoneNumber.getCountryCodeForRegionCode(regionCode);

    return example.getNumber(format);
  }

  getMask(regionCode: string, format = 'national'): string {
    return this.getExample(regionCode, format).replace(/\d/g, '0');
  }

  getRegionCodeForCountryCode(regionCode: string): string {
    return PhoneNumber.getRegionCodeForCountryCode(regionCode);
  }

  setMaskAndExample(regionCode: string) {
    this.example = this.getExample(regionCode);
    this.mask = this.getMask(regionCode);
  }

  async ChangeNumber()
  {
    if (this.form.valid)
    {
      
      var number = this.form.controls['number'].value;
      console.log(number);
      var pn = new PhoneNumber(number+'', 'US');
      if (pn == this.data.phone)
      {
        
        this.dialogRef.close();
      }
      else {
        console.log(pn);
        debugger;
        this.fs.updatePhonerNumber(this.data.uid, pn.a.number.e164,number).then(e => {
          this.nts.showSuccess("Phone Number Updated", "Updated");
          this.dialogRef.close();
          
        });
      }
      

    }
  }
}
