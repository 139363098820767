import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { SubscriptionsComponent } from './dashboard/subscriptions/subscriptions.component';
import { LayoutModule } from '@angular/cdk/layout';
import { TradingPerformanceSummaryByYearsComponent } from './shared/trading-performance-summary-by-years/trading-performance-summary-by-years.component';
import { ToastrModule } from 'ngx-toastr';
import { MessagingService } from './messaging.service';
import { CommonModule, AsyncPipe } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ChangepasswordComponent } from './account/changepassword/changepassword.component';
import { LoginComponent } from './account/login/login.component';
import { RegisterComponent } from './account/register/register.component';
import { SubscriptiondetailComponent } from './dashboard/subscriptiondetail/subscriptiondetail.component';
import { DeleteDialogComponent } from './dialogs/delete-dialog/delete-dialog.component';
import { DeleteconfirmdialogComponent } from './dialogs/deleteconfirmdialog/deleteconfirmdialog.component';
import { ModelDialogComponent } from './dialogs/model-dialog/model-dialog.component';
import { AddAlertsComponent } from './managers/alert-manager/add-alerts/add-alerts.component';
import { AletDeleteDialogComponent } from './managers/alert-manager/alet-delete-dialog/alet-delete-dialog.component';
import { FullEditDialogComponent } from './managers/alert-manager/full-edit-dialog/full-edit-dialog.component';
import { MyalertsComponent } from './managers/alert-manager/myalerts/myalerts.component';
import { AddcommentsComponent } from './managers/comments-manager/addcomments/addcomments.component';
import { ViewbasiccommentsComponent } from './managers/comments-manager/viewbasiccomments/viewbasiccomments.component';
import { CreateplansComponent } from './managers/plans-manager/createplans/createplans.component';
import { DeleteplansComponent } from './managers/plans-manager/deleteplans/deleteplans.component';
import { EditplansComponent } from './managers/plans-manager/editplans/editplans.component';
import { PlansmanagerComponent } from './managers/plans-manager/plansmanager/plansmanager.component';
import { EdituserComponent } from './managers/user-manager/edituser/edituser.component';
import { UserdevicedetailsComponent } from './managers/user-manager/userdevicedetails/userdevicedetails.component';
import { ViewallusersComponent } from './managers/user-manager/viewallusers/viewallusers.component';
import { ViewusersubscriptionsComponent } from './managers/user-manager/viewusersubscriptions/viewusersubscriptions.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { FaqComponent } from './pages/faq/faq.component';
import { HomeComponent } from './pages/home/home.component';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { PaymentplanspageComponent } from './pages/plans/paymentplanspage/paymentplanspage.component';
import { PlanspageComponent } from './pages/plans/planspage/planspage.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { FooterComponent } from './shared/footer/footer.component';
import { MaterialModule } from './shared/material-module';
import { PlatformStatusComponent } from './shared/platform-status/platform-status.component';
import { TradingAlertsComponent } from './user-trading-pages/trading-alerts/trading-alerts.component';
import { TradingPastPerformaceComponent } from './user-trading-pages/trading-past-performace/trading-past-performace.component';
import { ViewalertspageComponent } from './user-trading-pages/viewalertspage/viewalertspage.component';
import { CloseAlertDialogComponent } from './managers/alert-manager/close-alert-dialog/close-alert-dialog.component';
import { EditAlertsDialogComponent } from './managers/alert-manager/edit-alerts-dialog/edit-alerts-dialog.component';
import { ViewCommentsDialogComponent } from './managers/comments-manager/view-comments-dialog/view-comments-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChangetrialenddatedialogComponent } from './managers/user-manager/changetrialenddatedialog/changetrialenddatedialog.component';
import { GeneraMessageDialogComponent } from './dialogs/genera-message-dialog/genera-message-dialog.component';
import { ProgresdialogComponent } from './dialogs/progresdialog/progresdialog.component';
import { MyinfoComponent } from './user-dashboard/myinfo/myinfo.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import awesomePhonenumber from 'awesome-phonenumber';
import { NgxMaskModule } from 'ngx-mask';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { VerifyphonenumberdialogComponent } from './user-dashboard/verifyphonenumberdialog/verifyphonenumberdialog.component';
import { ChangephonenumberdialogComponent } from './user-dashboard/changephonenumberdialog/changephonenumberdialog.component';
import { MdbValidationModule } from 'mdb-angular-ui-kit/validation';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';
import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbPopoverModule } from 'mdb-angular-ui-kit/popover';
import { MdbRadioModule } from 'mdb-angular-ui-kit/radio';
import { MdbRangeModule } from 'mdb-angular-ui-kit/range';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MdbScrollspyModule } from 'mdb-angular-ui-kit/scrollspy';
import { MdbTabsModule } from 'mdb-angular-ui-kit/tabs';
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip';

import { ReviewsComponent } from './shared/reviews/reviews.component';
import { EditReviewComponent } from './user-dashboard/reviews/edit-review/edit-review.component';
import { AddReviewComponent } from './user-dashboard/reviews/add-review/add-review.component';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';
import { ReviewComponent } from './managers/review-manager/review/review.component';
import { DisclaimerModalComponent } from './dialogs/disclaimer-modal/disclaimer-modal.component';
import { ListReviewsComponent } from './user-dashboard/reviews/list-reviews/list-reviews.component';
import { ForgotpasswordComponent } from './account/forgotpassword/forgotpassword.component';
import { AddPaymentMethodComponent } from './user-dashboard/add-payment-method/add-payment-method.component';
import { CardDeclineDetailsComponent } from './user-dashboard/card-decline-details/card-decline-details.component';
import { ViewpaymentmethoddialogComponent } from './user-dashboard/viewpaymentmethoddialog/viewpaymentmethoddialog.component';
import { ViewCustomerTransactionsComponent } from './user-dashboard/view-customer-transactions/view-customer-transactions.component';

import { RefundpaymentComponent } from './user-dashboard/refundpayment/refundpayment.component';
import { RefundManagerComponent } from './managers/refunds/refund-manager/refund-manager.component';
import { RefundrejectionComponent } from './managers/refunds/refundrejection/refundrejection.component';
import { RefundpaymentadminComponent } from './managers/refunds/refundpaymentadmin/refundpaymentadmin.component';


import { MdbChartModule } from 'mdb-angular-ui-kit/charts';
import { MdbDatepickerModule } from 'mdb-angular-ui-kit/datepicker';
import { MdbNotificationModule } from 'mdb-angular-ui-kit/notification';
import { MdbRatingModule } from 'mdb-angular-ui-kit/rating';
import { MdbScrollbarModule } from 'mdb-angular-ui-kit/scrollbar';
import { MdbSelectModule } from 'mdb-angular-ui-kit/select';
import { MdbSidenavModule } from 'mdb-angular-ui-kit/sidenav';
import { MdbStepperModule } from 'mdb-angular-ui-kit/stepper';
import { MdbStickyModule } from 'mdb-angular-ui-kit/sticky';
import { MdbTableModule } from 'mdb-angular-ui-kit/table';
import { MdbTimepickerModule } from 'mdb-angular-ui-kit/timepicker';
import { DeleteReviewDialogComponent } from './user-dashboard/reviews/delete-review-dialog/delete-review-dialog.component';
import { ContactusManagerComponent } from './managers/contactus-manager/contactus-manager.component';


@NgModule({
  declarations: [
    ContactusManagerComponent,
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    ViewalertspageComponent,
    PlanspageComponent,
    PaymentplanspageComponent,
    ModelDialogComponent,
    SubscriptionsComponent,
    ReviewsComponent,
    AddAlertsComponent,
    LoginComponent,
    RegisterComponent,
    MyalertsComponent,
    SubscriptiondetailComponent,
    CloseAlertDialogComponent,
    AletDeleteDialogComponent,
    DeleteDialogComponent,
    ViewCommentsDialogComponent,
    FaqComponent,
    PrivacyComponent,
    ContactUsComponent,
    TradingAlertsComponent,
    TradingPastPerformaceComponent,
    AboutUsComponent,
    NotfoundComponent,
    TradingPerformanceSummaryByYearsComponent,
    EditAlertsDialogComponent,
    AddcommentsComponent,
    ViewallusersComponent,
    ViewusersubscriptionsComponent,
    CreateplansComponent,
    PlansmanagerComponent,
    EditplansComponent,
    DeleteplansComponent,
    EdituserComponent,
    ViewbasiccommentsComponent,
    UserdevicedetailsComponent,
    ChangepasswordComponent,
    DeleteconfirmdialogComponent,
    FullEditDialogComponent,
    PlatformStatusComponent,
        ChangetrialenddatedialogComponent,
        GeneraMessageDialogComponent,
        ProgresdialogComponent,
        MyinfoComponent,
        VerifyphonenumberdialogComponent,
        ChangephonenumberdialogComponent,


    ChangetrialenddatedialogComponent,
    GeneraMessageDialogComponent,
    ProgresdialogComponent,
    MyinfoComponent,
    EditReviewComponent,
    AddReviewComponent,
    DisclaimerComponent,
    ReviewComponent,
    DisclaimerModalComponent,
    ListReviewsComponent,
    ForgotpasswordComponent,
    DeleteReviewDialogComponent,
    AddPaymentMethodComponent,
    CardDeclineDetailsComponent,
    ViewpaymentmethoddialogComponent,
    ViewCustomerTransactionsComponent,
    RefundpaymentComponent,
    RefundManagerComponent,
    RefundrejectionComponent,
    RefundpaymentadminComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,

    NgxSpinnerModule,
    AngularFireFunctionsModule,
    AngularFireModule.initializeApp(environment.firebase),
    MaterialModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    LayoutModule,
    CommonModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    FlexLayoutModule,
    MdbCarouselModule,
    NgxMaskModule.forRoot(),
    InternationalPhoneNumberModule,
    MdbCheckboxModule,
    MdbCollapseModule,

    MatProgressSpinnerModule,
    MdbDropdownModule,
    MdbFormsModule,
    MdbModalModule,

    MdbPopoverModule,
    MdbRadioModule,
    MdbRangeModule,

    MdbRippleModule,

    MdbScrollspyModule,

    MdbTabsModule,

    MdbTooltipModule,
    MdbValidationModule,
    FormsModule,
    MdbChartModule,
    MdbDatepickerModule,
    MdbNotificationModule,
    MdbRatingModule,
    MdbScrollbarModule,
    MdbSelectModule,
    MdbSidenavModule,
    MdbStepperModule,
    MdbStickyModule,
    MdbTableModule,
    MdbTimepickerModule

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [],
  providers: [MessagingService, AsyncPipe, { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },],
  bootstrap: [AppComponent]
})
export class AppModule { }
