import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { first } from 'rxjs/operators';
import { userdto } from '../models/userdto';
import { firebaseStoreService } from '../services/firebasestoreservice';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$!: firebase.default.User;
  public isUserAuthenticated = false;

  constructor(public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    public router: Router,
    public fs:firebaseStoreService
  ) {
    var ts = firebase.default.firestore.FieldValue.serverTimestamp();

    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.user$ = user;
        //    localStorage.setItem('user', JSON.stringify(this.user$));
      } else {
        //    localStorage.setItem('user', null);
      }
    })

  }

  async forgotpassword(email:string)
  {
    try {
      var resp = await this.afAuth.sendPasswordResetEmail(email)
      return true;
    }
    catch (e)
    {
      return false;
    }

  }

  async isLocked(uid:string)
  {
    return (await this.afs.collection<userdto>('users').doc(uid).get().toPromise()).data()?.statusflag == 'I';
  }
  async login(email: string, password: any) {
    return await this.afAuth.signInWithEmailAndPassword(email, password);
  }

  register(email: string, password: any) {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  async loginOLD(email: string, password: string) {
    var result = await this.afAuth.signInWithEmailAndPassword(email, password).then((res) => {
      this.router.navigate(['admin']);
      //      this.noti.showSuccess('Welcome '+ res.user.email,'Log-in');
    })

  }

  async logout() {
    await this.afAuth.signOut().then(() => {
      console.log(this.router.url);
      this.router.navigateByUrl(this.router.url, { skipLocationChange: true });
      this.isUserAuthenticated = false;

      // this.noti.showInfo('Logout successfully.','Logging out');
    });
    //  localStorage.removeItem('user');

  }

  async signOut() {
    var currentUser = await this.afAuth.currentUser;
    await this.afAuth.signOut();
    this.router.navigate(['']);
    this.isUserAuthenticated = false;
  }


  async isPhoneNumberVerified()
  {
    await this.isSignedIn();
    debugger;
    if((await this.afAuth.currentUser) != null)
    {
      var result = ((await this.fs.getUserDetail((await this.afAuth.currentUser)?.uid ?? '').toPromise()).data()?.isPhoneNumberVerified);
      return result == null ? false : !result;
    }
    else{
      return false;
    }

  }

  async signInAnonymously() {
    await this.afAuth.signInAnonymously()
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode + ': ' + errorMessage);
      });
  }

  async inAdmin() {
    var currentUser = await this.afAuth.currentUser;

    if (currentUser == null) {
      return false;
    }

    if ((await this.afs.collection('admin').doc(currentUser.uid).get().toPromise()).exists) {
      return true;
    } else {
      return false
    }
  }

  public async isAuthenticated(): Promise<boolean> {
    await this.afAuth.authState.pipe(first()).toPromise();
    var currentUser = await this.afAuth.currentUser;

    if (currentUser != null) {
      return true;
    } else {
      return false;
    }
  }

  isSignedIn() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  async isLoggedIn() {
    const user = await this.afAuth.authState.pipe(first()).toPromise();
    if (user) {
      return true;
    } else {
      return false;
    }
  }

  /*   doSomething() {
      this.isLoggedIn().pipe(
        tap(user => {
          if (user) {
            return user.uid;
          } else {
            return null;
          }
        })
      )
        .subscribe()
    }
   */

  async isLoggedIn3() {
    var rtnFlag = false;
    await this.afAuth.authState.subscribe(res => {

      console.log('res.uid: ' + res?.uid);
      if (res && res.uid) {
        rtnFlag = true;
      }
      rtnFlag = false;
    });
    return rtnFlag;

  }



}
