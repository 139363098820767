import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Alerts } from 'src/app/models/alertdto';
import { commentdto } from 'src/app/models/commentdto';
import { AppUtilApiService } from 'src/app/services/app-util-api.service';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { LoginService } from 'src/app/services/login.service';
import { NotificationService } from 'src/app/services/notification.service';


@Component({
  selector: 'app-viewbasiccomments',
  templateUrl: './viewbasiccomments.component.html',
  styleUrls: ['./viewbasiccomments.component.css']
})
export class ViewbasiccommentsComponent implements OnInit {
  form!: FormGroup;
  comment: commentdto[] = [];
  Alert: Alerts;
  constructor(@Inject(MAT_DIALOG_DATA) public data: Alerts, public nts: NotificationService,
    public fs: firebaseStoreService,
    public firestore: AngularFirestore,
    private renderer: Renderer2,
    public router: Router,
    public utilService: AppUtilApiService,
    public auth: LoginService,
    private formBuilder: FormBuilder,
    private SpinnerService: NgxSpinnerService) {


    this.Alert = data;

  }



  displayedColumns: string[] = ['createdAt', 'name'];
  dataSource !: MatTableDataSource<commentdto>;
  breakpoint: number | undefined;

  events: string[] = [];
  date1 = new FormControl(new Date())

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
  }



  ngOnInit() {
    this.SpinnerService.show();
    this.breakpoint = window.innerWidth <= 400 ? 1 : 6;



    this.form = this.formBuilder.group({

      comment: [null, [Validators.required, Validators.minLength(1)]]
    });


    this.firestore.collection("alerts").doc(this.Alert.alertId).collection<commentdto>("comments", res => res.orderBy("createdAt", "desc")).valueChanges().subscribe(e => {
      this.comment = [];

      e.forEach(res => {


        this.comment.push(res);


      });



      this.dataSource = new MatTableDataSource(this.comment);

    });




  }

  async onEdit(element: any, event: any) {


    const has = element.classList.contains("hide");

    if (has) {
      this.renderer.removeClass(element, 'hide');
    }

    this.renderer.addClass(event.currentTarget, "hide");

  }

  async onsubmit(val: any, comment: commentdto, ele: any, edit: any) {

    comment.comment = val;
    comment.updatedAt = this.fs.getCurrentTime();


    this.fs.updateComents(comment).then(res => {

      this.nts.showSuccess("Updated", comment.comment);
      // this.renderer.addClass(ele, "hide");
      // this.renderer.removeClass(edit, "hide");

    }).catch(err => {
      this.nts.showError(err, "Error");
    });

  }

  async onDelete(elm: any, comment: commentdto, ele: any, edit: any) {



    this.fs.deleteComment(comment).then(res => {


      this.nts.showSuccess("Deleted", "deleted");

    }).catch(err => {
      this.nts.showError(err, "Error");
    });

  }

  async OnSubmit() {


    var result = await this.auth.isAuthenticated();

    if (result) {
      if (this.form.valid) {

        this.SpinnerService.show();

        var comment = this.form.controls['comment'].value;


        var user = await this.auth.getCurrentUserId();
        let alertPlan: commentdto = {

          createdAt: this.fs.getCurrentTime(),
          createdBy: user?.uid,
          comment: comment,
          alertPlan: this.Alert.alertId,
          updatedBy: user?.uid,
          updatedAt: this.fs.getCurrentTime(),

        };

        this.fs.addComents(alertPlan).then(res => {


          this.SpinnerService.hide();

          this.nts.showSuccess("Added Comment", "Comment");
        }).catch(error => {

          this.nts.showError(error, "Comment");

          this.SpinnerService.hide();
        });

      }
    }
  }

}
