import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { RefundCompactDto } from 'src/app/models/refunddto';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { LoginService } from 'src/app/services/login.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RefundpaymentadminComponent } from '../refundpaymentadmin/refundpaymentadmin.component';

@Component({
  selector: 'app-refundrejection',
  templateUrl: './refundrejection.component.html',
  styleUrls: ['./refundrejection.component.scss']
})
export class RefundrejectionComponent implements OnInit {

  form!: FormGroup;
  constructor(public Nts:NotificationService, public spinner:NgxSpinnerService, private dialogRef: MatDialogRef<RefundpaymentadminComponent>,  @Inject(MAT_DIALOG_DATA) public data: RefundCompactDto, public fns:firebaseStoreService,
  public firestoreservice: firebaseStoreService,

  public router: Router,
  private formBuilder: FormBuilder,
  public auth: LoginService,
  private SpinnerService: NgxSpinnerService,
  private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      reason:[null,[Validators.required]]
    });
  }

  Reject()
  {
    if (this.form.valid)
    {
      var reason = this.form.controls['reason'].value;
      this.firestoreservice.rejectRefund(reason, this.data.id).then(e => {
        this.Nts.showSuccess("Refunds Rejected", "Refund Reject Successfully");
        this.dialogRef.close();
      })
      
    }
    else {
      
    }
  }
}
