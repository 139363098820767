import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
 

@Component({
  selector: 'app-trading-performance-summary-by-years',
  templateUrl: './trading-performance-summary-by-years.component.html',
  styleUrls: ['./trading-performance-summary-by-years.component.scss']
})
export class TradingPerformanceSummaryByYearsComponent implements OnInit {
  
  Stat: Stats[] = [];
  isShow:boolean = false; 

  constructor(
    public afs: AngularFirestore,
    ) {
      this.isShow = false;
  }
  
  ngOnInit(): void {

    this.afs.collection<Stats>('stats', ref => ref.orderBy('year', 'desc')).valueChanges().subscribe(res => {
      this.Stat = [];
      res.forEach(r => {
        this.Stat.push(r);
      });

      if (this.Stat.length > 0) {
        this.isShow = true;
      }
    });
  }
}

export interface Stats {
  year: number;
  GainLoss: any;
  avgClosePrice: any;
  avgOpenPrice: any;
  trades: number;
}