import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Reviews } from 'src/app/models/reviewdto';
import { ReviewsService } from '../reviews.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-add-review',
  templateUrl: './add-review.component.html',
  styleUrls: ['./add-review.component.scss']
})
export class AddReviewComponent implements OnInit {

  form!: FormGroup;

  constructor(
    private revServ: ReviewsService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public nts: NotificationService,
    public afAuth: AngularFireAuth,
    @Inject(MAT_DIALOG_DATA) public data: Reviews) {
    // revServ.addReview( );
  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      review: ['', [Validators.required, Validators.minLength(10)]],
      rating: ['', [Validators.required, Validators.pattern(/^[1-5]\d*$/)]],
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  }

  log($event: any): void {
    this.form.controls.rating.setValue($event);
  }

  addReview(fb: FormGroup) {
    localStorage.setItem('rating', this.form.controls.rating.value);
    localStorage.setItem('review', this.form.controls.review.value);
    this.revServ.addReview();
    this.dialog.closeAll();
  }
}