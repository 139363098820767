import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReviewDto } from 'src/app/models/reviewdto';
import { NotificationService } from 'src/app/services/notification.service';

export interface ReviewsId extends ReviewDto { id: string; }

@Component({
  selector: 'app-edit-review',
  templateUrl: './edit-review.component.html',
  styleUrls: ['./edit-review.component.scss']
})
export class EditReviewComponent implements OnInit {
  form: any;
  selectedItem: any;

  ratingDesc = [
    { value: 1, name: 'Not Satisfied' },
    { value: 2, name: 'OK' },
    { value: 3, name: 'Satisfied' },
    { value: 4, name: 'Very Satisfied' },
    { value: 5, name: 'Extremely Satisfied' }];

  constructor(
    private formBuilder: FormBuilder,
    private afs: AngularFirestore,
    private noti: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: ReviewsId,
    private dialogRef: MatDialogRef<EditReviewComponent>,
  ) { }

  ngOnInit(): void {


    this.form = this.formBuilder.group({
      id: [this.data.id],
      review: [this.data.review, [Validators.required, Validators.minLength(10)]],
      rating: [this.data.rating, [Validators.required, Validators.pattern(/^[1-5]\d*$/)]],
    });

    this.selectedItem = this.data.rating;

    //  this.form.controls['rating'].setValue(this.selected);
    this.form.get('rating').setValue(this.selectedItem);

  }


  updateReview() {
    
    const newRating = this.form.controls.rating.value;
    const newDesc = this.form.controls.review.value;
    const id = this.form.controls.id.value;

    this.afs.collection("user-reviews").doc(id).update({'rating': newRating, 'review':newDesc, 'showflag': 'U'})
    .then(() => {
      this.noti.showSuccess('Successfully', 'Review Updated');
    }).catch((error: any) => {
      this.noti.showError(error, "Error removing document ");
    });

    this.dialogRef.close();
  }

}
