import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { SmsReponseModule } from 'src/app/models/smsresponsedto';
import { userdto } from 'src/app/models/userdto';
import { SmsMessagingService } from '../../services/sms-messaging.service';
import { ProgresdialogComponent } from '../../dialogs/progresdialog/progresdialog.component';
import { NotificationService } from '../../services/notification.service';
import { firebaseStoreService } from '../../services/firebasestoreservice';
import { UserData } from '../../managers/alert-manager/myalerts/myalerts.component';


@Component({
  selector: 'app-verifyphonenumberdialog',
  templateUrl: './verifyphonenumberdialog.component.html',
  styleUrls: ['./verifyphonenumberdialog.component.scss'],
})
export class VerifyphonenumberdialogComponent implements OnInit {
  public showResend = false;
  public limit = 3;
  public sentSms = 0;
  constructor(
    public nts: NotificationService,
    public fs: firebaseStoreService,
    public dialog: MatDialog,

    public sms: SmsMessagingService,
    public dialogRef: MatDialogRef<VerifyphonenumberdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: userdto
  ) {}
  timeLeft: number = 120;
  interval:any;
  public showInterval = true;
  error = "";
  showError = false;
startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
        console.log(this.timeLeft);
      } else {
        this.pauseTimer();
        this.showInterval = false;
        this.showResend = true;
      }
    },1000)
}
pauseTimer() {
  clearInterval(this.interval);
  this.timeLeft = 120;
  // setInterval(() => {
  //   this.timeLeft = 120;
  // },1000);
}

  ngOnInit(): void {

    this.startTimer();

  }

  async verify(token: string) {

    this.showError = false;
    debugger;
    if (token.trim() && token.length == 0)
    {
      this.error = "token can not be empty";
      this.showError = true;
    }
    else
      if (token.length != 6)
      {
        this.error = "token must be of 6 digits"
        this.showError = true;
        return;
      }
    
    var dialogRef = this.dialog.open(ProgresdialogComponent, {
      disableClose: true,
    });
    var resp = await this.sms
      .verifyAuthToken(this.data.phone ?? '', token)
      .toPromise();
    var status = SmsReponseModule.Convert.toSMSResponseDto(resp);
    console.log(status);
    if (status.valid && status.status == 'approved') {
      this.fs.updateUserPhoneNumberStatus(this.data.uid, true).then((res) => {
        dialogRef.close();
        this.nts.showSuccess('Phone Number Verified', 'Verification');
        this.dialogRef.close();
      });
    }
  }

  async onResend() {
    if (this.sentSms < this.limit) {
      var dialogRef = this.dialog.open(ProgresdialogComponent, {
        disableClose: true,
      });
      this.sms.sendPhoneVerification(this.data.phone ?? '').subscribe((e) => {
        console.log(e);
        dialogRef.close();
        this.nts.showSuccess('Message Sent Sucessfully', 'Sent');
        this.showResend = false;
        this.sentSms++;
        this.showInterval = true;
        this.startTimer();
      });
    }
    else {
      this.nts.showError("Limit Reached, Try after somtime","Limit Exceeded")
    }
  }
}
