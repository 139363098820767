import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "./auth.service";
import { firebaseStoreService } from '../services/firebasestoreservice';

@Injectable({
  providedIn: 'root'
})

export class AuthAdminGuardService implements CanActivate {

  public isAuthenticated: boolean = false;
  public isLoggedIn: boolean = false;

  constructor(
    public auth: AuthService,
    public fs: firebaseStoreService,
    public router: Router) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    await this.auth.isAuthenticated().then(res => {
      this.isAuthenticated = res;

    });



    if (!await this.auth.inAdmin()) {
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
    return true;
  }
}