import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Alerts } from 'src/app/models/alertdto';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from 'src/app/services/login.service';



@Component({
  selector: 'app-close-alert-dialog',
  templateUrl: './close-alert-dialog.component.html',
  styleUrls: ['./close-alert-dialog.component.css']
})
export class CloseAlertDialogComponent implements OnInit {
  selectedValue: string = '';

  Alert: Alerts;
  form!: FormGroup;
  dt  = new Date();
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: Alerts,
  public firestoreservice: firebaseStoreService,

  public router: Router,
  private formBuilder: FormBuilder,
    public auth: LoginService,
    private dialogRef: MatDialogRef<CloseAlertDialogComponent>,
  private SpinnerService: NgxSpinnerService,
  private _snackBar: MatSnackBar) {

    console.log(data);
    this.Alert = data;
  }


  ngOnInit(): void {

    console.log(this.dt);
    this.dt = this.data.closeAt != null || this.data.closeAt == '' ? new Date(this.data.closeAt.seconds * 1000) : new Date();
   
    this.form = this.formBuilder.group({
      description: [this.data.desciption],
      notes: [this.data.notes],
      closedate:[this.data.closeAt == null || this.data.closeAt == '' ? new Date():new Date(this.data.closeAt.seconds * 1000),[Validators.required]],
      closeprice:[this.data.closePrice,[Validators.required,Validators.min(0.00000001)]]
    });
  }
  async OnSubmit() {

    var result = await this.auth.isAuthenticated();
    if (result) {
      if (this.form.valid) {
        this.SpinnerService.show();
        
        var closeprice = this.form.controls['closeprice'].value;
        var description = this.form.controls['description'].value;
        var notes = this.form.controls['notes'].value;
        var closedate = this.form.controls['closedate'].value;

        var user = await this.auth.getCurrentUserId();
        let alertPlan: Alerts = {
          desciption: description,
          notes: notes,
          alertstatus: 'C',
          alertId: this.Alert.alertId,
          closeBy: user?.uid,
          updatedBy:user?.uid,
          updatedAt: this.firestoreservice.getCurrentTime(),
          closePrice: closeprice,
          closeAt: closedate
          
          
        };

        this.firestoreservice.closeAlert(alertPlan).then(res => {
        
          
          this.SpinnerService.hide();

          this.dialogRef.close();
        }).catch(error => {

          console.log(error);
          this.SpinnerService.hide();
        });

      }
    }
  }
}
