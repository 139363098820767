import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { contactUsdto } from '../../models/contactusdto';
import { AppUtilApiService } from '../../services/app-util-api.service';
import { firebaseStoreService } from '../../services/firebasestoreservice';
import { GeneraMessageDialogComponent } from '../../dialogs/genera-message-dialog/genera-message-dialog.component';
import { ProgresdialogComponent } from '../../dialogs/progresdialog/progresdialog.component';
import { MatDialog } from '@angular/material/dialog';
import { GeneralConfirmationDialogDto } from 'src/app/models/generalconfirmationdialogdto';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  form!: FormGroup;


  constructor(public pg: MatDialog, public fs:firebaseStoreService, public formbuild:FormBuilder,public AppUtilApiService:AppUtilApiService) { }


  ngOnInit(): void {
    this.form = this.formbuild.group({
      email:[null,[Validators.required,Validators.email]],
      name:[null,[Validators.required]],
      message:[null,[Validators.required]]
    });
  }

  submit()
  {

    debugger;

      if(this.form.valid)
      {

         var name = this.form.controls['name'].value;
         var email = this.form.controls['email'].value;
         var message = this.form.controls['message'].value;

         let contact:contactUsdto={
           name: name,
           email: email,
           message: message,
           createdat: this.AppUtilApiService.getServerTimestamp(),

         }
         this.fs.contactus(contact).then(e=>{

          let gm:GeneralConfirmationDialogDto={
            title: 'Contact Us',
            message: 'We have received your message, we will notify you soon',
            confirmbuttontext: 'Ok',
            cancelbuttontext: 'Close'
          };
          this.pg.open(GeneraMessageDialogComponent,{data:gm});
          this.form.reset();
         });



      }




  }

}
