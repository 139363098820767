import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { firebaseStoreService } from '../services/firebasestoreservice';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root',
})
export class SubscribeGuardService implements CanActivate {
  public isAuthenticated: boolean = false;
  public isLoggedIn: boolean = false;

  constructor(
    public auth: AuthService,
    public fs: firebaseStoreService,
    public router: Router
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    await this.auth.isAuthenticated().then((res) => {
      this.isAuthenticated = res;
    });
    debugger;
    if ((await this.auth.isLoggedIn()) && route.queryParams['plan'] != null) {
      console.log('hard');
      var id = route.queryParams['plan'];
      var uid = this.auth.user$.uid;
      var data = await this.fs
        .getSubscriptionByAlertIdAndUserId(id, uid, false)
        .toPromise();
      debugger;
      if (!data.empty) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}
