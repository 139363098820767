import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { first, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth-guards/auth.service';
import { AppUtilApiService } from 'src/app/services/app-util-api.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  //public isLogin: any = false;

  constructor(public appUtil: AppUtilApiService,
    public auth: AuthService,
    private afAuth: AngularFireAuth,) {   this.isAuthenticated(); }

  async ngOnInit(): Promise<void> {
    await this.isAuthenticated();
  }




  public async isAuthenticated() {

    await this.afAuth.authState.pipe(first()).pipe(
      tap(user => {
        if (user) {
          this.auth.isUserAuthenticated = true;
        } else {
          this.auth.isUserAuthenticated = false;
        }
      })
    )
      .subscribe()
  }

}
