import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/auth-guards/auth.service';
import { userdto } from 'src/app/models/userdto';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { NotificationService } from 'src/app/services/notification.service';
import { PaymentService } from 'src/app/services/paymentservice';
declare var require: any
var PhoneNumber = require( 'awesome-phonenumber' );
@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.css']
})
  
export class EdituserComponent implements OnInit {
  mask!: string;
  example!: string;
  email = new FormControl('', [Validators.required, Validators.email]);
  form!: FormGroup;
  logininvalid = false;
  loginerror: string = '';
  returnUrl: string = '/dashboard';
  uid: any;
  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
  constructor(
    public nts:NotificationService,
    public router: Router,
    public activeroute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public auth: AuthService,
    public fc: PaymentService,
    public fs:firebaseStoreService,
    private SpinnerService: NgxSpinnerService,
    private dialogRef: MatDialogRef<EdituserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: userdto,
  ) {

    this.setMaskAndExample('US');
  }

  ngOnInit(): void {

    this.uid = this.data.uid;
        this.form = this.formBuilder.group({
     
          name: [this.data?.name, [Validators.required]],
          middlename:[this.data?.middlename],
      lastname:[this.data?.lastname,[Validators.required]],
          address: [this.data?.address, [Validators.required]],
          city: [this.data?.city, [Validators.required]],
          state: [this.data?.state, [Validators.required]],
          zipcode: [this.data?.zipcode, [Validators.required]],
          number: [this.data?.phone, [Validators.required, Validators.minLength(10)]],
        });
      

  

    
  }

  getFormValidationErrors() {
    Object.keys(this.form.controls).forEach((key) => {
      let controlErrors = this.form?.get(key)?.errors;
      debugger;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log('Key control: ' + key + ', keyError: ' + keyError);
        });
      }
    });
  }

  async OnSubmit() {
    console.log('clicked');
    debugger;
    this.logininvalid = false;
    if (this.form.valid) {
      var email = this.form.controls['email'];
      var password = this.form.controls['password'];
      this.SpinnerService.show();
      
          
            var controls = this.form.controls;
     
    
            let user: userdto = {
              name: this.form.controls['name'].value,
              
              city: controls['city'].value,
             
              middlename: controls['middlename'].value,
              lastname:controls['lastname'].value,
              address: controls['address'].value,
              state: controls['state'].value,
              zipcode: controls['zipcode'].value,
              uid: this.uid,
              isupdate: true,
              pictureUrl: '',
            };

      this.fc.registerUser(user, true).subscribe((e) => {
              this.nts.showSuccess("Updated ", this.data.name)
              this.dialogRef.close();
            });
        
        
       

     
    } else {
      this.getFormValidationErrors();
    }
  }

  getCountryCodeForRegionCode(regionCode: string): string {
    return PhoneNumber.getCountryCodeForRegionCode(regionCode);
  }

  getExample(regionCode: string, format = 'national'): string {
    const example = PhoneNumber.getExample(regionCode);
    const countryCode = PhoneNumber.getCountryCodeForRegionCode(regionCode);

    return example.getNumber(format);
  }

  getMask(regionCode: string, format = 'national'): string {
    return this.getExample(regionCode, format).replace(/\d/g, '0');
  }

  getRegionCodeForCountryCode(regionCode: string): string {
    return PhoneNumber.getRegionCodeForCountryCode(regionCode);
  }

  setMaskAndExample(regionCode: string) {
    this.example = this.getExample(regionCode);
    this.mask = this.getMask(regionCode);
  }

  
}
