import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AppUtilApiService } from 'src/app/services/app-util-api.service';
import { AuthService } from 'src/app/auth-guards/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public copyRightYr: number = new Date().getFullYear();
  public isLoggedIn = false;
  public companyName;
  constructor(public appUtil: AppUtilApiService,
    public afAuth: AngularFireAuth,
    private auth: AuthService) {

    this.afAuth.authState.subscribe(res => {
      if (res && res.uid) {
        //    this.isLoggedIn = true;
      }
    });

    this.companyName = appUtil.Company.Name;
  }
  ngOnInit(): void {
    this.isLoggedIn = false;
  }



  public async logout() {
    //  this.isLoggedIn = false;
    this.auth.signOut();


  }


}
