import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./account/login/login.component";
import { RegisterComponent } from "./account/register/register.component";
import { SubscriptiondetailComponent } from "./dashboard/subscriptiondetail/subscriptiondetail.component";
import { SubscriptionsComponent } from "./dashboard/subscriptions/subscriptions.component";
import { AddAlertsComponent } from "./managers/alert-manager/add-alerts/add-alerts.component";
import { MyalertsComponent } from "./managers/alert-manager/myalerts/myalerts.component";
import { CreateplansComponent } from "./managers/plans-manager/createplans/createplans.component";
import { PlansmanagerComponent } from "./managers/plans-manager/plansmanager/plansmanager.component";
import { ViewallusersComponent } from "./managers/user-manager/viewallusers/viewallusers.component";
import { ViewusersubscriptionsComponent } from "./managers/user-manager/viewusersubscriptions/viewusersubscriptions.component";
import { AboutUsComponent } from "./pages/about-us/about-us.component";
import { ContactUsComponent } from "./pages/contact-us/contact-us.component";
import { FaqComponent } from "./pages/faq/faq.component";
import { HomeComponent } from "./pages/home/home.component";
import { NotfoundComponent } from "./pages/notfound/notfound.component";
import { PaymentplanspageComponent } from "./pages/plans/paymentplanspage/paymentplanspage.component";
import { PlanspageComponent } from "./pages/plans/planspage/planspage.component";
import { PrivacyComponent } from "./pages/privacy/privacy.component";
import { PlatformStatusComponent } from "./shared/platform-status/platform-status.component";
import { TradingAlertsComponent } from "./user-trading-pages/trading-alerts/trading-alerts.component";
import { TradingPastPerformaceComponent } from "./user-trading-pages/trading-past-performace/trading-past-performace.component";
import { ViewalertspageComponent } from "./user-trading-pages/viewalertspage/viewalertspage.component";

import { AuthGuardService } from './auth-guards/auth-guard';
import { MyinfoComponent } from './user-dashboard/myinfo/myinfo.component';
import { AddReviewComponent } from "./user-dashboard/reviews/add-review/add-review.component";
import { ChangepasswordComponent } from './account/changepassword/changepassword.component';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';
import { AuthAdminGuardService } from "./auth-guards/auth-admin-guard";
import { LoginGuardService } from "./auth-guards/login-guard";
import { ReviewComponent } from "./managers/review-manager/review/review.component";
import { ViewCustomerTransactionsComponent } from './user-dashboard/view-customer-transactions/view-customer-transactions.component';
import { RefundManagerComponent } from './managers/refunds/refund-manager/refund-manager.component';
import { SubscriptionGuardService } from "./auth-guards/self-guard";
import { SubscribeGuardService } from "./auth-guards/subscribe-guard";
import { BlockGuardService } from './auth-guards/block-guard';
import { ContactusManagerComponent } from './managers/contactus-manager/contactus-manager.component';


const routes: Routes = [
  { path: '', redirectTo: 'home',pathMatch:'full'},
  { path: '',canActivate:[BlockGuardService], runGuardsAndResolvers:'always', children:[

  { path: 'home', component: HomeComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'changepassword', component: ChangepasswordComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: 'myinfo', component: MyinfoComponent, canActivate: [AuthGuardService], runGuardsAndResolvers: 'always' },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'trading-alerts', component: TradingAlertsComponent, canActivate: [AuthGuardService], runGuardsAndResolvers: 'always' },
  { path: 'trading-past-performance', component: TradingPastPerformaceComponent },
  { path: 'login', component: LoginComponent, canActivate: [LoginGuardService], runGuardsAndResolvers: 'always' },
  { path: 'createplans', component: CreateplansComponent, canActivate: [AuthAdminGuardService], runGuardsAndResolvers: 'always' },

  { path: 'alerts', component: ViewalertspageComponent },
  { path: 'transactions', component: ViewCustomerTransactionsComponent,canActivate: [AuthGuardService], runGuardsAndResolvers: 'always' },
  { path: 'plans', component: PlanspageComponent },

  { path: 'check', component: PaymentplanspageComponent,canActivate:[SubscribeGuardService],runGuardsAndResolvers:'always' },
  { path: 'checkout', component: PaymentplanspageComponent },
  { path: 'subscriptions', component: SubscriptionsComponent,canActivate: [AuthGuardService], runGuardsAndResolvers: 'always' },
  { path: 'alert-manager', component: MyalertsComponent, canActivate: [AuthAdminGuardService], runGuardsAndResolvers: 'always' },
  { path: 'subscriptionsdetail', component: SubscriptiondetailComponent,canActivate: [AuthGuardService, SubscriptionGuardService], runGuardsAndResolvers: 'always' },
  { path: 'addalerts', component: AddAlertsComponent, canActivate: [AuthAdminGuardService], runGuardsAndResolvers: 'always' },
  { path: 'register', component: RegisterComponent, canActivate: [LoginGuardService], runGuardsAndResolvers: 'always' },
  { path: 'user-manager', component: ViewallusersComponent, canActivate: [AuthAdminGuardService], runGuardsAndResolvers: 'always' },
  { path: 'viewusersubscriptions', component: ViewusersubscriptionsComponent,canActivate: [AuthGuardService], runGuardsAndResolvers: 'always' },

  { path: 'plan-manager', component: PlansmanagerComponent, canActivate: [AuthAdminGuardService], runGuardsAndResolvers: 'always' },
  { path: 'contact-manager', component: ContactusManagerComponent, canActivate: [AuthAdminGuardService], runGuardsAndResolvers: 'always' },
  { path: 'reviews-manager', component: ReviewComponent, canActivate: [AuthGuardService] },
  { path: 'refund-manager', component: RefundManagerComponent, canActivate: [AuthGuardService] },
  // {
  //   children: [
  //     { path: 'subscriptions', component: SubscriptionsComponent },
  //     { path: 'subscriptionsdetail', component: SubscriptiondetailComponent },
  //     {path:'addalerts', component:AddAlertsComponent}
  //   ],
  // },

  { path: 'createplans', component: CreateplansComponent },
  { path: 'platform', component: PlatformStatusComponent },
  { path: '404', component: NotfoundComponent },
  { path: '**', redirectTo: '/404' },

  ]},





];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 64],
      onSameUrlNavigation: 'reload'
    }
  )],
  exports: [RouterModule],
})

export class AppRoutingModule { }
