import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ViewbasiccommentsComponent } from 'src/app/managers/comments-manager/viewbasiccomments/viewbasiccomments.component';
import { Alerts } from 'src/app/models/alertdto';
import { commentdto } from 'src/app/models/commentdto';
import { AppUtilApiService } from 'src/app/services/app-util-api.service';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { NotificationService } from 'src/app/services/notification.service';


@Component({
  selector: 'app-trading-past-performace',
  templateUrl: './trading-past-performace.component.html',
  styleUrls: ['./trading-past-performace.component.scss']
})
export class TradingPastPerformaceComponent implements OnInit {
  isShow: boolean = false;
  searchStr = '';
  Alert: Alerts[] = [];
  filteredAlert: Alerts[] = [];
  comments: commentdto[] = [];
  displayedColumns: string[] = [
    'alertplan',

    'symbol',
    'desciption',
    'createdAt',
    'openprice',
    'closePrice',
    'closeAt',

  ];
  dataSource!: MatTableDataSource<Alerts>;
  dataSourceClosed!: MatTableDataSource<Alerts>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    public router: Router,
    public afs: AngularFirestore,

    public dialog: MatDialog,
    public fs: firebaseStoreService,
    public appUtil: AppUtilApiService,
    public nts: NotificationService,
    public fns: AngularFireFunctions,
    public firestore: AngularFirestore,
    public auth: AngularFireAuth,
  ) {
    this.isShow = false;
    this.filteredAlert = this.Alert;
  }

  applyFilter(searchString: string) {
    if (searchString.trim().length > 0) {
      const filterValue = searchString; // (event.target as HTMLInputElement).value;
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }

      return this.dataSource.filter = filterValue.trim().toLowerCase();
    } else {
      return this.dataSource.filter = '';
    }
  }

  async applyFilter2(searchString: string) {


    if (searchString.trim().length > 0) {

      this.filteredAlert = this.Alert.filter(str =>
        (str.desciption?.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 ||
          str.symbol?.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1) ||
        str.openprice?.toString().indexOf(searchString) !== -1);

      console.log(this.filteredAlert);

      return this.filteredAlert;
    } else {
      return this.filteredAlert = this.Alert;
    }
  }

  resetFiterAlert() {
    this.searchStr = '';
    this.applyFilter(this.searchStr);
    this.filteredAlert= this.Alert;
  }

  async ViewComments(row: Alerts) {
    var count = 0;
    this.afs.collection("alerts").doc(row.alertId).collection<commentdto>("comments").get().subscribe(e => {
      this.comments = [];
      count = 0;
      e.forEach(res => {
        count++;
      });

      if (count > 0) {
        this.dialog.open(ViewbasiccommentsComponent, { data: row });
      }
      else {
        this.nts.showInfo("No Comments", "Comments");
      }
    });
  }

  ngOnInit(): void {

    this.fs.getClosedAlerts(this.appUtil.dt).subscribe((e) => {
      this.Alert = [];

      e.forEach((r) => {
        this.Alert.push(r);
      });

      this.dataSource = new MatTableDataSource(this.Alert);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isShow = true;

      this.filteredAlert = this.Alert;
    });

  }

  public formatPerfomance(price: number) {
    if (price < 0) {
      return 'red';
    }
    if (price > 0) {
      return 'green';
    }
    return 'black';
  }
}