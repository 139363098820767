export interface userdto {
  name: string;
  middlename?: string;
  lastname?: string;
  pictureUrl?: string;
  email?: string;
  uid: string;
  isupdate: boolean;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  phone?: string;
  wcphone?: string;
  lastLogin?: any;
  isPhoneNumberVerified?: boolean;
  statusflag?: string;
  alertsflag?: boolean;
  disclaimerSigned?: string;
  stripeCustId?: string;
}

export class userClass {
  name!: string;
  middlename?: string;
  lastname!: string;
  pictureUrl?: string;
  email?: string;
  uid!: string;
  isupdate!: boolean;
  address!: string;
  city!: string;
  state!: string;
  zipcode!: string;
  phone?: string;
  lastLogin?: any;
  isPhoneNumberVerified?: boolean;
  statusflag?: string;
  alertsflag?: boolean;
  disclaimerSigned?: string;
  fullName?: string;
}

 