import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { NgxSpinnerService } from 'ngx-spinner';
import { Alerts } from 'src/app/models/alertdto';
import { commentdto } from 'src/app/models/commentdto';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { LoginService } from 'src/app/services/login.service';


import { CloseAlertDialogComponent } from '../../alert-manager/close-alert-dialog/close-alert-dialog.component';

@Component({
  selector: 'app-addcomments',
  templateUrl: './addcomments.component.html',
  styleUrls: ['./addcomments.component.css']
})
export class AddcommentsComponent implements OnInit {

  selectedValue: string = '';

  Alert: Alerts;
  form!: FormGroup;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: Alerts,
  public firestoreservice: firebaseStoreService,

  public router: Router,
  private formBuilder: FormBuilder,
    public auth: LoginService,
    private dialogRef: MatDialogRef<CloseAlertDialogComponent>,
  private SpinnerService: NgxSpinnerService,
  private _snackBar: MatSnackBar) {

    console.log(data);
    this.Alert = data;
  }


  ngOnInit(): void {
    this.form = this.formBuilder.group({
      
      comment:[null,[Validators.required, Validators.minLength(1)]]
    });
  }
  async OnSubmit() {

   
    var result = await this.auth.isAuthenticated();
    console.log(result);
    if (result) {
      if (this.form.valid) {
        console.log("here submit");
        this.SpinnerService.show();
        
        var comment = this.form.controls['comment'].value;
      

        var user = await this.auth.getCurrentUserId();
        let alertPlan: commentdto = {
          
          createdAt: Date.now(),
          createdBy: user?.uid,
          comment: comment,
          alertPlan:this.Alert.alertId,
          updatedBy:user?.uid,
          updatedAt: Date.now(),
                    
        };

        this.firestoreservice.addComents(alertPlan).then(res => {
        
          
          
          this.SpinnerService.hide();

          this.dialogRef.close();
        }).catch(error => {

          console.log(error);
          this.SpinnerService.hide();
        });

      }
    }
  }
}
