import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { firebaseStoreService } from '../../services/firebasestoreservice';
import {
  subscriptiondto,
  usersubscriptiondto,
} from '../../models/subscriptiondto';
import { AlertPlans } from '../../models/alertplandto';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';
import { NotificationService } from '../../services/notification.service';


@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.css'],
})
export class SubscriptionsComponent implements OnInit {
  plans: AlertPlans[] = [];
  usersubscriptions: usersubscriptiondto[] = [];
  showsubscription = false;

  displayedColumns: string[] = ['name', 'status', 'notifications', 'action' , ];
  dataSource !: MatTableDataSource<usersubscriptiondto>;
  breakpoint: number | undefined;

  events: string[] = [];
  date1 = new FormControl(new Date())
  date = new Date();
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
  }
  
  constructor(
    public nts:NotificationService,
    public fs: firebaseStoreService,
    public auth: AngularFireAuth,
    private SpinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.SpinnerService.show();
    this.breakpoint = window.innerWidth <= 400 ? 1 : 6;

    this.auth.authState.subscribe((ath) => {
      if (ath != null) {
        let subscriptions: string[] = [];
        this.usersubscriptions = [];
        
        this.fs.getUserSubscription(ath.uid).snapshotChanges().subscribe((e) => {
          let subscriptions: string[] = [];
        this.usersubscriptions = [];
          e.forEach((rs) => {
            var date = rs.payload.doc.data()['trialEndTime'];
            var dt = new Date();
            if (date != null && date !+ undefined)
            {
               dt = new Date(date.seconds * 1000);
              console.log(dt);
              }
         
            let subscrs: usersubscriptiondto = {
              alertPlan: '',
              currentSubscribedTime:
                rs.payload.doc.data()['currentSubscribedTime'],
              isCancelled: rs.payload.doc.data()['isCancelled'],
              isEnabled: rs.payload.doc.data()['isEnabled'],
              isTrial: rs.payload.doc.data()['isTrial'],
              stripeStatus: rs.payload.doc.data()['stripeStatus'],
              stripeSubscriptionId:
                rs.payload.doc.data()['stripeSubscriptionId'],
              subscriptionId: rs.payload.doc.data()['subscriptionId'],
              alertPlanId: rs.payload.doc.data()['alertPlanId'],
              trialEndTime: rs.payload.doc.data()['trialEndTime'],
              trialEndDate:dt 
            };
            this.usersubscriptions.push(subscrs);
            subscriptions.push(rs.payload.doc.data()['alertPlanId']);
          });
         
          if (subscriptions.length > 0) {
            
          var alertPlan =  this.fs.getAlertPlansByList(subscriptions).subscribe((s) => {
            s.forEach((res) => {
           //   console.log(res);
                var element = this.usersubscriptions.filter(
                  (rs) => rs.alertPlanId == res.payload.doc.data()['plandocid']
              );
              element.forEach(ele => {
                ele.alertPlan =
                  res.payload.doc.data()['planname'];
              });
               
              });
              this.SpinnerService.hide();
              this.showsubscription = true;
          //  console.log(this.usersubscriptions);
              this.dataSource = new MatTableDataSource(this.usersubscriptions);
              alertPlan.unsubscribe();

          });
            
          } else {
            this.usersubscriptions = [];
            this.SpinnerService.hide();
            this.showsubscription = true;
          }
        });
      }
    });    
    
  }

  async trialEndDateChanged(subsc:usersubscriptiondto,event: MatDatepickerInputEvent<Date>)
  {
   // console.log(subsc);
   // console.log(event.value);
    this.fs.updateSubscriptionTrialTime(event.value, subsc.subscriptionId).then(res => {
      this.nts.showSuccess("Trial Date Updated", "Trial");
    })
      .catch(es => {
        this.nts.showError(es, "Error");
    })
  }
  onResize(event: any) {
    this.breakpoint = event.target.innerWidth <= 400 ? 1 : 6;
  }
}
