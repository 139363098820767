import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "./auth.service";
import { Location } from '@angular/common';
@Injectable({
  providedIn: 'root'
})

export class LoginGuardService implements CanActivate {

  public isAuthenticated: boolean = false;
  public isLoggedIn: boolean = false;

  constructor(
    public auth: AuthService,
    public _location:Location,
    public router: Router) { }

  async canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    await this.auth.isAuthenticated().then(res => {
      this.isAuthenticated = res;

    });

    console.log('this.rtn: ' + this.isAuthenticated);
    console.log('isLog: ' + await this.auth.isLoggedIn());

    console.log('state.url: ' + state.url);

    if (await this.auth.isLoggedIn()) {

      this.router.navigateByUrl("/home");
      return false;
    }
    return true;
  }
}