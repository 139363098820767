import {  Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Timestamp } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ViewCommentsDialogComponent } from 'src/app/managers/comments-manager/view-comments-dialog/view-comments-dialog.component';
import { Alerts } from 'src/app/models/alertdto';
import { commentdto } from 'src/app/models/commentdto';
import { AppUtilApiService } from 'src/app/services/app-util-api.service';



function hello() {
 
}
@Component({
  selector: 'app-viewalertspage',
  templateUrl: './viewalertspage.component.html',
  styleUrls: ['./viewalertspage.component.css']
})
export class ViewalertspageComponent implements OnInit {

  alertSubscription: any;
  Alert: Alerts[] = [];
  comments: commentdto[] = [];
 
  
  // Check to show datatabe when firebase data comes
  isShow = false;
 
  showtrial = false;
  returnUrl: string = "/";



  displayedColumns: string[] = [    'alertplan',   'symbol', 'desciption', 'createdAt', 'openprice', 'closePrice', 'closeAt', 'gain', 'comments'];
  dataSource !: MatTableDataSource<Alerts>;
  dataSourceClosed !: MatTableDataSource<Alerts>;

  @ViewChild(MatPaginator) paginator !: MatPaginator;
  @ViewChild(MatSort) sort !: MatSort;


  constructor(public appUtil:AppUtilApiService,public dialog: MatDialog,public router: Router, public firestore: AngularFirestore, public auth: AngularFireAuth, public fns: AngularFireFunctions) {
    
    this.returnUrl = this.router.url;

    this.auth.authState.subscribe(res => {
      
      if (res == null)
      {
        
        this.showtrial = true;
        if (this.alertSubscription != null)
        {
          this.alertSubscription.unsubscribe();
          }
        this.alertSubscription =  this.firestore.collection<Alerts>('alerts', res=> res.where('alertstatus','==','O')).valueChanges().subscribe(res => {
         
          console.log('alerts data received');
          this.Alert = [];
         res.forEach(r => {
           
           let a = {} as Alerts ;
         
            console.log(r.alertplan);
           this.Alert.push(r);
         });
          
         
   
       });
      }
      else {
        this.showtrial = false;
        if (this.alertSubscription != null)
        {
          this.alertSubscription.unsubscribe();
          }
       

            this.alertSubscription =  this.firestore.collection<Alerts>('alerts').valueChanges().subscribe(res => {
         
          console.log('alerts data received');
          this.Alert = [];
         res.forEach(r => {
           
           let a = {} as Alerts ;
         
            console.log(r.alertplan);
           this.Alert.push(r);
         });
          
         this.dataSource = new MatTableDataSource(this.Alert);
         this.dataSource.paginator = this.paginator;
         this.dataSource.sort = this.sort;
         this.isShow = true;
   
       });

      }

    });


   }

  ngOnInit(): void {
   
    
   
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async ViewComments(row:Alerts)
  {
    this.firestore.collection("alerts").doc(row.alertId).collection<commentdto>("comments").valueChanges().subscribe(e => {
  
      e.forEach(res => {
        debugger;
        res.alertPlan = row.alertplan;
        this.comments.push(res);
        

      });
      console.log(this.comments);
      this.dialog.open(ViewCommentsDialogComponent, { data: this.comments, disableClose:true });


    });
  
  
  }
}




