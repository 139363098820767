import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { firebaseStoreService } from "../services/firebasestoreservice";
import { AuthService } from "./auth.service";
import { ThrowStmt } from '@angular/compiler';
@Injectable({
  providedIn: 'root'
})
export class SubscriptionGuardService implements CanActivate {

  public isAuthenticated: boolean = false;
  public isLoggedIn: boolean = false;

  constructor(
    public auth: AuthService,
    public fs: firebaseStoreService,
    public router: Router) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    
    await this.auth.isAuthenticated().then(res => {
      this.isAuthenticated = res;
      
    });
    if (await this.auth.inAdmin()) {
      return true;
    }
    
    
  
    if (await this.auth.isLoggedIn() && route.queryParams['id'] != null)
    {
      
      console.log("hard");
      var id = route.queryParams['id'];
      var uid = this.auth.user$.uid;
      var data = await this.fs.getUserSubscriptionbyUserIdAndStripeSubscription(uid, id).get().toPromise();
      if (!data.empty)
      {
        return true;
      
      }
      else {
        return false;
      }
      console.log(route.queryParams['id']);
      
    
    }
    else {
      return false;
    }


    
    return false;
  }
}