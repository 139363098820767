import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { PaymentService } from '../../services/paymentservice';
import { AuthService } from '../../auth-guards/auth.service';
import { stripepaymentinfomodule } from 'src/app/models/stripepaymentinfo';
import { MatTableDataSource } from '@angular/material/table';
import * as stripetransactionsinfo from '../../models/stripetransactionsinfo';
import { StripeCustomerTransactionInfoModule } from '../../models/stripetransactionsinfo';
import { AppUtilApiService } from '../../services/app-util-api.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { firebaseStoreService } from '../../services/firebasestoreservice';
import { MatDialog } from '@angular/material/dialog';
import { ProgresdialogComponent } from '../../dialogs/progresdialog/progresdialog.component';
import { StripePaymentMethodInfoModule } from 'src/app/models/stripepaymentmethodinfo';
import { ViewpaymentmethoddialogComponent } from '../viewpaymentmethoddialog/viewpaymentmethoddialog.component';

@Component({
  selector: 'app-view-customer-transactions',
  templateUrl: './view-customer-transactions.component.html',
  styleUrls: ['./view-customer-transactions.component.scss']
})
export class ViewCustomerTransactionsComponent implements OnInit {

  transactions : StripeCustomerTransactionInfoModule.StripeCustomerTransactionstInfodto[] = [];
  displayedColumns: string[] = ['created', 'amountreceived', 'amountrefunded', 'netamount','action'];
  dataSource !: MatTableDataSource<StripeCustomerTransactionInfoModule.StripeCustomerTransactionstInfodto>;
  constructor(public dialog:MatDialog, public fs:firebaseStoreService, public auth:AngularFireAuth, public appUtil:AppUtilApiService, public ls:LoginService, public PaymentService:PaymentService) { }

  async ViewPaymentMethod(pid:string) {
    var dialog = this.dialog.open(ProgresdialogComponent, { disableClose: true });

    var pm = await this.PaymentService.getPaymentMethod(pid ?? '').toPromise();
    console.log(pm);
    var method = StripePaymentMethodInfoModule.Convert.toStripePaymentMethodInfo(pm);
    dialog.close();
    this.dialog.open(ViewpaymentmethoddialogComponent, { data: method, disableClose: true });
    console.log(JSON.parse(pm));

  }
  async ngOnInit(): Promise<void> {


    this.auth.authState.subscribe(async e => {

      if (e != null)
      {
        var uid = e.uid;
        console.log(uid);
        debugger;
        var ud = await this.fs.getUserDetail(uid).toPromise();
          this.PaymentService.getAllTransaction(ud.data()?.stripeCustId ?? '').subscribe(e => {

            debugger;
            console.log(e);
            this.transactions = e;

            this.transactions.forEach(e => {
              e.created = new Date((e.created  ?? 0)* 1000);
              e.amountcaptured = ((e.amountcaptured ?? 0) / 100);
              e.amountreceived = ((e.amountreceived ?? 0) / 100);
              e.amountrefunded = ((e.amountrefunded ?? 0) / 100);
            })
            this.dataSource = new MatTableDataSource(this.transactions);

          });
        }

    });
    
  }

}
