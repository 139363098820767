import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { ReviewDto } from 'src/app/models/reviewdto';
import { AppUtilApiService } from 'src/app/services/app-util-api.service';
import { MatDialog } from '@angular/material/dialog';
import { AddReviewComponent } from '../add-review/add-review.component';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ReviewsService } from '../reviews.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { DeleteReviewDialogComponent } from '../delete-review-dialog/delete-review-dialog.component';
import { FormBuilder, Validators } from '@angular/forms';
import { EditReviewComponent } from '../edit-review/edit-review.component';


export interface ReviewsId extends ReviewDto { id: string; }

@Component({
  selector: 'app-list-reviews',
  templateUrl: './list-reviews.component.html',
  styleUrls: ['./list-reviews.component.scss']
})

export class ListReviewsComponent implements OnInit {
  stars = ['1', '2', '3', '4', '5'];


  public ideasCollection!: AngularFirestoreCollection<ReviewDto>;
  public reviews!: Observable<ReviewsId[]>;
  sUID!: any;
  form: any;

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public appUtil: AppUtilApiService,
    public dialog: MatDialog,
    public revSrv: ReviewsService,
    private formBuilder: FormBuilder) { }

  async ngOnInit(): Promise<void> {



    await this.afAuth.currentUser.then(r => {
      this.sUID = r?.uid;
    })

    this.ideasCollection = this.afs.collection<ReviewDto>('user-reviews', ref => ref
      .orderBy('showflag')
      .where('showflag', "!=", 'A')
      .where('uid', '==', this.sUID)
      .orderBy('createAt', 'desc'));

    this.reviews = this.ideasCollection.snapshotChanges().pipe(
      map(actions => {

        return actions.map(a => {
          const data = a.payload.doc.data() as ReviewDto;
          const id = a.payload.doc.id;          
          return { id, ...data };
        });
      }));




    //    this.userInfo = await this.AuthService.getCurrentUserId();
    //  this.reviews = [];
    //  var sUID = this.userInfo?.uid;

    /*   this.afs.collection<Reviews>('user-reviews', ref => ref.where('uid', '==', sUID).where('showflag', '==', 'N').orderBy('createAt', 'desc'))
      .snapshotChanges().subscribe(res => {
          this.reviews =   res ?? this.reviews;
          console.log(res);
        }); */
  }

  addReview() {
    this.dialog.open(AddReviewComponent);
  }

  deleteReview1(revId: string) {
    this.revSrv.deleteUserReview(revId);
  }

  deleteReview(id: string, revText: string): void {
    const dialogRef = this.dialog.open(DeleteReviewDialogComponent, {
      width: '250px',
      data: { id: id, desciption: revText }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed' + result);

    });
  }

  editReview(review: ReviewsId) {
    const dialogRef = this.dialog.open(EditReviewComponent, {
      width: '250px',
      data: review,
    });

  }

  log($event: any): void {
    this.form.controls.rating.setValue($event);
  }
}