import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertPlans } from 'src/app/models/alertplandto';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { LoginService } from 'src/app/services/login.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PaymentService, CloundFunctionsConstants } from 'src/app/services/paymentservice';
import { AppUtilApiService } from '../../../services/app-util-api.service';

@Component({
  selector: 'app-createplans',
  templateUrl: './createplans.component.html',
  styleUrls: ['./createplans.component.css'],
})
export class CreateplansComponent implements OnInit {
  email = new FormControl('', [Validators.required, Validators.email]);
  form!: FormGroup;
  logininvalid = false;
  loginerror: string = '';
  returnUrl: string = '/dashboard';
  loading = false;

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
  constructor(
    public dialog: MatDialogRef<CreateplansComponent>,
    public firestoreservice: firebaseStoreService,
    public firecloud: PaymentService,
    public router: Router,
    public nts: NotificationService,
    private formBuilder: FormBuilder,
    public auth: LoginService,
    public AppUtilApiService:AppUtilApiService,
    private SpinnerService: NgxSpinnerService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      name: [null, [Validators.required, Validators.min(1)]],
      description: [null, Validators.required],
      monthlyprice: [null, Validators.required],
      yearlyprice: [null, [Validators.required]],
    });
  }

  async OnSubmit() {
  await  this.SpinnerService.show();

    var user = await this.auth.isAuthenticated();
    if (user) {

      this.logininvalid = false;

      if (this.form.valid) {
        this.loading=true;

        var uid = await this.auth.getCurrentUserId();
        var name = this.form.controls['name'].value;
        var monthlyprice = this.form.controls['monthlyprice'].value;
        var yearlyprice = this.form.controls['yearlyprice'].value;
        var description = this.form.controls['description'].value;

        this.firecloud.createProduct(name,description).subscribe((res) => {
          var productId = res;

          this.firecloud
            .createStripePLan(
              monthlyprice,
              CloundFunctionsConstants.stripemonthInterval,
              productId
            )
            .subscribe((res) => {

              var monthlyPlanId = res;

              this.firecloud
                .createStripePLan(
                  yearlyprice,
                  CloundFunctionsConstants.stripeyearInterval,
                  productId
                )
                .subscribe((res) => {
                  var yearlyPlanId = res;

                  let plan: AlertPlans = {
                    plandocid: '',
                    stripeproductid: productId,
                    desciption: description,
                    monthlypriceid: monthlyPlanId,
                    price: monthlyprice,
                    planname: name.toUpperCase(),
                    yearlyprice: yearlyprice,
                    yearlypriceid: yearlyPlanId,
                    topicname: name,
                    createdAt: this.AppUtilApiService.getServerTimestamp(),
                    updatedAt: this.AppUtilApiService.getServerTimestamp(),
                    createdBy: uid?.uid,

                  };

                  this.firestoreservice

                    .addAlertPlans(plan)
                    .then((res) => {
                      this.SpinnerService.hide();

                      this.dialog.close();
                      this.nts.showSuccess("New alert plan added successfully", "Adding New Plan...");
                    })
                    .catch((err) => {
                      this.SpinnerService.hide();
                    });
                });
            });
        });
      }
    }
  }
}
