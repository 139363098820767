import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { PaymentService } from '../../services/paymentservice';
import { userdto } from '../../models/userdto';
import { AuthService } from 'src/app/auth-guards/auth.service';
import { PhoneNumberFormat } from 'ngx-intl-tel-input';
import { MatDialog } from '@angular/material/dialog';
import { ProgresdialogComponent } from '../../dialogs/progresdialog/progresdialog.component';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { DisclaimerModalComponent } from 'src/app/dialogs/disclaimer-modal/disclaimer-modal.component';
import { UsersService } from 'src/app/services/users.service';

declare var require: any
var PhoneNumber = require('awesome-phonenumber');
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  public modalRef: MdbModalRef<DisclaimerModalComponent> | undefined;
  agreedFlag = false;




  mask!: string;
  example!: string;

  folders: any[] = [
    {
      name: 'Photos',
      updated: new Date('1/1/16'),
    },
    {
      name: 'Recipes',
      updated: new Date('1/17/16'),
    },
    {
      name: 'Work',
      updated: new Date('1/28/16'),
    },
  ];
  notes: any[] = [
    {
      name: 'Vacation Itinerary',
      updated: new Date('2/20/16'),
    },
    {
      name: 'Kitchen Remodel',
      updated: new Date('1/18/16'),
    },
  ];
  email = new FormControl('', [Validators.required, Validators.email]);
  form!: FormGroup;
  logininvalid = false;
  loginerror: string = '';
  returnUrl: string = '/dashboard';
  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
  constructor(
    public router: Router,
    public activeroute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public auth: AuthService,
    public dialog: MatDialog,
    public fc: PaymentService,
    private SpinnerService: NgxSpinnerService,
    private modalService: MdbModalService,
    private userSrv: UsersService

  ) {
    this.setMaskAndExample('US');
  }

  ngOnInit(): void {
    this.activeroute.queryParams.subscribe((params) => {
      this.returnUrl = params['returnUrl'];
    });

    this.form = this.formBuilder.group({
      password: [null, [Validators.required, Validators.min(4)]],
      email: [null, [Validators.required, Validators.email]],
      name: [null, [Validators.required]],
      middlename: [null],
      lastname: [null, [Validators.required]],
      address: [null, [Validators.required]],
      city: [null, [Validators.required]],
      state: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
      zipcode: [null, [Validators.required]],
      number: [null, [Validators.required, Validators.minLength(10)]],
      disclaimer: [this.agreedFlag, [Validators.required]]
    });

    this.form.get('number')?.valueChanges.subscribe(e => {
      var pn = new PhoneNumber(e, 'US');
      //    console.log(pn.getNumber('e164'));
    });

  }

  getFormValidationErrors() {
    Object.keys(this.form.controls).forEach((key) => {
      let controlErrors = this.form?.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log('Key control: ' + key + ', keyError: ' + keyError);
        });
      }
    });
  }

  async OnSubmit() {
    this.logininvalid = false;
    if (this.form.valid) {
      var dialogRef = this.dialog.open(ProgresdialogComponent, { disableClose: true });
      var email = this.form.controls['email'];
      var password = this.form.controls['password'];
      this.SpinnerService.show();
      this.auth
        .register(email.value, password.value)
        .then((e) => {
          this.SpinnerService.hide();

          if (e.user != null) {
            var controls = this.form.controls;

            var pn = new PhoneNumber(controls['number'].value, 'US');
            let user: userdto = {
              name: this.form.controls['name'].value,
              middlename: this.form.controls['middlename'].value,
              lastname: this.form.controls['lastname'].value,
              email: email.value,
              city: controls['city'].value,
              wcphone:controls['number'].value,
              phone: pn.a.number.e164,
              address: controls['address'].value,
              state: controls['state'].value,
              zipcode: controls['zipcode'].value,
              uid: e.user.uid,
              isupdate: false,
              pictureUrl: '',
            };

            this.userSrv.updateGoogleDisplayName(e.user.uid, '');

            this.fc.registerUser(user, false).subscribe((e) => {
              if (this.returnUrl == undefined) {
                dialogRef.close();
                this.router.navigateByUrl('/home');
              } else {
                dialogRef.close();
                this.router.navigateByUrl(this.returnUrl);
              }
            });
            var fullName = user.name + ' ' + user.lastname;
            this.userSrv.updateGoogleDisplayName(e.user.uid, fullName);
          }
        })
        .catch((exc) => {
          this.logininvalid = true;
          dialogRef.close();
          this.SpinnerService.hide();
          this.loginerror = exc.message;
          console.log(exc.meesage);
        });

    } else {
      this.getFormValidationErrors();
    }
  }

  getCountryCodeForRegionCode(regionCode: string): string {
    return PhoneNumber.getCountryCodeForRegionCode(regionCode);
  }

  getExample(regionCode: string, format = 'national'): string {
    const example = PhoneNumber.getExample(regionCode);
    const countryCode = PhoneNumber.getCountryCodeForRegionCode(regionCode);

    return example.getNumber(format);
  }

  getMask(regionCode: string, format = 'national'): string {
    return this.getExample(regionCode, format).replace(/\d/g, '0');
  }

  getRegionCodeForCountryCode(regionCode: string): string {
    return PhoneNumber.getRegionCodeForCountryCode(regionCode);
  }

  setMaskAndExample(regionCode: string) {
    this.example = this.getExample(regionCode);
    this.mask = this.getMask(regionCode);
  }

  config = {
    animation: true,
    backdrop: true,
    containerClass: 'right',
    data: {
      title: 'Custom title'
    },
    ignoreBackdropClick: true,
    keyboard: true,
    modalClass: 'modal-top-right modal-dialog-scrollable'
  }

  openDisclaimer() {
    this.modalRef = this.modalService.open(DisclaimerModalComponent, this.config);
    this.modalRef.onClose.subscribe((message: any) => {
      this.form.controls['disclaimer'].setValue(message);
    });
  }
}

