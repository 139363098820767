import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { NgxSpinnerService } from 'ngx-spinner';

import * as firebase from 'firebase';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Alerts } from 'src/app/models/alertdto';
import { AlertPlans } from 'src/app/models/alertplandto';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { LoginService } from 'src/app/services/login.service';

interface Food {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-add-alerts',
  templateUrl: './add-alerts.component.html',
  styleUrls: ['./add-alerts.component.css'],
})
export class AddAlertsComponent implements OnInit {
  selectedValue: string = '';

  AlertPlans: AlertPlans[] = [];
  dt = new FormControl(new Date());
  email = new FormControl('', [Validators.required, Validators.email]);
  form!: FormGroup;
  logininvalid = false;
  loginerror: string = '';
  returnUrl: string = '/dashboard';

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
  constructor(
    public firestoreservice: firebaseStoreService,
    public fns: AngularFirestore,

    public router: Router,
    private formBuilder: FormBuilder,
    public auth: LoginService,
    private SpinnerService: NgxSpinnerService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddAlertsComponent>
  ) {
    this.firestoreservice.getAllAlertPlans().valueChanges().subscribe((alrt) => {
      alrt.forEach((plans) => {
        this.AlertPlans.push(plans);
      });
    });
  }


  ngOnInit(): void {
    this.form = this.formBuilder.group({
      openprice: [null, [Validators.required, Validators.min(0.0000001)]],
      description: [null, [Validators.required]],
      notes: [null],
      alertplan: [null, [Validators.required]],
      symbol: [null, [Validators.required]],
      opendate: [this.dt.value, Validators.required]
    });
  }

  async OnSubmit() {

    var user = await this.auth.isAuthenticated();
    if (user) {
      //  console.log(this.form.controls['alertplan'].value);

      this.logininvalid = false;
      console.log(this.form.valid);
      if (this.form.valid) {
        this.SpinnerService.show();
        var alertPlanName = this.form.controls['alertplan'].value;
        var symbol = this.form.controls['symbol'].value;
        var openprice = this.form.controls['openprice'].value;
        var description = this.form.controls['description'].value;
        var notes = this.form.controls['notes'].value;
        var opendate = this.form.controls['opendate'].value;

        let alertPlan: Alerts = {
          alertstatus: 'O',
          alertId: '',
          alertplanId: alertPlanName.plandocid, symbol: symbol.toUpperCase(), openprice: openprice, desciption: description, notes: notes,
          alertplan: alertPlanName.planname,
          closePrice: '',
          closeAt: '',
          createdAt: opendate,
          totalcomments: 0,
          creationTime: this.firestoreservice.getCurrentTime()
        };

        this.firestoreservice.addAlerts(alertPlan).then(res => {

          console.log(res);

          this.SpinnerService.hide();

          this.dialogRef.close();
        }).catch(error => {
          this.SpinnerService.show();
        });
      }
    }
  }
}
