import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-disclaimer-modal',
  templateUrl: './disclaimer-modal.component.html',
  styleUrls: ['./disclaimer-modal.component.scss']
})
export class DisclaimerModalComponent implements OnInit {
  //agreeFlag = false;

  constructor(public modalRef: MdbModalRef<DisclaimerModalComponent>) { }

  ngOnInit(): void {
  }

  close(): void {
    
    const agreeFlag = false;
    this.modalRef.close(agreeFlag);
 
  }

  agreeBtn(): void {
   
    const agreeFlag = true;
    this.modalRef.close(agreeFlag);
 
  }

}
