import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReviewDto } from 'src/app/models/reviewdto';
import { ReviewsService } from '../reviews.service';

export interface ReviewsId extends ReviewDto { id: string; }

@Component({
  selector: 'app-delete-review-dialog',
  templateUrl: './delete-review-dialog.component.html',
  styleUrls: ['./delete-review-dialog.component.scss']
})


export class DeleteReviewDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DeleteReviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReviewsId,
    private reviewSrv: ReviewsService) { }

  ngOnInit(): void { }

  async deleteReview() {
    this.dialogRef.close();
     this.reviewSrv.deleteUserReview(this.data.id);
  }
}
