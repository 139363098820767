import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { StripeSubscriptionModule } from 'src/app/models/stripesubscription';
import { userdto } from 'src/app/models/userdto';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { ChangephonenumberdialogComponent } from '../changephonenumberdialog/changephonenumberdialog.component';
import { subscriptiondto } from '../../models/subscriptiondto';
import { dateservice } from 'src/app/services/date-service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RefundDto } from '../../models/refunddto';
import { LoginService } from 'src/app/services/login.service';
import { ProgresdialogComponent } from '../../dialogs/progresdialog/progresdialog.component';
import { PaymentService } from '../../services/paymentservice';

@Component({
  selector: 'app-refundpayment',
  templateUrl: './refundpayment.component.html',
  styleUrls: ['./refundpayment.component.scss']
})
export class RefundpaymentComponent implements OnInit {

  constructor(public dialog:MatDialog, public ls:LoginService, public fc:PaymentService, public fs:firebaseStoreService, private formBuilder: FormBuilder, private dialogRef: MatDialogRef<RefundpaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: subscriptiondto, public dateservice:dateservice) { }
  form!: FormGroup;
  refundamount!: number;
  monthsleft!: number;
  monthsused!: number;
  ngOnInit(): void {

    var dt = new Date(this.data.currentSubscribedTime.seconds * 1000);
    var creationDate = new Date();
    var days = this.dateservice.getmonthDiff(dt, creationDate);

    debugger;
    var amountRefund = this.data.amount - ((this.data.amount / 12) * days);
    var stripeFee = ((amountRefund*(2.5 / 100)) + 0.30);
    var netRefundAmount = amountRefund - stripeFee;


    this.refundamount = netRefundAmount;
    this.monthsused = days;
    this.monthsleft = 12 - days;

    console.log(days);
  
    const diff=(e:any,t:any)=>Math.floor((dt.getTime()-new Date(t).getTime())/86400000);
    this.form =  this.formBuilder.group({
     
      refundamount:[netRefundAmount,[Validators.required]],
      reason: [null ,[Validators.required, Validators.minLength(10)]],
    });
  }

  async ApplyRefund()
  {
    
    debugger;
    if (this.form.valid)
    {
      var dialog = this.dialog.open(ProgresdialogComponent, { disableClose: true });
      var uid = (await this.ls.geUserId());
      var reason = this.form.controls['reason'].value;
      var paymentintent = await this.fc.getPaymetIntentFromSubscriptionId(this.data.stripeSubscriptionId).toPromise();
      
      let refund: RefundDto = {
        userid: uid ?? '',
        planid: this.data.alertPlanId,
        subscriptionid: this.data.subscriptionId,
        refundamount: this.refundamount ?? 0,
        monthsleft: this.monthsleft ?? 0,
        monthsused: this.monthsused ?? 0,
        reason: reason,
        applieddate: this.fs.getCurrentTime(),
        isreviewed: false,
        paymentintentid: paymentintent.id,
        isaccepted: false,
        id: '',
        stripesubscriptionid: this.data.stripeSubscriptionId,
        striperefundstatus: 'waiting'
      }
      this.fs.createRefund(refund).then(e => {
        
        dialog.close();
        this.dialogRef.close({ data: true });

      });



    }
    else {
      
    }
  }
}
