
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuardService implements CanActivate {

  public isAuthenticated: boolean = false;
  public isLoggedIn: boolean = false;

  constructor(
    public auth: AuthService,
    public router: Router) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    await this.auth.isAuthenticated().then(res => {
      this.isAuthenticated = res;
    });

    //    console.log('this.rtn: ' + this.isAuthenticated);
    //    console.log('isLog: ' + await this.auth.isLoggedIn());
    //    console.log('state.url: ' + state.url);

    if (!await this.auth.isLoggedIn()) {
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
    return true;
  }
}