import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Alerts } from 'src/app/models/alertdto';
import { firebaseStoreService } from 'src/app/services/firebasestoreservice';
import { LoginService } from 'src/app/services/login.service';
import { NotificationService } from 'src/app/services/notification.service';
import { EditAlertsDialogComponent } from '../../alert-manager/edit-alerts-dialog/edit-alerts-dialog.component';
import { RefundCompactDto } from '../../../models/refunddto';
import { PaymentService } from '../../../services/paymentservice';

@Component({
  selector: 'app-refundpaymentadmin',
  templateUrl: './refundpaymentadmin.component.html',
  styleUrls: ['./refundpaymentadmin.component.scss']
})
export class RefundpaymentadminComponent implements OnInit {
  loading = false;
  form!: FormGroup;
  constructor(public Nts:NotificationService, public spinner:NgxSpinnerService, private dialogRef: MatDialogRef<RefundpaymentadminComponent>,  @Inject(MAT_DIALOG_DATA) public data: RefundCompactDto, public fns:firebaseStoreService,
  public firestoreservice: firebaseStoreService,
  public fc:PaymentService,
  public router: Router,
  private formBuilder: FormBuilder,
  public auth: LoginService,
  private SpinnerService: NgxSpinnerService,
  private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      amount:[this.data.refundamount,[Validators.required]]
    });
  }

  async Refund()
  {
    if (this.form.valid)
    {
      this.loading = true;
      debugger;
      var amount = this.form.controls['amount'].value;

      this.data.refundamount = Math.ceil(amount);
      this.fc.refundAmount(this.data.refundamount, this.data.paymentintentid).subscribe(async e => {
        debugger;
        console.log(e);
        if (e != null && e.type == "StripeInvalidRequestError")
        {
          this.Nts.showError(e.raw.code, "Error");
        }
        else {
          (await this.fc.cancelSubscription(this.data.stripesubscriptionid).toPromise());
          this.Nts.showSuccess("Refund Proceeded", "Refund");
          debugger;
          await this.fns.refundAmountToUser(this.data.id, this.data.refundamount ?? 0)
          this.dialogRef.close({ data: true });
        }


      });


    }

  }



}
