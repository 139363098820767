import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as firebase from 'firebase';
import { MatDialog } from '@angular/material/dialog';
import { ProgresdialogComponent } from '../../dialogs/progresdialog/progresdialog.component';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css'],
})
export class ChangepasswordComponent implements OnInit {
  form!: FormGroup;
  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public auth: AngularFireAuth,
    public nts:NotificationService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        password: [null, [Validators.required, Validators.min(4)]],
        newpassword: [null, [Validators.required, Validators.min(4)]],
        confirmpassword: [null, [Validators.required, Validators.min(4)]],
      },
      {
        validator: this.checkIfMatchingPasswords(
          'newpassword',
          'confirmpassword'
        ),
      }
    );
  }

  checkIfMatchingPasswords(
    passwordKey: string,
    passwordConfirmationKey: string
  ) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

  async changePassword() {
    debugger;
    if (this.form.valid) {
      var dialogRef = this.dialog.open(ProgresdialogComponent, {
        disableClose: true,
      });
      const oldpassword = this.form.controls['password'];
      this.auth.currentUser
        .then((e) => {
          const credentials =
            firebase.default.auth.EmailAuthProvider.credential(
              e?.email ?? '',
              oldpassword.value
            );

          this.auth
            .signInWithCredential(credentials)
            .then((resp) => {
              if (resp.user != null) {
                const newpassword = this.form.controls['newpassword'].value;
                e?.updatePassword(newpassword)
                  .then((pass) => {
                    dialogRef.close();
                    this.nts.showSuccess("Password Updated", "Updated");
                  })
                  .catch((err) => {
                    dialogRef.close();
                    this.nts.showError("Wrond Credentials", "Wrong");
                  });
              } else {
                dialogRef.close();
                this.nts.showError("Wrond Credentials", "Wrong");
              }
            })
            .catch((err) => {
              dialogRef.close();
              this.nts.showError("Wrond Credentials", "Wrong");
            });
        })
        .catch((err) => {
          dialogRef.close();
          this.nts.showError("Wrond Credentials", "Wrong");
        });
      console.log('valid form');
    } else {
      console.log('error');
      console.log(this.form.errors);
    }
  }
}
