import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  isDevMode,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import PhoneNumber from 'awesome-phonenumber';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/auth-guards/auth.service';
import { ModelDialogComponent } from 'src/app/dialogs/model-dialog/model-dialog.component';
import { ProgresdialogComponent } from 'src/app/dialogs/progresdialog/progresdialog.component';
import { AlertPlans } from 'src/app/models/alertplandto';
import { StripeSubscriptionModule } from 'src/app/models/stripesubscription';
import { subscriptiondto } from 'src/app/models/subscriptiondto';
import { userdto } from 'src/app/models/userdto';
import { LoginService } from 'src/app/services/login.service';
import { PaymentService } from 'src/app/services/paymentservice';
import { firebaseStoreService } from '../../services/firebasestoreservice';

declare var require: any

declare var Stripe: stripe.StripeStatic;
@Component({
  selector: 'app-add-payment-method',
  templateUrl: './add-payment-method.component.html',
  styleUrls: ['./add-payment-method.component.scss'],
})
export class AddPaymentMethodComponent implements OnInit,AfterViewInit {
  @ViewChild('cardInfo') cardInfo: ElementRef | any;
  pm: PaymentService;
  mask!: string;
  constructor(
    public dialog: MatDialog,
    public router: Router,
    public firestore: AngularFirestore,
    public fns: AngularFireFunctions,
    public auth: AngularFireAuth,
    public authService: AuthService,
    public ls:LoginService,
    private SpinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public matref:MatDialogRef<AddPaymentMethodComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StripeSubscriptionModule.StripeSubscription,
    public firebaseStoreService: firebaseStoreService
  ) {
    debugger;
    this.pm = new PaymentService(fns);
    this.amount = 500;
    this.userId = '';
    this.stripedto = this.data;
    this.alertId = '';
    this.id = this.data.id ?? '';
  }
  openDialog(): void {}
  ngAfterViewInit(): void {
    debugger;
    const cardStyle = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    };
    if(isDevMode())
    {
      this.stripe = Stripe(
        'pk_test_51ISHAjFL0m4LXzCrWZ1UMRetktzNoa0rzteiy2l3Tf5GaKPe5y2vlianjyrYGT4AKvB85sTj4Gxu1BRgCm8SeZMV00qbVJDNya'
      );
    }
    else{
      this.stripe = Stripe(
        'pk_live_51ISHAjFL0m4LXzCriyTVKqnMXk4NxE8BzchmsSUf1gbItKeOIOhVoTLQVHnDqf3ntTayTPjJG4Ov0yH4UwO98UF700ta61vZx1'
      );
    }
    const elements = this.stripe.elements();

    //this.card = elements.create('card', { cardStyle });
    this.cardNumberInput = elements.create('cardNumber');

    this.cardNumberInput.mount(this.cardInput.nativeElement);

    elements.create('cardExpiry').mount(this.cardM.nativeElement);

    elements.create('cardCvc').mount(this.cardCvc.nativeElement);

    // this.card.mount(this.cardElement.nativeElement);

    this.cardNumberInput.addEventListener('change', (error: any) => {
      console.log(error);
      this.cardErrors = error.error != undefined ? error.error.message : '';
    });
  }

  @Input() amount: any;
  @Input() description: any;
  @ViewChild('cardElement') cardElement: ElementRef | any;
  @ViewChild('cardInput') cardInput: ElementRef | any;
  @ViewChild('cardCvc') cardCvc: ElementRef | any;
  @ViewChild('cardMM') cardMM: ElementRef | any;
  @ViewChild('cardM') cardM: ElementRef | any;
  handler!: StripeCheckoutHandler;

  stripe: any; // : stripe.Stripe;
  card: any;
  cardErrors: any;
  userId: string;

  cardNumberInput: any;
  cardCvcInput: any;
  cardExpiryInput: any;

  loading = false;
  confirmation: any;
  alertId: string;
  alert: AlertPlans | undefined;
  selectedPriceId?: string;
  selectedPlanId?: string;
  Signed = false;
  returnUrl: string = '';
  form!: FormGroup;
  logininvalid = false;
  loginerror: string = '';
  selectDefaultValue = '';
  stripedto : StripeSubscriptionModule.StripeSubscription;

  errors: string[] = [];
  id: string;

  async ngOnInit(): Promise<void> {


    debugger;
    this.selectedPriceId != this.data.amount;

    console.log(this.data);
    this.ls.getCurrentUserId().then(e => {

      debugger;
      this.userId = e?.uid ?? '';
    });
  }

  async handleForm(e: any) {

    console.log(this.id);
    e.preventDefault();
    debugger;
    console.log(this.id);

    console.log(this.stripedto);
    const { token, error } = await this.stripe.createToken(
      this.cardNumberInput
    );
    if (token) {
      var dialogprogress = this.dialog.open(ProgresdialogComponent, {
        disableClose: true,
      });

      console.log(token);
      console.log(this.selectedPriceId);

      var pMethod = await this.pm
        .createPaymentMethod(
          token.id,
          ((this?.data?.amount ?? 0)*100) ?? 0 ,
          'usd'
        )
        .toPromise();
      if (pMethod == null) {
        dialogprogress.close();
        return;
      }
      debugger;
      var customerMethod = await this.pm
        .attachPaymetnMethodToCustomer(this.userId, pMethod.paymentMethodId)
        .toPromise();
      debugger;
      if (customerMethod.customerId == undefined) {
        this.cardErrors =
          customerMethod.decline_code != null
            ? customerMethod.decline_code
            : customerMethod.code;
        dialogprogress.close();
        return;
      }
      debugger;
      console.log(this.id);
      var subscription = await this.pm
        .updatePaymentMethodsubscription(
          this.id ?? '',
          pMethod.paymentMethodId
        )
        .toPromise();
      console.log(subscription);
      var j = JSON.parse(subscription);
      if (j.latest_invoice != null)
      {
        var res = await this.pm
        .updatePaymentMethodInvoice(
          j.latest_invoice,
          pMethod.paymentMethodId

        )
        ?.toPromise();
        console.log(res);
        var invoice = JSON.parse(res);
        if (invoice != undefined && invoice.status != undefined && invoice.status == 'open')
        {
          var resp = await this.pm.payInvoice(invoice.id).toPromise();
          console.log(resp);
        }
        if (res.statusCode != undefined) {
          this.cardErrors = res.code + '- Unknown Reasons';
          dialogprogress.close();
          return;
        }
        this.matref.close({ data: true });
      }
      dialogprogress.close();

    } else {
      console.log(error);
    }

    console.log(error);

    if (error) {
      // Inform the customer that there was an error.
      this.cardErrors = error.message;
    } else {
      // Send the token to your server.
      this.loading = true;

      // this.pm.createPaymentMethod(source.id, 500, "$").subscribe(e => {
      //   console.log(e);

      // });
    }
  }

  // Open the checkout handler
  async checkout(e: any) {
    this.handler.open({
      name: 'Fireship Store',
      description: this.description,
      amount: this.amount,
      email: '',
    });
    e.preventDefault();
  }
}
