import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable, Timestamp } from 'rxjs';
import { subscriptiondto } from '../models/subscriptiondto';
import { Injectable } from '@angular/core';
import { userdto } from '../models/userdto';
import { RefundPaymentIntentDto } from '../models/refundpaymentintentdto';
import { StripeSubscriptionModule } from '../models/stripesubscription';
import { CardDeclineDto } from '../models/carddeclineddetails';
import { StripeCustomerTransactionInfoModule } from '../models/stripetransactionsinfo';
import { customerPaymentMethodsDto } from '../models/customerpaymentmethodsdto';






export class CloundFunctionsConstants{
  static subscribePlan: string = "subscribePlan";
  static createPaymentMethod: string = "createPayment";
  static attachPaymentMethod: string = "attachPaymentMethodToCustomer";
  static getSubscription: string = "getSubscriptions";
  static createSubscription: string = 'createSubscription';
  static amountKey:string = "amount";
  static currencyKey:string = "currency";
  static tokenKey: string = "token";
  static registerUserMethod = "addUserDetails";
  static createProductMethod = "createproduct";
  static createStripePlanMethod = "createPlan";
  public static stripemonthInterval = 'month';
  public static stripeyearInterval = 'year';
  public static cancelSubscription = 'cancelSubscriptions';
  public static pauseSubscription = 'pauseSubscription';
  public static resumSubscription = 'resumeSubscription';
  public static payInvoice = "payInvoice";
  public static changePaymentMethod = 'updatePaymentMethodSubscription';
  public static updateInovicePaymentMethod = 'updatePaymentMethodInvoice';
  public static getPaymentMethod = "getPaymentMethod";
  public static getAllTranscation = "getTransactions";
  public static getAllSubscriptionInvoices = "getAllInvoices";
  public static refundPayment = "refundPayment";
  public static getCustomerMethods = "getCustomerMethods";
  public static getPaymentIntentFromSubscriptionId = "getPaymentIntentFromSubscriptionId";
  public static getPaymentIntentFromInvoice = "getPaymentIntentFromInvoice";

}
@Injectable({
  providedIn: 'root',
})
export class PaymentService{


  constructor(public fns: AngularFireFunctions, ) {

  }
  getCustomerPaymentMethods(id:string)
  {
    const pm = this.fns.httpsCallable<any,Array<customerPaymentMethodsDto>>(CloundFunctionsConstants.getCustomerMethods);
    return pm({'id':id});
  }
  getPaymentIntentErrorFromInvoice(id: string)
  {
    const pm = this.fns.httpsCallable<any,CardDeclineDto>(CloundFunctionsConstants.getPaymentIntentFromInvoice);
    return pm({
      'id': id,
    });
  }
  refundAmount(amount: number, id: string)
  {
    const pm = this.fns.httpsCallable(CloundFunctionsConstants.refundPayment);
    return pm({
      'id': id,
      'amount':amount
    });
  }
  getPaymetIntentFromSubscriptionId(id: string)
  {
    const pm = this.fns.httpsCallable<any,RefundPaymentIntentDto>(CloundFunctionsConstants.getPaymentIntentFromSubscriptionId);
    return pm({
      'id': id,
    });
  }
  getAllTransaction(id: string)
  {
    const pm = this.fns.httpsCallable<any,Array<StripeCustomerTransactionInfoModule.StripeCustomerTransactionstInfodto>>(CloundFunctionsConstants.getAllTranscation);
    return pm({
      'id': id,

    });
  }
  getAllInvoicesOfSubscriptions(id: string)
  {
    const pm = this.fns.httpsCallable(CloundFunctionsConstants.getAllSubscriptionInvoices);
    return pm({
      'id': id,

    });
  }
  getPaymentMethod(id:string)
  {
    const pm = this.fns.httpsCallable(CloundFunctionsConstants.getPaymentMethod);
    return pm({
      'id': id,

    });
  }
  payInvoice(id:string)
  {
    const pm = this.fns.httpsCallable(CloundFunctionsConstants.payInvoice);
    return pm({
      'id': id,

    });
  }
  updatePaymentMethodInvoice(id: string, paymentMethod: string)
  {
    const pm = this.fns.httpsCallable(CloundFunctionsConstants.updateInovicePaymentMethod);
    return pm({
      'id': id,
      'pm':paymentMethod
    });
  }
  updatePaymentMethodsubscription(id: string,paymentMethod:string)
  {
    const pm = this.fns.httpsCallable(CloundFunctionsConstants.changePaymentMethod);
    return pm({
      'id': id,
      'paymentmethod':paymentMethod
    });
  }
  cancelSubscription(id: string)
  {
    const pm = this.fns.httpsCallable(CloundFunctionsConstants.cancelSubscription);
    return pm({
      'id': id
    });
  }
  pauseSubscription(id : string)
  {
   const pm = this.fns.httpsCallable(CloundFunctionsConstants.pauseSubscription);
    return pm({
      'id': id
    });
  }
  resumeSubscription(id: string)
  {
   const pm = this.fns.httpsCallable(CloundFunctionsConstants.resumSubscription);
    return pm({
      'id': id
    });
  }
  createProduct(name: string,description:string)
  {
   const pm = this.fns.httpsCallable(CloundFunctionsConstants.createProductMethod);
   return pm({
     'name': name,
     'description':description

  });
  }
  createStripePLan(amount: any,interval:string,productId:string)
  {
   const pm = this.fns.httpsCallable(CloundFunctionsConstants.createStripePlanMethod);
    return pm({
      "amount": amount,
      "interval": interval,
      "productId": productId
    });

  }
  createPaymentMethod(token:string,totalAmount:number,currencyName:string):Observable<any>
  {

   const pm = this.fns.httpsCallable(CloundFunctionsConstants.createPaymentMethod);
    return pm({
       'amount': totalAmount,
       'currency': currencyName,
       'token': token
     });

  }

  registerUser(registrationData: userdto,isupdate:boolean)
  {
    const pm = this.fns.httpsCallable(CloundFunctionsConstants.registerUserMethod);
    return pm({
      "uid": registrationData.uid,
      "isupdate": isupdate ,
      "email": registrationData.email,
      "name": registrationData.name,
      "middlename": registrationData.middlename,
      "lastname":registrationData.lastname,
      "address": registrationData.address,
      "city": registrationData.city,
      "state": registrationData.state.toUpperCase(),
      "zipcode": registrationData.zipcode,
      "phone": registrationData.phone,
      'wcphone':registrationData.wcphone
    });
  }
  attachPaymetnMethodToCustomer(customerId: string, methodId: string):Observable<any>
  {
    const pm = this.fns.httpsCallable(CloundFunctionsConstants.attachPaymentMethod);
    return pm({
      'customerId' : customerId,
        'paymentMethodId': methodId
    });
  }

  subscribeplan(customerId: string, planId: string, paymentMethodId: string)
  {
    const pm = this.fns.httpsCallable(CloundFunctionsConstants.subscribePlan);
    if (customerId != undefined && planId != undefined && paymentMethodId != undefined)
    {
      return pm({
        "customerId": customerId.trim(),
          "planId": planId.trim(),
          "paymentmethodId": paymentMethodId.trim()
      });

    }
    else {
      return null;
    }

  }
  getSubscription(id: string)
  {
    const pm = this.fns.httpsCallable<any,StripeSubscriptionModule.StripeSubscription> (CloundFunctionsConstants.getSubscription);
    return pm({
      id:id
    });
  }
  createSubscription(instance:subscriptiondto)
  {
    const pm = this.fns.httpsCallable(CloundFunctionsConstants.createSubscription);
    return pm({
      'userId': instance.userId,
      'subscriptionId': instance.subscriptionId,
      'alertPlanId': instance.alertPlanId,
      'stripeSubscriptionId': instance.stripeSubscriptionId,
      'priceId': instance.priceId,
      'productId':instance.productId,
      'isEnabled': instance.isEnabled,
      'stripeStatus': instance.stripeStatus,
      'isCancelled': instance.isCancelled,
      'isTrial': instance.isTrial,
      'plan': instance.plan,
      'amount':instance.amount,
      'paymentMethoId':instance.paymentMethoId,
      'currentPeriodStartDate': instance.currentPeriodStartDate,
      'currentPeriodEndDate':instance.currentPeriodEndDate
    });
  }

}
