import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { Reviews } from 'src/app/models/reviewdto';


@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {
  stars: any = [];
  reviews: Reviews[] = [];
  isShow: boolean = false;
  db: any;
  constructor(public afs: AngularFirestore) {
    this.db = firebase.default.firestore();
    this.stars = ['1', '2', '3', '4', '5'];
  }

  ngOnInit(): void {

    this.afs.collection<Reviews>('user-reviews', ref => ref.where('showflag', '==', 'A').orderBy('createAt', 'desc')).valueChanges().subscribe(e => {
      this.reviews = [];
      e.forEach(res => {
        this.reviews.push(res);
      });

      if (this.reviews.length > 0) {
        this.isShow = true;
      }
    });

    //.orderBy('createAt', 'desc'))

  }



}

 