import { Component, OnInit } from '@angular/core';
import { AppUtilApiService } from 'src/app/services/app-util-api.service';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  public policyEmail: string;
  public webSiteAddress: string;

  constructor(public appUtil: AppUtilApiService, private viewportScroller: ViewportScroller, private router:Router,) {
    this.policyEmail = appUtil.Company.PolicyEmail;
    this.webSiteAddress = appUtil.Company.WebSiteAddress;
  }

  ngOnInit(): void { }

  headElements = ['Category', 'Examples', 'Collected'];

  scrollToTest() {
    this.viewportScroller.scrollToAnchor('q1');
    this.viewportScroller.scrollToAnchor('q2');
    this.viewportScroller.scrollToAnchor('q3');
    this.viewportScroller.scrollToAnchor('q4');
  }

  scrollTo(fragment: any): void {
    this.router.navigate([], { fragment: fragment }).then((res: any) => {
      const element = document.getElementById(fragment);
      if (element != undefined) element.scrollIntoView();
    });
  }
}
