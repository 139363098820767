import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AlertPlans } from 'src/app/models/alertplandto';
import { firebaseStoreService } from '../../../services/firebasestoreservice';

import { DataSource } from '@angular/cdk/collections';
import { RefundpaymentadminComponent } from '../refundpaymentadmin/refundpaymentadmin.component';
import { RefundrejectionComponent } from '../refundrejection/refundrejection.component';
import { RefundCompactDto } from 'src/app/models/refunddto';
import { NotificationService } from '../../../services/notification.service';
import { PaymentService } from '../../../services/paymentservice';

@Component({
  selector: 'app-refund-manager',
  templateUrl: './refund-manager.component.html',
  styleUrls: ['./refund-manager.component.scss']
})
export class RefundManagerComponent implements OnInit {

  refunds: RefundCompactDto[] = [];
  Alert: AlertPlans[] = [];
  ClosedAlert: AlertPlans[] = [];
  displayedColumns: string[] = ['username', 'plan', 'monthsused',   'amounttorefund', 'reason', 'status' , 'action']
  dataSource !: MatTableDataSource<RefundCompactDto>;
  dataSourceClosed !: MatTableDataSource<RefundCompactDto>;

  @ViewChild(MatPaginator) paginator !: MatPaginator;
  @ViewChild(MatSort) sort !: MatSort;
  value = 50;
  @ViewChild('paginator2', { read: MatPaginator }) paginator2 !: MatPaginator;
  @ViewChild(MatSort) sort2 !: MatSort;
  constructor(public dialog:MatDialog,public fs:firebaseStoreService,public fc:PaymentService, public nts:NotificationService) { }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  async ngOnInit(): Promise<void> {
  
    debugger;
    var user = await this.fs.getUsers().get().toPromise();
    var plans = await this.fs.getAllAlertPlans().get().toPromise();
    this.fs.getAllRefunds().subscribe(e => {
    
      this.refunds = [];

      e.forEach(r => {
        
        debugger;
        
        var userdto = user.docs.find(e => e.data().uid == r.userid);
        var plan = plans.docs.find(e => e.data().plandocid == r.planid);
        if (userdto != undefined)
        {
          let RefundCompactDto: RefundCompactDto = {
            monthsused: r.monthsused,
            plan: plan?.data().planname,
            username: userdto.data().name,
            email: userdto.data().email ?? '',
            subscriptionId: r.subscriptionid,
            monthsleft: r.monthsleft,
            reason: r.reason,
            refundamount: r.refundamount,
            appliedate: r.applieddate,
            isaccepted: r.isaccepted ?? false,
            isreviewed: r.isreviewed,
            id: r.id,
            paymentintentid: r.paymentintentid,
            rejectionreason: r.rejectionreason ?? '',
            stripesubscriptionid: r.stripesubscriptionid,
           
          }
          this.refunds.push(RefundCompactDto);
        }


      });
      this.dataSource = new MatTableDataSource(this.refunds);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    });


  }

  async Refund(refund:RefundCompactDto)
  {
    this.dialog.open(RefundpaymentadminComponent, { data: refund, disableClose: true }).beforeClosed().subscribe(async e => {
      if (e)
      {
        debugger;
        await this.fc.cancelSubscription(refund.subscriptionId).toPromise();
        await this.fs.updateSubscriptionRefund(refund.subscriptionId);
      }
    });
  }
  async Reject(refund:RefundCompactDto)
  {
    this.dialog.open(RefundrejectionComponent, { data: refund, disableClose: true });
  }
  async MarkAsRefunded(refund: RefundCompactDto)
  {
    this.fs.refundAmountUser(refund.id).then(e => {
      this.nts.showSuccess("Refunded", "Refunded Amount");
    });
  
  }
}
