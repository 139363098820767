import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  StripePaymentMethodInfoModule } from '../../models/stripepaymentmethodinfo';

@Component({
  selector: 'app-viewpaymentmethoddialog',
  templateUrl: './viewpaymentmethoddialog.component.html',
  styleUrls: ['./viewpaymentmethoddialog.component.scss']
})
export class ViewpaymentmethoddialogComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public data: StripePaymentMethodInfoModule.StripePaymentMethodInfo) { }

  ngOnInit(): void {
    
  }

}
