
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { firebaseStoreService } from '../services/firebasestoreservice';
import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root'
})

export class BlockGuardService implements CanActivate {

  public isAuthenticated: boolean = false;
  public isLoggedIn: boolean = false;

  constructor(
    public auth: AuthService,
    public logins:LoginService,
    public fs:firebaseStoreService,
    public router: Router) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {


    await this.auth.isAuthenticated().then(res => {
      this.isAuthenticated = res;
    });

    //    console.log('this.rtn: ' + this.isAuthenticated);
    //    console.log('isLog: ' + await this.auth.isLoggedIn());
    //    console.log('state.url: ' + state.url);

    debugger;
    if(this.isAuthenticated && !(await this.logins.inAdmin()))
    {
      debugger;
     if ((await this.fs.getUserDetail(this.auth.user$.uid).toPromise()).data()?.statusflag != 'A' ) {
        await this.auth.logout();
        return false;
      }
    }
    return true;
  }
}
