import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReviewDto } from 'src/app/models/reviewdto';
import { AppUtilApiService } from 'src/app/services/app-util-api.service';
import { ReviewsService } from 'src/app/user-dashboard/reviews/reviews.service';

export interface ReviewsId extends ReviewDto { id: string; rowsCnt: any; }

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {

  idCounts: any;

  public ideasCollection!: AngularFirestoreCollection<ReviewDto>;
  public reviews!: Observable<ReviewsId[]>;
  public disAppReviews!: Observable<ReviewsId[]>;
  public appReviews!: Observable<ReviewsId[]>;


  constructor(
    public afs: AngularFirestore,
    public appUtil: AppUtilApiService,
    private revSrv: ReviewsService) { }

  ngOnInit(): void {

    this.ideasCollection = this.afs.collection<ReviewDto>('user-reviews', ref => ref
      .orderBy('showflag')
      .where('showflag', "not-in", ['A', 'D'])
      .orderBy('createAt', 'desc'));

    this.reviews = this.ideasCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as ReviewDto;
          const id = a.payload.doc.id;
          const rowsCnt = actions.length;
          return { id, rowsCnt, ...data };
        });
      })
    );


    this.disAppReviews = this.afs.collection<ReviewDto>('user-reviews', ref => ref
      .where('showflag', "==", 'D')
      .orderBy('createAt', 'desc')).snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as ReviewDto;
            const id = a.payload.doc.id;
            const rowsCnt = actions.length;
            return { id, rowsCnt, ...data };
          });
        })
      );

      this.appReviews = this.afs.collection<ReviewDto>('user-reviews', ref => ref
      .where('showflag', "==", 'A')
      .orderBy('createAt', 'desc')).snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as ReviewDto;
            const id = a.payload.doc.id;
            const rowsCnt = actions.length;
            return { id, rowsCnt, ...data };
          });
        })
      );
  }
  approveReview(id: string, appFlag: string) {
    this.revSrv.approveReview(id, appFlag);
  }
}