import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { userdto } from 'src/app/models/userdto';
import { AppUtilApiService } from 'src/app/services/app-util-api.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ReviewDto, Reviews } from '../../models/reviewdto';
import { firebaseStoreService } from '../../services/firebasestoreservice';
import { LoginService } from '../../services/login.service';

export interface ReviewsId extends ReviewDto { id: string; }
@Injectable({
  providedIn: 'root'
})
export class ReviewsService {
  reviews: Reviews[] = [];
  fullName: string = '';

  constructor(
    private afs: AngularFirestore,
    private firestoreservice: firebaseStoreService,
    private loginSrv: LoginService,
    public afAuth: AngularFireAuth,
    private noti: NotificationService ) { }

  addReview() {
    let uid: any = 'eee';
    let userName: any = 'em';
    let photoURL: any = '';

    this.afAuth.currentUser.then(async rr => {
      uid = rr?.uid;
      userName = rr?.displayName;
      photoURL = rr?.photoURL;
      localStorage.setItem('userUID', uid);
      localStorage.setItem('userName', userName);
      localStorage.setItem('photoURL', photoURL);

      const docSnapshot = await this.afs.collection<userdto>('users').doc(uid).get().toPromise();
      if (docSnapshot.exists) {
        this.fullName = docSnapshot.data()?.name + ' ' + docSnapshot.data()?.lastname;

      }


    })
    localStorage.setItem('photoURL', photoURL);
    this.afs.collection('user-reviews').doc().set({
      createAt: firebase.default.firestore.FieldValue.serverTimestamp(),
      uid: localStorage.getItem('userUID'),
      username: this.fullName,
      showflag: 'N',
      rating: localStorage.getItem('rating'),
      pictureurl: localStorage.getItem('photoURL'),
      review: localStorage.getItem('review'),

    })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }

  editReview() {
    return this.loginSrv.getCurrentUserId();
  }


  deleteUserReview(revid: string) {

    this.afs.collection("user-reviews").doc(revid).delete().then(() => {
      this.noti.showSuccess('Successfully', 'Review deleted');
    }).catch((error: any) => {
      this.noti.showError(error, "Error removing document ");
    });

  }

  approveReview(id: string, appFlag: string) {


    this.afs.collection("user-reviews").doc(id).update({ 'showflag': appFlag, 'updatedAt': this.firestoreservice.getCurrentTime() })
      .then(() => {
        this.noti.showSuccess('Successfully', 'Review Updated');
      }).catch((error: any) => {
        this.noti.showError(error, "Error removing document ");
      });

  }

}


