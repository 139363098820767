import { Injectable } from "@angular/core";


import  firebase from "firebase";
import { Subject } from "rxjs";
import { AngularFirestore } from "@angular/fire/firestore";
import { DeviceDetectorService } from "ngx-device-detector";
import { NotificationService } from './services/notification.service';


@Injectable()
export class MessagingService {
  deviceInfo = null;
  private messaging = firebase.messaging();

  private messageSource = new Subject();
  public currentMessage = this.messageSource.asObservable(); // message observable to show in Angular component

  constructor(private afs: AngularFirestore,
    private nts:NotificationService,
    private deviceService: DeviceDetectorService) {
    
    console.log(deviceService.os);
               }

  // get permission to send messages
  getPermission(user:any) {
    Notification.requestPermission()
      .then(() => {
        console.log("Notification permission granted.");
        return this.messaging.getToken();
      })
      .then(token => {
        console.log("Token: " + token);
        this.saveToken(user, token);
      })
      .catch((err) => {
        console.log("Unable to get permission to notify.", err);
      });
  }

  // Listen for token refresh
  monitorRefresh(user:any) {
    this.messaging.onTokenRefresh(()  => {
      this.messaging.getToken()
        .then(refreshedToken => {
          console.log("Token refreshed.");
          this.saveToken(user, refreshedToken)
        })
        .catch(err => console.log(err, "Unable to retrieve new token"))
    });
  }

  // save the permission token in firestore
  private async saveToken(user:any, token:string): Promise<void> {

 let tokenExist: boolean = false;


 const parentChild = await (await firebase.firestore().collection("users").doc(user.uid).collection("fcmTokens").where("token", "==" , token).get().then(function(res) {
      console.log("parentChild: " + res.size);
      if (res.size > 0 )
      {
        tokenExist = true;
      }
      else {
        tokenExist = false;
      }
    }));

 const currentTokens = user.fcmTokens || {};

    // If token does not exist in firestore, update db
 if (!tokenExist) {
      const userRef = this.afs.collection("users").doc(user.uid).collection("fcmTokens");
      const tokens = { token };
      userRef.add({
        token,
        createDate: firebase.firestore.FieldValue.serverTimestamp(),
        os: this.deviceService.os,
        os_version: this.deviceService.os_version,
        deviceType: this.deviceService.deviceType,
      });
    }
  }


  // used to show message when app is open
  receiveMessages() {
    this.messaging.onMessage(payload => {
      this.nts.showSuccess("new notification", payload);
      console.log("Message received. ", payload);
      this.messageSource.next(payload);
    });
  }
}