import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';

import { first } from 'rxjs/operators';
import { userClass } from '../models/userdto';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  UserInfo: userClass = new userClass();

  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
  ) {


  }


  async storeLoginUserInfo(uid: string): Promise<void> {
    

    await this.afs.collection('users').doc(uid).get().pipe(first()).subscribe((res: any) => {
      var data = res.data();
      this.UserInfo = data;
      //  this.UserInfo.fullName = data.name + ' ' + data.lastname;
    });
  }


  async updateGoogleDisplayName(uid: string, displayname: string) {
    const user = firebase.default.auth().currentUser;

    user?.updateProfile({
      displayName: displayname,
      //  photoURL: "https://example.com/jane-q-user/profile.jpg"
    });
  }

  async updateGooglePhotoURL(uid: string, photourl: string) {
    const user = firebase.default.auth().currentUser;
    user?.updateProfile({
      photoURL: photourl
    });
  }

}
